import { useRef, useCallback } from "react";

export const useChatInputFocus = () => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const focusChatInput = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  return { textareaRef, focusChatInput };
};
