import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function ContentRow({
  title,
  icon,
  children,
}: {
  title: string;
  icon: IconDefinition;
  children: JSX.Element;
}) {
  return (
    <div className={`grid grid-cols-4`}>
      <div
        className={`col-span-2 sm:col-span-1 flex flex-row items-center gap-2`}
      >
        <FontAwesomeIcon icon={icon} width={18} className="text-darkIcons" />
        <div className="text-sm text-darkSecondary truncate w-full">
          {title}
        </div>
      </div>
      <div
        className={`col-span-2 sm:col-span-3 flex flex-row items-center gap-2 h-5`}
      >
        {children}
      </div>
    </div>
  );
}
