import posthog from "posthog-js";

import { useAppStore } from "../store";
import { useState, useMemo } from "react";
import { invoke } from "@tauri-apps/api/core";

enum CONTEXT_COLLECTOR_STATE {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  ACCESSIBILITY_PERMISSION_MISSING = "ACCESSIBILITY_PERMISSION_MISSING",
  SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG",
}

export const useContextCollection = () => {
  const {
    contextCollectorWorking,
    stopContextCollection,
    startContextCollection,
    setServerAllowed,
    ongoingContextCollection,
    hasAccessibilityPermissions,
    serverAllowed,
  } = useAppStore((state) => state);

  const [isLoading, setIsLoading] = useState(false);

  const handleCollectionToggle = async () => {
    setIsLoading(true);
    if (contextCollectorWorking) {
      await stopContextCollection();
      posthog.capture("user_disabled_context_collection");
      setServerAllowed(false);
    } else {
      await stopContextCollection();
      await startContextCollection();
      posthog.capture("user_enabled_context_collection");
      setServerAllowed(true);
    }
    setTimeout(() => setIsLoading(false), 2000);
  };

  const contextCollectionState = useMemo(() => {
    if (!hasAccessibilityPermissions) {
      return CONTEXT_COLLECTOR_STATE.ACCESSIBILITY_PERMISSION_MISSING;
    }
    if (!serverAllowed) {
      return CONTEXT_COLLECTOR_STATE.DISABLED;
    }
    if (
      serverAllowed &&
      hasAccessibilityPermissions &&
      !ongoingContextCollection
    ) {
      return CONTEXT_COLLECTOR_STATE.SOMETHING_WENT_WRONG;
    }
    return CONTEXT_COLLECTOR_STATE.ENABLED;
  }, [ongoingContextCollection, hasAccessibilityPermissions, serverAllowed]);

  const contextCollectionStateDetails = useMemo(() => {
    const createOnPressHandler = (fn: () => Promise<void>) => async () => {
      setIsLoading(true);
      await fn();
      await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for the state to update
      setIsLoading(false);
    };

    const details = {
      [CONTEXT_COLLECTOR_STATE.DISABLED]: {
        iconColor: "bg-darkRed",
        text: "Disabled",
        onPress: createOnPressHandler(startContextCollection),
        tooltipText: "Enable Context Collection",
      },
      [CONTEXT_COLLECTOR_STATE.ENABLED]: {
        iconColor: "bg-darkGreen",
        text: "Enabled",
        onPress: createOnPressHandler(stopContextCollection),
        tooltipText: "Disable Context Collection",
      },
      [CONTEXT_COLLECTOR_STATE.ACCESSIBILITY_PERMISSION_MISSING]: {
        iconColor: "bg-darkGrey",
        text: "Grant Permissions",
        onPress: createOnPressHandler(() =>
          invoke("request_accessibility_permissions")
        ),
        tooltipText: "Open Accessibility Permissions",
      },
      [CONTEXT_COLLECTOR_STATE.SOMETHING_WENT_WRONG]: {
        iconColor: "bg-darkYellow",
        text: "Connection Lost",
        onPress: createOnPressHandler(async () => {
          await stopContextCollection();
          await startContextCollection();
        }),
        tooltipText: "Restart Context Collection",
      },
    };
    return (
      details[contextCollectionState] || {
        iconColor: "bg-darkGrey",
        text: "Disabled",
        onPress: handleCollectionToggle,
        tooltipText: "Enable Context Collection",
      }
    );
  }, [
    contextCollectionState,
    startContextCollection,
    stopContextCollection,
    handleCollectionToggle,
  ]);

  return {
    isLoading,
    handleCollectionToggle,
    contextCollectionStateDetails,
  };
};
