import Modal from "../ui/Modal";
import { useEffect, useState } from "react";
import { useAppStore } from "../../store";
import CopyButton from "./CopyButton";
import { set_chat_as_public } from "../../utils/api";
import Separator from "../ui/Separator";
import { faMessages } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ShareModal = () => {
  const { focusedChat, APIUrl, token, shareModalOpen, setShareModalOpen } =
    useAppStore((state) => state);
  const [chatUrl, setChatUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!focusedChat || !token || !APIUrl) return;
    set_chat_as_public(focusedChat.chat_id, token, APIUrl).then((res) => {
      setChatUrl(
        `https://lilbird.co/shared-chat/${focusedChat.chat_id}?publicToken=${res.public_token}`
      );
    });
  }, [focusedChat, token, APIUrl]);

  if (!focusedChat) return null;

  return (
    <Modal
      mainContent={
        <div className="px-6 py-8">
          <div className="text-start text-darkWhite text-[15px]">
            Share Link to Chat
          </div>
          <div className="text-start text-darkPrimary opacity-60 text-sm mt-2">
            Other people who have the link will be able to view this chat.
          </div>

          <Separator className="my-6" />

          <div
            className={`
        flex w-full gap-4 items-center text-start px-3 h-12 rounded opacity-90 border transition bg-darkField border-darkLine
      `}
          >
            <FontAwesomeIcon icon={faMessages} width={18} />
            <div className="flex-grow text-sm text-darkPrimary truncate">
              {focusedChat.title}
            </div>
          </div>
          <Separator className="my-6" />

          <div className="mt-4 flex gap-2 flex-col">
            <div className="flex flex-row gap-2 items-center">
              {!chatUrl ? (
                <div className="animate-pulse h-8 flex-grow bg-darkField rounded" />
              ) : (
                <>
                  <div className="select-all font-mono text-xs text-darkPrimary truncate">
                    {chatUrl}
                  </div>
                  <CopyButton
                    copyTextPositionCl="top start"
                    text={chatUrl}
                    copyText="Copy url"
                    className="py-1 px-2 rounded-sm border border-darkLine"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      }
      isOpen={shareModalOpen}
      onClose={() => setShareModalOpen(false)}
      className="max-w-[640px] w-full"
    />
  );
};

export default ShareModal;
