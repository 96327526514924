import { TodoStatus } from "../types/dataclasses";

export const getStatusString = (status: TodoStatus) => {
  if (status === TodoStatus.accepted) {
    return "Todo";
  }
  if (status === TodoStatus.suggestion) {
    return "Suggested";
  }

  if (status === TodoStatus.completed) {
    return "Complete";
  }
  if (status === TodoStatus.deleted) {
    return "Deleted";
  }
  if (status === TodoStatus.rejected) {
    return "Rejected";
  }
  return "";
};

export const stringToColor = (str: string) => {
  // Simple hash function
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert hash to a 6-digit hex code
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff; // Extract each byte
    color += ("00" + value.toString(16)).slice(-2); // Convert to 2-digit hex
  }

  return color;
};
