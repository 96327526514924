import { Listbox, ListboxButton } from "@headlessui/react";
import { faArrowUpRight } from "@fortawesome/pro-regular-svg-icons";
import IconButton from "../ui/IconButton";
import { WsList } from "./WsList";
import { Todo } from "../../types/dataclasses";
import { useAppStore } from "../../store";
import posthog from "posthog-js";

export function WorkstreamSelector({ task }: { task: Todo }) {
  const { updateTodo } = useAppStore();

  return (
    <Listbox
      value={task.workstreams}
      onChange={(workstreams) => {
        updateTodo({
          ...task,
          workstreams,
        });
        posthog.capture("user_task_event", {
          actionName: "Task_Tags_Updated",
          tags: task.workstreams,
          oldTags: task.workstreams,
          newTags: workstreams,
        });
      }}
      multiple
    >
      <ListboxButton as="div" className="cursor-pointer">
        <div className={`flex flex-row items-center gap-2`}>
          <div className="text-xs text-darkSecondary truncate">
            {task.workstreams.length > 0
              ? task.workstreams.length > 1
                ? `${task.workstreams[0]} +${task.workstreams.length - 1}`
                : task.workstreams[0]
              : ""}
          </div>
          <IconButton
            icon={faArrowUpRight}
            onClick={() => {}}
            size={10}
          />
        </div>
      </ListboxButton>

      <WsList anchor="bottom end" />
    </Listbox>
  );
} 