import { useEffect, useRef, useState } from "react";
import { faMessages } from "@fortawesome/pro-regular-svg-icons";
import { ROUTES, useAppStore } from "../store";

export const CMD_K_OPTIONS = [
  {
    text: "Go to Chat",
    actionInfo: [
      {
        text: "G",
        isKey: true,
      },
      {
        text: "then",
        isKey: false,
      },
      {
        text: "C",
        isKey: true,
      },
    ],
  },
  {
    text: "Go to Tasks",
    actionInfo: [
      {
        text: "G",
        isKey: true,
      },
      {
        text: "then",
        isKey: false,
      },
      {
        text: "T",
        isKey: true,
      },
    ],
  },
  {
    text: "Go to Journal",
    actionInfo: [
      {
        text: "G",
        isKey: true,
      },
      {
        text: "then",
        isKey: false,
      },
      {
        text: "J",
        isKey: true,
      },
    ],
  },
  {
    text: "Go to Settings",
    actionInfo: [
      {
        text: "G",
        isKey: true,
      },
      {
        text: "then",
        isKey: false,
      },
      {
        text: "S",
        isKey: true,
      },
    ],
  },
  {
    icon: faMessages,
    text: "Create New Chat",
    actionInfo: [
      {
        text: "⌘",
        isKey: true,
      },
      {
        text: "then",
        isKey: false,
      },
      {
        text: "N",
        isKey: true,
      },
    ],
  },
];

export const useHotkeys = () => {
  const {
    cmdModalOpen,
    requestNewChat,
    setCurrentRoute,
    setSettingsOpen,
    setCmdModalOpen,
    stopPropagatingHotkeys,
  } = useAppStore((state) => state);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null); // Use ReturnType<typeof setTimeout> for compatibility

  const [waitingForHotkey, setWaitingForHotkey] = useState(false);

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.metaKey && event.key === "k") {
        if (cmdModalOpen) {
          setCmdModalOpen(false);
        } else {
          setCmdModalOpen(true);
          setSettingsOpen(false);
        }
      }

      if (event.metaKey && event.key === "n") {
        setCurrentRoute(ROUTES.CHAT);
        setSettingsOpen(false);
        setCmdModalOpen(false);
        requestNewChat();
      }

      if (!stopPropagatingHotkeys) {
        if (event.key === "g") {
          setWaitingForHotkey(true); // Start waiting for actual cmd key
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = setTimeout(() => {
            setWaitingForHotkey(false);
            timeoutRef.current = null;
          }, 2000);
        }

        if (waitingForHotkey && event.key === "c") {
          setCurrentRoute(ROUTES.CHAT);
          setSettingsOpen(false);
          setCmdModalOpen(false);
        }

        if (waitingForHotkey && event.key === "t") {
          setCurrentRoute(ROUTES.TASKS);
          setSettingsOpen(false);
          setCmdModalOpen(false);
        }

        if (waitingForHotkey && event.key === "j") {
          setCurrentRoute(ROUTES.JOURNAL);
          setSettingsOpen(false);
          setCmdModalOpen(false);
        }

        if (waitingForHotkey && event.key === "s") {
          setSettingsOpen(true);
          setCmdModalOpen(false);
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [waitingForHotkey, cmdModalOpen, stopPropagatingHotkeys]);
};
