import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

interface ButtonProps {
  children: React.ReactNode;
  onPress: () => void;
  //   disabled?: boolean;
  isOn: boolean;
  className?: string;
  isSubmitting?: boolean;
}

const SwitchButton = ({
  children,
  onPress,
  //   disabled = false,
  isOn,
  className = "",
  isSubmitting = false,
}: ButtonProps) => {
  const baseStyles =
    "flex flex-row items-center justify-center opacity-90 disabled:opacity-50 hover:opacity-100 h-11 rounded text-xs font-mono whitespace-nowrap uppercase text-darkPrimary px-3 border border-darkLine gap-2";

  return (
    <button
      className={`${baseStyles} ${className}`}
      onClick={onPress}
      //   disabled={disabled}
    >
      {isOn ? (
        <div className={`w-1.5 h-1.5 rounded-full bg-darkGreen`} />
      ) : (
        <div className={`w-1.5 h-1.5 rounded-full border border-darkPrimary`} />
      )}
      {isSubmitting ? (
        <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
      ) : isOn ? (
        "Enabled"
      ) : (
        children
      )}
    </button>
  );
};

export default SwitchButton;
