import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function ListRow({
  onClick,
  title,
  leftIcon,
  higlighted,
  children,
  noBottomMargin,
}: {
  onClick: () => void;
  title: string;
  higlighted?: boolean;
  leftIcon?: IconDefinition | JSX.Element;
  children?: JSX.Element;
  noBottomMargin?: boolean;
}) {
  return (
    <button
      className={`
        flex w-full gap-4 items-center text-start px-3 h-12 rounded opacity-90 border transition
        ${higlighted ? "bg-darkField border-darkLine" : "border-transparent"}
        hover:bg-darkField hover:border-darkLine
        ${!noBottomMargin ? "mb-1" : ""}
        }
      `}
      onClick={onClick}
    >
      {leftIcon &&
        (typeof leftIcon === "object" && "iconName" in leftIcon ? ( // Check for IconDefinition
          <FontAwesomeIcon icon={leftIcon as IconDefinition} width={18} />
        ) : React.isValidElement(leftIcon) ? ( // Check for JSX.Element
          leftIcon
        ) : null)}
      <div className="flex-grow text-sm text-darkPrimary truncate">{title}</div>

      {children && children}
    </button>
  );
}
