import { faAdd, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListboxOption, ListboxOptions, ListboxOptionsProps } from "@headlessui/react";
import { useAppStore } from "../../store";
import { useState } from "react";
import Input from "../ui/Input";

export function WsList({
  anchor = "bottom start",
}: {
  anchor?: ListboxOptionsProps['anchor']
}) {
  const { workstreams, editWorkstream } = useAppStore();
  const [workstreamQuery, setWorkstreamQuery] = useState("");

  return (
    <ListboxOptions
      anchor={anchor}
      className="mt-1 rounded w-64 border border-darkLine bg-darkField backdrop-blur-lg shadow-card"
    >
      <Input
        id="ws-query"
        value={workstreamQuery}
        onChange={(e) => setWorkstreamQuery(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
        placeholder="Search workstreams..."
      />
      {workstreamQuery.length > 0 && (
        <ListboxOption
          value={workstreamQuery}
          onClick={() => {
            editWorkstream({
              id: null as any,
              name: workstreamQuery,
              capture: null,
              ignore: null,
            });
            setWorkstreamQuery("");
          }}
          className="flex flex-row items-center group w-full px-3 h-12  cursor-pointer text-sm text-darkPrimary"
        >
          <span className="grow truncate">
            New workstream '{workstreamQuery}'
          </span>
          <FontAwesomeIcon icon={faAdd} />
        </ListboxOption>
      )}
      {workstreams
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .map((workstream) => (
          <ListboxOption
            key={workstream.id}
            value={workstream.name}
            className="flex flex-row items-center group w-full px-3 h-12 rounded cursor-pointer text-sm opacity-90 border border-transparent hover:bg-darkField hover:border-darkLine"
          >
            <span className="grow">{workstream.name}</span>
            <FontAwesomeIcon
              icon={faCheck}
              className="hidden group-data-[selected]:inline"
            />
          </ListboxOption>
        ))}
    </ListboxOptions>
  );
}
