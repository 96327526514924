import Button from "../components/ui/Button";
import CenteredTile from "../components/ui/CenteredTile";
import Logo from "../assets/icons/logo.svg";
import { ONBOARDINGSTEP, useAppStore } from "../store";
import { faArrowLeftLong } from "@fortawesome/pro-regular-svg-icons";
import IconButton from "../components/ui/IconButton";

function OnboardingVideoScreen() {
  const { setOnboardingStep, setEmail, setToken } = useAppStore(
    (state) => state
  );
  return (
    <CenteredTile
      title="Welcome to Little Bird"
      leadingContent={<img src={Logo} alt="Little Bird" className="mx-auto" />}
      description="Little Bird is a personal AI designed for productive people."
      content={
        <>
          <div className="w-full">
            <video
              className="w-full rounded-lg"
              playsInline
              controls
              src="https://little-bird-releases.s3.us-east-2.amazonaws.com/user_tutorial.webm"
            >
              Video not supported, please skip the tutorial or go to:
              https://little-bird-releases.s3.us-east-2.amazonaws.com/user_tutorial.webm
            </video>
          </div>
          <div className="flex flex-row items-center mt-4">
            <IconButton
              className="mr-5"
              icon={faArrowLeftLong}
              onClick={() => {
                setEmail(null);
                setToken(null);
              }}
            ></IconButton>
            <Button
              className="w-full mt-4 mb-4"
              type="button"
              onPress={() => {
                setOnboardingStep(ONBOARDINGSTEP.COMPLETE);
              }}
            >
              Next
            </Button>
          </div>
        </>
      }
    />
  );
}

export default OnboardingVideoScreen;
