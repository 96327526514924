import { open } from "@tauri-apps/plugin-shell";

export const inBrowser = () => {
  // @ts-ignore
  return window.__TAURI_INTERNALS__ === undefined;
};

export const handleMailTo = (email: string, subject: string, body: string) => {
  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  try {
    handleExternalUrlClick(mailtoLink);
  } catch (error) {
    console.error("Failed to open mail client:", error);
  }
};

export const handleExternalUrlClick = (url: string) => {
  if (inBrowser()) {
    window.open(url, "_blank");
  } else {
    open(url);
  }
};

export type BrowserType = "Chrome" | "Firefox" | "Safari" | "Edge" | "Other";

export const getBrowser = (): BrowserType => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Edg")) {
    return "Edge";
  } else if (userAgent.includes("Chrome") && !userAgent.includes("OPR")) {
    return "Chrome";
  } else if (userAgent.includes("Firefox")) {
    return "Firefox";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    return "Safari";
  } else {
    return "Other";
  }
};

export const getExtensionLink = (browser: BrowserType) => {
  switch (browser) {
    case "Chrome":
      return "https://chromewebstore.google.com/detail/little-bird-assistant/diiglblkiaadlinpdbalilcnkiiainmp";
    case "Firefox":
      return '"https://addons.mozilla.org/en-US/firefox/addon/little-bird-assistant/"';
    case "Safari":
      return "Safari addon not ready";
    case "Edge":
      return "Edge addon not ready";
    case "Other":
      return "https://chromewebstore.google.com/detail/little-bird-assistant/diiglblkiaadlinpdbalilcnkiiainmp";
  }
};
