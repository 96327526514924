import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

interface ButtonProps {
  children: React.ReactNode;
  onPress?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  className?: string;
  isSubmitting?: boolean;
  variant?: "default" | "outline";
}

const Button = ({
  children,
  onPress,
  disabled = false,
  type = "button",
  className = "",
  isSubmitting = false,
  variant = "default",
}: ButtonProps) => {
  // Define styles for different variants
  const baseStyles =
    "flex items-center justify-center opacity-90 disabled:opacity-50 hover:opacity-100 rounded text-xs font-mono whitespace-nowrap uppercase text-darkPrimary";

  const variantStyles = {
    default: "bg-cta-gradient h-12 px-4",
    outline: "h-11 px-3 border border-darkLine",
  };

  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      onClick={onPress}
      type={type}
      disabled={disabled}
    >
      {isSubmitting ? (
        <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
