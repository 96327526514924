import { useState } from "react";
import { format, addDays } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faSignalStream } from "@fortawesome/pro-regular-svg-icons";
import { useAppStore } from "../../store";
import DatePicker from "../ui/DatePicker";

import Separator from "../ui/Separator";
import Button from "../ui/Button";
import {
  Listbox,
  ListboxButton,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import { TodoStatus } from "../../types/dataclasses";
import { WsList } from "./WsList";

const NewTask = ({ onClose }: { onClose: () => void }) => {
  const { createTodo, setStopPropagatingHotkeys } = useAppStore();

  const [description, setDescription] = useState("");
  const [workstreams, setWorkstreams] = useState<string[]>([]);
  const [date, setDate] = useState<Date | undefined>(undefined);

  return (
    <>
      <div className="flex flex-col px-4 pt-6 pb-2 space-y-2 max-h-96">
        <textarea
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            e.target.style.height = "auto";
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          placeholder="Add Description..."
          className="bg-transparent w-full resize-none outline-none caret-blue"
          onFocus={() => setStopPropagatingHotkeys(true)}
          onBlur={() => setStopPropagatingHotkeys(false)}
        />
      </div>
      <Separator />
      <div className="flex flex-row px-3 py-3 space-x-2">
        <Listbox value={workstreams} onChange={setWorkstreams} multiple>
          <ListboxButton className="px-3.5 py-2.5 max-h-11 truncate space-x-2 rounded-md border border-darkLine text-sm text-start text-darkSecondary data-[open]:bg-darkLine data-[open]:text-darkPrimary">
            <FontAwesomeIcon icon={faSignalStream} />
            <span className="grow">
              {workstreams.length > 0
                ? workstreams.length > 1
                  ? `${workstreams[0]} +${workstreams.length - 1}`
                  : workstreams[0]
                : "Workstream"}
            </span>
          </ListboxButton>

          <WsList />
        </Listbox>

        <Popover>
          <PopoverButton className="px-3.5 py-2.5 max-h-11 truncate space-x-2 rounded-md border border-darkLine text-sm text-start text-darkSecondary data-[open]:bg-darkLine data-[open]:text-darkPrimary">
            <FontAwesomeIcon icon={faClock} />
            <span>{date ? format(date, "EEE, MMM d") : "Date"}</span>
          </PopoverButton>
          <PopoverPanel
            anchor="bottom start"
            className="mt-1 rounded-md border border-darkLine"
          >
            {({ close }) => (
              <DatePicker
                value={date}
                onChange={(v) => {
                  setDate(v);
                  close();
                }}
              />
            )}
          </PopoverPanel>
        </Popover>

        <div className="grow" />

        <Button
          disabled={!description}
          className="max-h-10 text-sm"
          onPress={() => {
            createTodo({
              date: (date ?? addDays(new Date(), 1)).toISOString(),
              description,
              workstreams,
              status: TodoStatus.accepted,
            });

            onClose();
            setDescription("");
            setWorkstreams([]);
            setDate(undefined);
          }}
        >
          Create Task
        </Button>
      </div>
    </>
  );
};

export default NewTask;
