import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import remarkGfm from "remark-gfm";

import { coldarkDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import style from "../ui/markdown-styles.module.css";
import { memo } from "react";
import CopyButton from "../blocks/CopyButton";

const Markdown = ({ text }: { text: string }) => {
  return (
    <ReactMarkdown
      className={`${style.reactMarkDown} select-text text-darkPrimary`}
      remarkPlugins={[remarkGfm]}
      components={{
        // Rewrite
        code({ className, inline, children, ...rest }: any) {
          const match = /language-(\w+)/.exec(className || "");

          return !inline && match ? (
            <div className="relative">
              <SyntaxHighlighter
                lineProps={{
                  style: {
                    whiteSpace: "pre-wrap", // Preserves formatting and wraps long lines
                    overflowWrap: "break-word",
                  },
                }}
                PreTag="div"
                wrapLongLines
                wrapLines={true}
                language={match[1] || "javascript"}
                style={coldarkDark}
                customStyle={{
                  marginTop: 15,
                  marginBottom: 20,
                  borderRadius: 4,
                  borderWidth: 1,
                  lineHeight: "22px",
                  border: "1px solid rgba(255, 255, 255, 0.08)",
                  backgroundColor: "rgba(255, 255, 255, 0.04)",
                  color: "rgba(255, 255, 255, 0.85)",
                }}
                codeTagProps={{
                  style: {
                    fontSize: 13,
                  },
                }}
              >
                {children as any}
              </SyntaxHighlighter>
              <div className="absolute right-4 bottom-4">
                <CopyButton
                  text={children as any}
                  copyText="Copy Code"
                  copyTextPositionCl="top start"
                />
              </div>
            </div>
          ) : (
            <code
              {...rest}
              className={className}
              style={{
                fontSize: 13,
                lineHeight: "100%",
                border: "1px solid rgba(255, 255, 255, 0.08)",
                backgroundColor: "rgba(255, 255, 255, 0.04)",
                borderRadius: 2,
                paddingBottom: "2px",
                paddingRight: "4px",
                paddingLeft: "4px",
                color: "#F15F5F",
                whiteSpace: "pre-wrap", // Preserves spaces and wraps
                overflowWrap: "break-word", // Breaks long words if needed
              }}
            >
              {children}
            </code>
          );
        },
        a: (props) => {
          return (
            <a
              href={props.href}
              target="_blank"
              rel="noopener noreferrer" // Added for security best practice
            >
              {props.children}
            </a>
          );
        },
        // Rewrite
        // Map `h1` (`# heading`) to use `h2`s.
        // h1: 'h2',
      }}
    >
      {text}
    </ReactMarkdown>
  );
};

export default memo(Markdown);
