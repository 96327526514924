import { faUserCircle } from "@fortawesome/pro-regular-svg-icons";

import CenteredTile from "../components/ui/CenteredTile";
import Button from "../components/ui/Button";
import { useEffect, useState } from "react";
import { useAppStore } from "../store";
import Input from "../components/ui/Input";
import Separator from "../components/ui/Separator";
// import SectionRow from "../components/ui/SectionRow";

const PersonalizeScreen = () => {
  const { user, updateUser, fetchUser, setToken } = useAppStore(
    (state) => state
  );

  const [name, setName] = useState(user?.name ?? "");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showApplicationSelectionList, setShowApplicationSelectionList] =
    useState(false);

  useEffect(() => {
    fetchUser();
  }, []);

  if (showApplicationSelectionList) {
    return (
      <CenteredTile
        title="Applications with Context Collection "
        description="Choose which applications Little Bird will be able to see to Collect Context."
        content={
          <div className="flex flex-col gap-8">
            <Separator />
            <div className="flex flex-row items-center">
              <div className="flex flex-grow text-darkPrimary text-sm">
                APP NAME
              </div>
              <Button variant="outline" onPress={() => {}}>
                {true ? "allowed" : "Enable Access"}
              </Button>
            </div>
            <Separator />
            <Button
              disabled={name === ""}
              isSubmitting={isSubmitting}
              onPress={() => {
                // TOOD: SAVE
                setShowApplicationSelectionList(false);
              }}
            >
              Save Settings
            </Button>
          </div>
        }
      />
    );
  }
  return (
    <CenteredTile
      icon={faUserCircle}
      title="Profile"
      description="Share information with Little Bird to help Little Bird match your needs. You can change these settings later too."
      content={
        <div className="flex flex-col gap-8">
          <Input
            label="Full Name"
            id="name"
            value={name}
            placeholder="John Doe"
            onChange={(e) => setName(e.currentTarget.value)}
          />
          {/* <SectionRow
            title="Apps to ignore"
            description="Select apps which you’d like Little Bird to ignore. "
            actionComponent={
              <Button
                variant="outline"
                onPress={() => setShowApplicationSelectionList(true)}
              >
                select apps
              </Button>
            }
          /> */}

          <Button
            disabled={name === ""}
            isSubmitting={isSubmitting}
            onPress={async () => {
              if (user) {
                setIsSubmitting(true);
                const res = await updateUser({
                  ...user,
                  name,
                });
                if (res.error) {
                  setToken(null);
                }
                setIsSubmitting(false);
              } else {
                setToken(null);
              }
            }}
          >
            Complete Setup
          </Button>
        </div>
      }
    />
  );
};

export default PersonalizeScreen;
