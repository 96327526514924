import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BreadCrumb({
  icon,
  onClick,
  main,
  section,
}: {
  icon?: IconDefinition;
  onClick?: () => void;
  main: string;
  section?: string;
}) {
  return (
    <div className="flex flex-row items-center gap-3 w-full">
      {icon && (
        <FontAwesomeIcon icon={icon} width={14} className="flex-shrink-0" />
      )}

      <div
        className={`text-sm ${section ? "text-darkSecondary" : ""} ${
          onClick ? "cursor-pointer" : ""
        } flex-shrink-0`}
        onClick={onClick ? onClick : () => {}}
      >
        {main}
      </div>

      {section && (
        <div className="text-darkSecondary flex-shrink-0 mx-1">/</div>
      )}

      {section && (
        <div className="flex-grow truncate">
          <p className="text-sm truncate">{section}</p>
        </div>
      )}
    </div>
  );
}
