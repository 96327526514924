import { faRemove } from "@fortawesome/pro-regular-svg-icons";
import IconButton from "../ui/IconButton";
import docImageSrc from "../../assets/icons/doc-icon.webp";

export default function FilePreview({
  file,
  size,
  removeFile,
}: {
  file: string;
  size: "lg" | "sm";
  removeFile?: () => void;
}) {
  return (
    <div className="relative">
      {removeFile && (
        <IconButton
          className="absolute bg-darkBG w-6 h-6 rounded-full"
          icon={faRemove}
          onClick={removeFile}
          style={{
            top: 4,
            right: 4,
          }}
        />
      )}
      <img
        src={file}
        alt={file}
        className="rounded-md object-cover"
        style={{
          width: size === "sm" ? 88 : 176,
          height: size === "sm" ? 88 : 176,
        }}
        onError={(e) => {
          console.error("Image load error:", e.target);
          (e.target as HTMLImageElement).src = docImageSrc;
        }}
      />
    </div>
  );
}
