import axios from "axios";
import {
  Assistant,
  Chat,
  ChatMode,
  Source,
  Summary,
  Todo,
  TodoCreate,
  TodoUpdate,
  User,
  UserUpdate,
  VoteData,
  VoteDataDirection,
} from "../types/dataclasses";
// @ts-ignore
import { getFileFromPath } from "./files";
import { fetchEventSource } from "@microsoft/fetch-event-source";

export async function create_user(email: string, APIUrl: string) {
  const response = await axios.post(`${APIUrl}/create/user`, {
    email: email,
  });
  console.log(response);
  if (response.status !== 200) {
    throw new Error("Failed to create user");
  }
}

export async function get_user_data(
  token: string,
  APIUrl: string
): Promise<User> {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await axios.get(`${APIUrl}/get_user_data?token=${token}`, {
    headers: {
      TZ: timezone,
    },
  });
  if (response.status !== 200) {
    throw new Error("Failed to fetch user data");
  }
  return response.data;
}

export async function update_user_data(
  token: string,
  user: UserUpdate,
  APIUrl: string
) {
  const response = await axios.post(
    `${APIUrl}/update_user_data?token=${token}`,
    user
  );
  if (response.status !== 200) {
    throw new Error("Failed to update user data");
  }
}

export async function delete_user_context(token: string, APIUrl: string) {
  const response = await axios.post(
    `${APIUrl}/delete_user_context?token=${token}`
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete user data");
  }
}

// Todos
export async function get_todos(
  token: string,
  APIUrl: string
): Promise<Todo[]> {
  const response = await axios.get(`${APIUrl}/todos?token=${token}`);
  if (response.status !== 200) {
    console.error("Failed to fetch todos");
    return [];
  }
  return response.data;
}

export async function create_todo(
  newTodo: TodoCreate,
  token: string,
  APIUrl: string
) {
  const response = await axios.post(`${APIUrl}/todos?token=${token}`, newTodo);
  if (response.status !== 200) {
    console.error("Failed to create todo");
  }
}

export async function poll_chat_with_context(
  chat_id: number,
  token: string,
  APIUrl: string
) {
  const response = await axios.get(
    `${APIUrl}/poll_chat_with_context/${chat_id}?token=${token}`
  );
  return response.data.partial_answer;
}

export async function update_todo(
  patch: TodoUpdate,
  token: string,
  APIUrl: string
) {
  const updateDict: Record<string, any> = {};
  if (patch.date) {
    updateDict["date"] = patch.date;
  }
  if (patch.description !== undefined) {
    updateDict["description"] = patch.description;
  }
  if (patch.status) {
    updateDict["status"] = patch.status;
  }
  if (patch.workstreams !== null && patch.workstreams !== undefined) {
    updateDict["workstreams"] = patch.workstreams;
  }
  const response = await axios.put(
    `${APIUrl}/todos/${patch.id}?token=${token}`,
    updateDict
  );
  if (response.status !== 200) {
    console.error("Failed to update todo");
  }
}

// Sources
export async function get_sources(
  todo_id: number,
  token: string,
  APIUrl: string
): Promise<Source[]> {
  const response = await axios.get(
    `${APIUrl}/sources/${todo_id}?token=${token}`
  );
  if (response.status !== 200) {
    console.error("Failed to fetch sources");
    return [];
  }
  return response.data.sources;
}

// Summary
export async function get_daily_summaries(
  token: string,
  APIUrl: string
): Promise<Summary[]> {
  const response = await axios.get(
    `${APIUrl}/get_daily_summaries?token=${token}`
  );
  if (response.status !== 200) {
    console.error("Failed to fetch summaries");
    return [];
  }
  return response.data.daily_summaries;
}

// Chat
export async function get_chat_history(
  token: string,
  APIUrl: string
): Promise<Chat[]> {
  const response = await axios.get(`${APIUrl}/chat_history?token=${token}`);
  if (response.status !== 200) {
    throw new Error("Failed to get chat history");
  }
  return response.data.chats; // Sorted in reverse chronological order by the backend
  // return response.data.chats.sort(
  //   (
  //     a: { date: string | number | Date },
  //     b: { date: string | number | Date },
  //   ) => {
  //     return new Date(b.date).getTime() - new Date(a.date).getTime();
  //   },
  // );
}

export async function get_shared_chat(
  chatId: string,
  chatPublicToken: string,
  APIUrl: string
): Promise<Chat> {
  const response = await axios.get(`${APIUrl}/get_shared_chat/${chatId}`, {
    params: {
      public_token: chatPublicToken,
    },
  });
  if (response.status !== 200) {
    throw new Error("Failed to get shared chat");
  }
  return response.data;
}

export async function delete_chat(
  chatId: number,
  token: string,
  APIUrl: string
): Promise<void> {
  const response = await axios.delete(
    `${APIUrl}/chat/${chatId}?token=${token}`
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete chat");
  }
}

export async function set_chat_as_public(
  chatId: number,
  token: string,
  APIUrl: string
): Promise<{ public_token: string }> {
  const response = await axios.patch(
    `${APIUrl}/set_chat_as_public/${chatId}?token=${token}`
  );
  if (response.status !== 200) {
    throw new Error("Failed to set chat public");
  }
  return response.data;
}

export async function get_chat_votes(
  chat_id: number,
  token: string,
  APIUrl: string
): Promise<VoteData[]> {
  const response = await axios.get(
    `${APIUrl}/get_votes/${chat_id}?token=${token}`
  );
  if (response.status !== 200) {
    console.error("Failed to fetch votes");
    return [];
  }
  return response.data.votes;
}

export async function post_vote(
  chat_id: number,
  message_index: number,
  direction: VoteDataDirection,
  token: string,
  APIUrl: string
): Promise<void> {
  const response = await axios.post(`${APIUrl}/vote?token=${token}`, {
    chat_id,
    message_index,
    direction,
  });
  if (response.status !== 200) {
    throw new Error("Failed to post vote");
  }
}

export async function create_new_chat(
  token: string,
  APIUrl: string
): Promise<number> {
  const response = await axios.post(`${APIUrl}/new_chat?token=${token}`);
  if (response.status !== 200) {
    throw new Error("Failed to create new chat");
  }
  return response.data.chat_id;
}

export async function get_chat_reply(
  question: string,
  chat_id: number,
  assistant: Assistant,
  chatMode: ChatMode,
  token: string,
  APIUrl: string,
  attachment?: string[],
  onNewToken?: (data: string) => void
) {
  const formData = new FormData();
  formData.append("question", question);
  formData.append("chat_id", chat_id.toString());
  formData.append("model", assistant);
  formData.append("mode", chatMode);

  if (attachment) {
    try {
      const attachments = Array.isArray(attachment) ? attachment : [attachment];
      for (let i = 0; i < attachments.length; i++) {
        const file = await getFileFromPath(attachments[i]);
        formData.append("files", file);
      }
    } catch (error) {
      console.error("Failed to read attached file(s) for chat", error);
    }
  }

  try {
    await fetchEventSource(`${APIUrl}/chat_v3?token=${token}`, {
      method: "POST",
      body: formData,
      openWhenHidden: true,
      onmessage(msg) {
        try {
          const response = JSON.parse(msg.data);

          if (onNewToken) {
            onNewToken(response);
          }
        } catch (e) {
          console.log("Error parsing SSE message:", msg.data);
        }
      },
      async onopen(response) {
        if (
          response.ok &&
          response.headers.get("content-type")?.includes("text/event-stream")
        ) {
          return;
        }
        throw new Error(
          `Failed to connect: ${response.status} ${response.statusText}`
        );
      },
      onerror(err) {
        console.error("SSE error:", err);
        throw err;
      },
    });
  } catch (err) {
    console.error("Error during SSE fetch:", err);
    throw err;
  }
}

export async function get_autocomplete(
  question: string,
  chat_id: number,
  token: string,
  APIUrl: string
): Promise<string[]> {
  const response = await axios.post(`${APIUrl}/autocomplete?token=${token}`, {
    question: question,
    chat_id: chat_id,
  });
  if (response.status !== 200) {
    console.error("Failed to fetch autocomplete");
    return [];
  }
  return response.data.autocompletes;
}

export async function get_recommended_questions(
  token: string,
  APIUrl: string
): Promise<string[]> {
  const response = await axios.get(
    `${APIUrl}/recommended_questions?token=${token}`
  );
  if (response.status !== 200) {
    console.error("Failed to fetch recommended questions");
    return [];
  }
  return response.data.recommended_questions;
}

// Workstreams
export async function get_workstreams(
  token: string,
  APIUrl: string
): Promise<any[]> {
  const response = await axios.get(`${APIUrl}/get_workstreams?token=${token}`);
  if (response.status !== 200) {
    throw new Error("Failed to fetch workstreams");
  }
  return response.data.workstreams;
}

export async function edit_workstream(
  token: string,
  APIUrl: string,
  id?: number,
  name?: string,
  capture?: string,
  ignore?: string
): Promise<any> {
  const response = await axios.post(
    `${APIUrl}/edit_workstream?token=${token}`,
    {
      name,
      id,
      capture,
      ignore,
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to create workstream");
  }
  return response.data.workstream;
}

export async function remove_workstream(
  workstreamId: number,
  token: string,
  APIUrl: string
): Promise<void> {
  const response = await axios.delete(
    `${APIUrl}/remove_workstream/${workstreamId}?token=${token}`,
    {}
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete workstream");
  }
}

export async function stop_chat_with_context(
  chatId: number,
  token: string,
  APIUrl: string
): Promise<void> {
  const response = await axios.get(
    `${APIUrl}/stop_chat_with_context/${chatId}?token=${token}`
  );
  if (response.status !== 200) {
    throw new Error("Failed to stop chat");
  }
}

export async function version_info(): Promise<string[]> {
  const response = await axios.get(
    `https://little-bird-releases.s3.us-east-2.amazonaws.com/valid_versions.txt`
  );
  const versions = response.data.split("\n");
  return versions;
}

export async function speech_to_text(
  token: string,
  APIUrl: string,
  audio: Blob
): Promise<string> {
  const formData = new FormData();
  formData.append("file", audio, "recording.wav");

  const response = await axios.post(`${APIUrl}/stt?token=${token}`, formData);

  if (response.status !== 200) {
    throw new Error("Failed to convert speech to text");
  }
  return response.data.text;
}

export async function text_to_speech(
  token: string,
  APIUrl: string,
  text: string
): Promise<string> {
  const response = await axios.post(
    `${APIUrl}/tts?token=${token}`,
    { text },
    { responseType: "blob" }
  );

  if (response.status !== 200) {
    throw new Error("Failed to convert speech to text");
  }

  const blob = new Blob([response.data], { type: "audio/mpeg" });

  return URL.createObjectURL(blob);
}

export async function delete_inputs(
  token: string,
  APIUrl: string,
  timestamp: number
) {
  const response = await axios.post(`${APIUrl}/delete/inputs?token=${token}`, {
    timestamp: timestamp,
  });

  if (response.status !== 200) {
    throw new Error("Failed to delete input");
  }

  return null;
}
