import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CenteredTileProps {
  icon?: IconDefinition;
  title: string;
  description: string;
  content: JSX.Element;
  leadingContent?: JSX.Element;
  titleClassName?: string;
  descriptionClassName?: string;
}

export default function CenteredTile({
  icon,
  title,
  description,
  content,
  leadingContent,
  titleClassName = "",
  descriptionClassName = "",
}: CenteredTileProps) {
  return (
    <div className="bg-darkShadow min-h-screen h-full flex items-center justify-center">
      <div className="w-full sm:max-w-[560px] px-5 sm:px-6 py-0 sm:py-8 mx-auto rounded-lg shadow-card sm:border border-darkLine sm:bg-darkBG sm:backdrop-blur-lg flex flex-col gap-6">
        {icon && (
          <FontAwesomeIcon
            size="2x"
            icon={icon}
            className="self-center text-darkPrimary"
          />
        )}
        {leadingContent && leadingContent}
        <div className="flex flex-col gap-2 items-center text-center">
          <p className={`text-xl text-darkPrimary ${titleClassName}`}>{title}</p>
          <p className={`text-sm text-darkPrimary opacity-60 ${descriptionClassName}`}>
            {description}
          </p>
        </div>
        <div>{content}</div>
      </div>
    </div>
  );
}