import { convertFileSrc } from "@tauri-apps/api/core";
import FilePreview from "./FilePreview";
import Modal from "../ui/Modal";
import { useState, useCallback } from "react";
import { inBrowser } from "../../utils/platform";

const FileGallery = ({
  attachedFiles,
  removeFile,
  convertFileSource,
  focusable,
  size = "sm",
}: {
  attachedFiles: string[];
  convertFileSource: boolean;
  removeFile?: (file: string) => void;
  focusable?: boolean;
  size?: "lg" | "sm";
}) => {
  const [focused, setFocused] = useState<JSX.Element | null>(null);
  const _convertFileSrc = inBrowser() ? (x: string) => x : convertFileSrc;

  const getFileSource = useCallback(
    (file: string): string => {
      // Don't convert blob URLs, data URLs, or http(s) URLs
      if (
        file.startsWith("blob:") ||
        file.startsWith("data:") ||
        file.startsWith("http://") ||
        file.startsWith("https://")
      ) {
        return file;
      }
      return convertFileSource ? _convertFileSrc(file) : file;
    },
    [convertFileSource, _convertFileSrc]
  );

  return (
    <>
      <div
        // TODO: Handle this is broken on narrow screens with multiple files
        className="flex flex-row flex-wrap gap-3"
      >
        {attachedFiles.map((file, index) => {
          const fileSource = getFileSource(file);
          
          if (removeFile) {
            return (
              <div
                key={index.toString()}
                onClick={() =>
                  focusable
                    ? setFocused(
                        <img
                          src={fileSource}
                          alt={file}
                          className="rounded-md object-cover w-full"
                        />
                      )
                    : undefined
                }
                className={focusable ? "cursor-pointer" : ""}
              >
                <FilePreview
                  file={fileSource}
                  removeFile={() => removeFile(file)}
                  size={size}
                />
              </div>
            );
          } else {
            return (
              <div
                key={index.toString()}
                onClick={() =>
                  focusable
                    ? setFocused(
                        <img
                          src={fileSource}
                          alt={file}
                          className="rounded-md object-cover w-full"
                        />
                      )
                    : undefined
                }
                className={focusable ? "cursor-pointer" : ""}
              >
                <FilePreview
                  file={fileSource}
                  size={size}
                />
              </div>
            );
          }
        })}
      </div>
      <Modal
        mainContent={focused ?? <></>}
        isOpen={!!focused}
        onClose={() => setFocused(null)}
      />
    </>
  );
};
export default FileGallery;
