export type Source = {
  text: string;
  app: string;
};

export enum TodoStatus {
  suggestion = "suggestion",
  accepted = "accepted",
  rejected = "rejected",
  completed = "completed",
  deleted = "deleted",
}

export type Todo = {
  id: number;
  description: string;
  status: TodoStatus;
  date: string;
  workstreams: string[];
};

export type TodoCreate = {
  description: string;
  status: TodoStatus;
  date: string;
  workstreams: string[];
};

export type TodoUpdate = {
  id: number;
  description: string;
  status: TodoStatus;
  date: string;
  workstreams: string[];
};

export type User = {
  global_prompt: string;
  email: string;
  latest_input: string;
  total_inputs: string;
  todo_prompt: string;
  summary_prompt: string;
  info_to_ignore_prompt: string;
  name: string;
  aliases: string;
};

export type UserUpdate = {
  global_prompt?: string;
  email?: string;
};

export type Summary = {
  date: string;
  text: string;
};

export type Chat = {
  title: string;
  chat_id: number;
  chat_history: string[][];
  date: string;
};

export type VoteDataDirection = -1 | 1;

export type VoteData = {
  message_index: number;
  direction: VoteDataDirection;
};

export type Workstream = {
  id: number;
  name: string;
  capture: string | null;
  ignore: string | null;
};

export enum Assistant {
  claude = "claude",
  gpt = "gpt",
}

export enum ChatMode {
  smart = "smart",
  fast = "fast",
}
