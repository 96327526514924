import { MediaRecorder, register } from "extendable-media-recorder";
import * as wavEncoder from "extendable-media-recorder-wav-encoder";

import { useRef, useState } from "react";
import { useAppStore } from "../store";

export function useSpeechToText(process: (transcription: string) => void) {
  const { setToastData, setProcessingVoice, speechToText } = useAppStore();

  const [isRecording, setRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const startRecording = async () => {
    try {
      let mediaRecorder = mediaRecorderRef.current;
      if (!mediaRecorder) {
        await register(await wavEncoder.connect());
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaRecorder = new MediaRecorder(stream, {
          mimeType: "audio/wav",
        }) as MediaRecorder;
        mediaRecorderRef.current = mediaRecorder;
      }

      const chunks: Blob[] = [];
      mediaRecorder.onstart = () => setRecording(true);
      mediaRecorder.ondataavailable = (event) => chunks.push(event.data);
      mediaRecorder.onstop = () => {
        setRecording(false);

        try {
          const blob = new Blob(chunks, { type: "audio/wav" });
          // get audio transcription
          setProcessingVoice(true);
          speechToText(blob)
            .then((transcript) => {
              if (!transcript) return;
              process(transcript);
            })
            .catch((err) =>
              setToastData({
                title: "Failed to convert speech to text",
                description: JSON.stringify(err),
                kind: "error",
              }),
            )
            .finally(() => setProcessingVoice(false));
        } catch (err) {
          console.error("Failed to collect recorded audio: ", err);
        }
      };

      mediaRecorder.start();
    } catch (err) {
      setToastData({
        title: "Failed to record audio",
        description: JSON.stringify(err),
        kind: "error",
      });
      console.error("Failed to start recording", err);
    }
  };

  const stopRecording = () => {
    const recorder = mediaRecorderRef.current;
    if (!recorder) return;

    recorder.stop();
  };

  return { isRecording, startRecording, stopRecording };
}
