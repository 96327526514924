import { ReactNode } from 'react';
export default function SectionRow({
  title,
  description,
  actionComponent,
  className = " ",
}: {
  title: string;
  description: ReactNode;
  actionComponent: JSX.Element;
  className?: string;
}) {
  return (
    <div className={"flex items-center gap-2 " + className}>
      <div className="w-full text-start">
        <div className="flex flex-grow text-darkPrimary mb-2 text-xs uppercase font-mono">
          {title}
        </div>
        <div className="flex flex-grow text-darkPrimary opacity-60 text-sm">
          {description}
        </div>
      </div>
      {actionComponent}
    </div>
  );
}
