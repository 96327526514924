import { useEffect, useMemo, useState } from "react";
import { ONBOARDINGSTEP, useAppStore } from "./store";
import LoadingScreen from "./screens/LoadingScreen";
import LandingScreen from "./screens/LandingScreen";
import UpdateScreen from "./screens/UpdateScreen";
import PersonalizeScreen from "./screens/PersonalizeScreen";
import PermitA11yScreen from "./screens/PermitA11yScreen";
import MainScreen from "./screens/MainScreen";
import { useStoreSync } from "./hooks/useStoreSync";
import { SpotLightScreen } from "./screens/SpotLightScreen";
import posthog from "posthog-js";
import { invoke } from "@tauri-apps/api/core";
import { getCurrentWindow } from "@tauri-apps/api/window";
import { inBrowser } from "./utils/platform";
import OnboardingVideoScreen from "./screens/OnboardingVideoScreen";

export function App() {
  const {
    token,
    serverAllowed,
    user,
    fetchChats,
    fetchUser,
    fetchSummaries,
    fetchTodos,
    setOngoingContextCollection,
    setContextCollectorWorking,
    stopContextCollection,
    startContextCollection,
    versionUpdateInfo,
    checkVersionUpdate,
    cancelVersionUpdate,
    contextCollectorWorking,
    onboardingStep,
    hasAccessibilityPermissions,
    setHasAccessibilityPermissions,
    fetchRecommendedQuestions,
  } = useAppStore((state) => state);
  const [load, setLoad] = useState(true);

  useStoreSync();

  useEffect(() => {
    if (!token) return;
    if (serverAllowed) {
      startContextCollection();
    } else {
      stopContextCollection();
    }
  }, [token, serverAllowed]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchUser(),
          fetchChats(),
          fetchSummaries(),
          fetchTodos(),
          fetchRecommendedQuestions(),
          checkVersionUpdate(),
        ]);
      } catch (error) {
        console.error("Error in data fetch interval:", error);
      }
    };
    fetchData();
    const pollInterval = setInterval(fetchData, 20000);
    return () => {
      clearInterval(pollInterval);
    };
  }, []);

  useEffect(() => {
    setLoad(true);
    const checkPermissions = async () => {
      if (inBrowser()) {
        setLoad(false);
        setHasAccessibilityPermissions(true);
        return;
      }
      try {
        const permitted = await invoke<boolean>(
          "check_accessibility_permissions"
        );
        setHasAccessibilityPermissions(permitted);
        setLoad(false);
      } catch (error) {
        console.error("Error checking permissions in App.tsx:", error);
      }
    };
    checkPermissions();
    const highFreqPollInterval = setInterval(async () => {
      await checkPermissions();
      if (inBrowser()) {
        return;
      }
      try {
        const cc_working = await invoke("context_collection_working");
        setContextCollectorWorking(cc_working as boolean);
      } catch (error) {
        console.error("Error in high freq poll interval:", error);
      }
    }, 1000);
    return () => {
      clearInterval(highFreqPollInterval);
    };
  }, []);

  useEffect(() => {
    const checkCollection = async () => {
      try {
        let contextCollectionState = false;
        if (inBrowser()) {
          if (
            user?.latest_input &&
            new Date().getTime() -
              new Date(user.latest_input.replace(" ", "T") + "Z").getTime() <
              10 * 60 * 1000
          ) {
            contextCollectionState = true;
          } else {
            contextCollectionState = false;
          }
        } else {
          contextCollectionState = contextCollectorWorking;
        }
        setOngoingContextCollection(contextCollectionState);
        if (contextCollectionState) {
          posthog.capture("user_has_active_context_collection");
        }

        if (!inBrowser()) {
          invoke("update_context_collection_state", {
            contextCollectionState,
          });
        }
      } catch (error) {
        console.error("Error checking collection status:", error);
      }
    };
    checkCollection();
    const checkInterval = setInterval(async () => {
      await checkCollection();
    }, 2 * 1000);
    return () => {
      clearInterval(checkInterval);
    };
  }, [user, token, serverAllowed, contextCollectorWorking]);

  useEffect(() => {
    if (user?.email) {
      posthog.identify(user.email, {
        email: user.email,
      }); // AS THE USERS DONT HAVE USER ID'S, EMAIL IS USED AS DISTINCT ID'S. IF USER CHANGES EMAIL, THEN WILL NEED TO https://posthog.com/docs/product-analytics/identify#alias-assigning-multiple-distinct-ids-to-the-same-user
    }
  }, [user?.email]);

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.metaKey && event.key === "q") {
        event.preventDefault();
        if (!inBrowser()) {
          await invoke("hide_main_window");
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // TODO: MOVE THESE TO ROUTER AT SOME POINT
  return useMemo(() => {
    if (versionUpdateInfo.needs_update && !cancelVersionUpdate)
      return <UpdateScreen />;
    if (!token) return <LandingScreen />;
    if (load) return <LoadingScreen />;
    if (!hasAccessibilityPermissions && !inBrowser())
      return <PermitA11yScreen />;

    if (onboardingStep === ONBOARDINGSTEP.VIDEO)
      return <OnboardingVideoScreen />;

    if (!user?.name?.trim()) return <PersonalizeScreen />;
    if (!inBrowser() && getCurrentWindow().label === "little-bird-spotlight")
      return <SpotLightScreen />;
    return <MainScreen />;
  }, [
    token,
    load,
    versionUpdateInfo.needs_update,
    cancelVersionUpdate,
    hasAccessibilityPermissions,
    user?.name,
    onboardingStep,
  ]);
}
