import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler } from "react";

export default function IconButton({
  icon,
  onClick,
  size = 14,
  disabled = false,
  className = "",
  style = {},
}: {
  icon: IconDefinition;
  onClick: MouseEventHandler;
  size?: number;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <button
      className={`disabled:opacity-80 group ${className}`} // Add 'group' to enable hover targeting
      onClick={onClick ?? undefined}
      disabled={disabled}
      style={style}
    >
      <FontAwesomeIcon
        icon={icon}
        width={size}
        className={
          "duration-75 transition-colors text-darkIcons group-hover:text-darkPrimary"
        }
      />
    </button>
  );
}
