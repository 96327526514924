const TileModal = ({
  title,
  sideContent,
  mainContent,
}: {
  title: string;
  sideContent: JSX.Element;
  mainContent: JSX.Element;
}) => {
  return (
    <div className="sm:flex flex-row h-[560px] max-h-[80vh] overflow-auto">
      <div className="sm:w-60 px-3 pt-3 pb-1 sm:pt-8 sm:pb-0 overflow-auto">
        <h6 className="hidden font-mono uppercase sm:block text-xs text-start text-darkSecondary mb-3">
          {title}
        </h6>
        <div className="sm:block flex flex-row w-full gap-2">{sideContent}</div>
      </div>
      <div className="flex-1 border-t sm:border-t-0 sm:border-l border-darkLine px-3 sm:px-6 pt-3 sm:pt-8 pb-8 overflow-auto">
        {mainContent}
      </div>
    </div>
  );
};

export default TileModal;
