import { createRoute, createRouter, redirect } from "@tanstack/react-router";
import {
  createRootRouteWithContext,
  Outlet,
  RouterProvider,
} from "@tanstack/react-router";
import { ErrorBoundary } from "@sentry/react";
import { AuthContextType, useAuth } from "./hooks/useAuth";
import { useAppStore } from "./store";
import Toast from "./components/blocks/Toast";
import LoadingScreen from "./screens/LoadingScreen";
import LandingScreen from "./screens/LandingScreen";
import { App } from "./App";
import SharedChatScreen from "./screens/SharedChatScreen";
import DeleteAccountScreen from "./screens/DeleteAccountScreen";

interface RouterContext {
  auth: AuthContextType;
}

const rootRoute = createRootRouteWithContext<RouterContext>()({
  component: () => {
    const { setToastData, _hasHydrated } = useAppStore((state) => state);
    if (!_hasHydrated) return <LoadingScreen />;
    return (
      <>
        <ErrorBoundary
          onError={(e) =>
            setToastData({
              title: "Error",
              description: e instanceof Error ? e.message : JSON.stringify(e),
              kind: "error",
            })
          }
        >
          <>
            <Outlet />
            <Toast />
          </>
        </ErrorBoundary>
      </>
    );
  },
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  component: function Index() {
    return <App />;
  },
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/login",
  component: function Login() {
    return <LandingScreen />;
  },
  beforeLoad: ({ context, location }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({
        to: new URLSearchParams(location.search).get("redirect") || "/",
        replace: true,
      });
    }
  },
});

const deleteAccountRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/delete-account",
  component: () => <DeleteAccountScreen />,
});

const sharedChatRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/shared-chat/$chatId",
  component: () => <SharedChatScreen />,
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  loginRoute,
  deleteAccountRoute,
  sharedChatRoute,
]);

const router = createRouter({
  routeTree,
  context: {
    // auth will initially be undefined
    // We'll be passing down the auth state from within a React component
    auth: undefined!,
  },
});

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export function RouterWrapper() {
  const auth = useAuth();
  return <RouterProvider router={router} context={{ auth }} />;
}
