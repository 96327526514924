import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { useAppStore } from "../../store";
import { useContextCollection } from "../../hooks/useContextCollection";
import SwitchButton from "../ui/SwitchButton";
import { getTimeDifferenceString } from "../../utils/date";
import {
  getBrowser,
  getExtensionLink,
  handleExternalUrlClick,
  inBrowser,
} from "../../utils/platform";
import ListRow from "../ui/ListRow";
import Separator from "../ui/Separator";
import { useEffect, useRef, useState } from "react";
import Tooltip from "../ui/Tooltip";

const CollectionSwitch = () => {
  const { user, ongoingContextCollection } = useAppStore((state) => state);

  const { isLoading, contextCollectionStateDetails } = useContextCollection();

  const [width, setWidth] = useState(0);

  const elementRef = useRef(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        setWidth(entries[0].contentRect.width);
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <Popover>
      <PopoverButton className="w-full" ref={elementRef}>
        <ListRow
          onClick={() => {}}
          title="Context Collection"
          leftIcon={
            <div
              className="flex items-center justify-center border border-darkIcons rounded-full"
              style={{
                height: 18,
                width: 18,
              }}
            >
              {isLoading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="animate-spin"
                  width={8}
                />
              ) : (
                <div
                  className={`w-1.5 h-1.5 rounded-full ${contextCollectionStateDetails.iconColor}`}
                />
              )}
            </div>
          }
          higlighted={false}
        />
      </PopoverButton>

      <PopoverPanel
        anchor="bottom"
        className={`bg-darkField backdrop-blur-lg shadow-card flex flex-row items-center rounded border border-darkLine overflow-visible`}
        style={{
          width,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {({ close }) => (
          <div className="p-4">
            <div className="font-mono text-xs uppercase">
              <div className="text-darkSecondary">Last Collection</div>
              <div className="text-darkPrimary mt-1.5">
                {getTimeDifferenceString(user?.latest_input!)}
              </div>
            </div>
            <Separator className="my-4" />
            <div className="text-sm text-darkSecondary">
              Toggle whether Little Bird is learning from your actions.
            </div>

            {inBrowser() ? (
              <SwitchButton
                className="w-full mt-5"
                isOn={ongoingContextCollection}
                onPress={() => {
                  handleExternalUrlClick(getExtensionLink(getBrowser()));
                }}
              >
                {getBrowser()} Addon
              </SwitchButton>
            ) : (
              <Tooltip label={contextCollectionStateDetails.tooltipText}>
                <button
                  className={`mt-5 w-full flex flex-row items-center justify-center opacity-90 disabled:opacity-50 hover:opacity-100 h-11 rounded text-xs font-mono whitespace-nowrap uppercase text-darkPrimary px-3 border border-darkLine gap-2`}
                  onClick={async () => {
                    await contextCollectionStateDetails.onPress();
                    close();
                  }}
                  disabled={isLoading}
                >
                  <div
                    className={`w-1.5 h-1.5 rounded-full ${contextCollectionStateDetails.iconColor}`}
                  />
                  {isLoading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="animate-spin"
                    />
                  ) : (
                    contextCollectionStateDetails.text
                  )}
                </button>
              </Tooltip>
            )}
          </div>
        )}
      </PopoverPanel>
    </Popover>
  );
};
export default CollectionSwitch;
