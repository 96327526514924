import { useAppStore } from "../store";
import Button from "../components/ui/Button";
import CenteredTile from "../components/ui/CenteredTile";
import { useEffect } from "react";
import { handleExternalUrlClick } from "../utils/platform";

const UpdateScreen = () => {
  const { versionUpdateInfo, checkVersionUpdate } = useAppStore(
    (state) => state
  );

  useEffect(() => {
    checkVersionUpdate();
  }, []);

  return (
    <CenteredTile
      title="Update Required"
      titleClassName="cursor-default"
      description="A new version is available. Please update to continue using the application."
      descriptionClassName="cursor-default"
      content={
        <div className="flex flex-col gap-4">
          <p className="text-center text-gray-600 dark:text-gray-400 cursor-default select-none">
            Latest Version: {versionUpdateInfo.latestVersion}
            <br />
            Your Version: {versionUpdateInfo.currentVersion}
          </p>
          <Button
            className="w-full"
            onPress={() => {
              handleExternalUrlClick(versionUpdateInfo.download_url);
            }}
          >
            Download Update
          </Button>
        </div>
      }
    />
  );
};

export default UpdateScreen;