import { useRef } from "react";
import { useEffect } from "react";
import { useAppStore } from "../store";
import { emit, listen, UnlistenFn } from "@tauri-apps/api/event";
import { inBrowser } from "../utils/platform";

export const useStoreSync = () => {
  if (inBrowser()) {
    return;
  }
  const syncStateUnListener = useRef<UnlistenFn>();
  const serverAllowedUnListener = useRef<UnlistenFn>();
  const { token, APIUrl, _hasHydrated } = useAppStore((state) => state);

  const setupSyncStateListener = async () => {
    const unListen = await listen("sync-state", (event) => {
      const { key, value } = event.payload as { key: string; value: any };
      useAppStore.setState({ [key]: value });
    });
    syncStateUnListener.current = unListen;
  };

  const setupServerAllowedListener = async () => {
    const unListen = await listen<boolean>("SERVER_ALLOWED_EVENT", (event) => {
      const serverAllowed = event.payload;
      useAppStore.setState({
        serverAllowed,
        serverAllowedLastUpdated: new Date(),
      });
    });
    serverAllowedUnListener.current = unListen;
  };

  useEffect(() => {
    if (!_hasHydrated) return;
    setupSyncStateListener();
    setupServerAllowedListener();
    return () => {
      if (syncStateUnListener.current) syncStateUnListener.current();
      if (serverAllowedUnListener.current) serverAllowedUnListener.current();
    };
  }, [_hasHydrated]);

  useEffect(() => {
    if (!token || !APIUrl || !_hasHydrated) return;
    emit("update-state", { APIUrl, token });
  }, [token, APIUrl, _hasHydrated]);
};
