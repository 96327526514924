import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
export default function TableRow({
  title,
  description,
  secondaryDescription,
  persistentActions,
  hoveredActions,
  actionsDescription,
  isSelected,
  onClick,
  leadingIcon,
}: {
  title: string;
  description?: string;
  secondaryDescription: string | JSX.Element;
  persistentActions: JSX.Element;
  hoveredActions?: JSX.Element;
  actionsDescription: string;
  isSelected: boolean;
  onClick: () => void;
  leadingIcon?: IconDefinition;
}) {
  // used to display additional actions on hover
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`${
        isSelected ? "bg-darkField border border-darkLine" : ""
      } w-full relative text-sm center`}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      onClick={(e) => !e.isDefaultPrevented() && onClick()}
    >
      {/* MOBILE VARIANT    */}
      <div className="sm:hidden py-3 flex flex-col space-y-3 border-b border-darkLine cursor-pointer rounded">
        {leadingIcon && (
          <FontAwesomeIcon
            icon={leadingIcon}
            width={14}
            className={"text-darkPrimary"}
          />
        )}
        <span className="truncate text-darkPrimary">{title}</span>
        {description && (
          <span className="truncate text-darkSecondary">{description}</span>
        )}

        <span className="flex flex-row items-center pt-1 justify-between text-xs text-darkSecondary font-mono uppercase">
          <div className="truncate text-xs text-darkSecondary font-mono uppercase">
            {secondaryDescription}
          </div>

          <div className="flex flex-row gap-3.5">
            {persistentActions}
            {hoveredActions}
          </div>
        </span>
      </div>
      <div className="hidden sm:flex px-4 py-3 items-center flex-row space-x-4 overflow-hidden hover:bg-darkField border border-transparent hover:border-darkLine cursor-pointer rounded">
        {leadingIcon && (
          <FontAwesomeIcon
            icon={leadingIcon}
            width={14}
            className={"text-darkPrimary"}
          />
        )}
        <span
          className={`truncate min-w-[40px] text-darkPrimary ${
            description ? "shrink-1" : "flex-grow"
          }`}
        >
          {title}
        </span>
        {description && (
          <span className="truncate basis-0 grow min-w-[40px] text-darkSecondary">
            {description}
          </span>
        )}

        <span className="truncate flex-none min-w-[40px] max-w-36 px-2 text-xs text-darkSecondary font-mono uppercase">
          {secondaryDescription}
        </span>
        <span className="flex flex-row gap-3.5">
          {persistentActions}
          {hovered && hoveredActions}
        </span>

        {hoveredActions && !hovered && (
          // IF THERE ARE HOVER ACTIONS, HIDE
          <span className="font-mono w-10 whitespace-nowrap flex-shrink-0 text-xs text-end text-darkSecondary">
            {actionsDescription}
          </span>
        )}

        {!hoveredActions && (
          // IF THERE ARE NO HOVER ACTIONS, SHOW THE TEXT ALL THE TIME
          <span className="font-mono w-10 whitespace-nowrap flex-shrink-0 text-xs text-end text-darkSecondary">
            {actionsDescription}
          </span>
        )}
      </div>
    </div>
  );
}
