import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  format,
  parse,
  parseISO,
} from "date-fns";

export const formatDate = (
  dateStr: string,
  originalFormat: string = "iso",
  newFormat: string = "MMM d",
) => {
  try {
    // If tz is not specified, it will be parsed as local time
    // For example, "2024-08-01" will be parsed into a Date representing Aug 1 12am local time
    // If then newFormat is "yyyy-MM-dd'T'HH:mmX" and if for example local time is UTC-04:00, it
    // will be formatted as "2024-08-01T00:00:00.000-04:00"
    const parsedDate =
      originalFormat === "iso"
        ? parseISO(dateStr)
        : parse(dateStr, originalFormat, new Date());
    return format(parsedDate, newFormat);
  } catch (e) {
    return dateStr;
  }
};

export const getTimeDifferenceString = (dateInput: string): string => {
  // Parse the user input date
  const dateInputTime = new Date(`${dateInput}Z`);
  const currentTime = new Date();

  const days = differenceInDays(currentTime, dateInputTime);
  const hours = differenceInHours(currentTime, dateInputTime) % 24;
  const minutes = differenceInMinutes(currentTime, dateInputTime) % 60;
  const seconds = differenceInSeconds(currentTime, dateInputTime) % 60;

  const parts: string[] = [];
  if (days > 0) parts.push(`${days}D`);
  if (hours > 0) parts.push(`${hours}H`);
  if (minutes > 0) parts.push(`${minutes}M`);
  if (seconds > 0 || parts.length === 0) parts.push(`${seconds}S`);

  return `${parts.join(" ")} ago`;
};
