import { useEffect, useMemo, useRef, useState } from "react";
import { useAppStore } from "../../store";

export default function ChatTts({}: { disabled?: boolean }) {
  const {
    focusedChat,
    partialAnswers,
    textToSpeech,
    useVoiceMode,
    setVoiceMode,
    ttsMessages,
    setTtsMessages,
  } = useAppStore();

  const playbackRef = useRef<HTMLAudioElement | null>(null);
  const [lastPlaybackPosition, setLastPlaybackPosition] = useState(0);

  const partialAnswer = useMemo(() => {
    return focusedChat ? partialAnswers[focusedChat.chat_id] : null;
  }, [partialAnswers, focusedChat]);

  useEffect(() => {
    const listener = () => {
      // we've reached the end of the queue, and no more messages will be
      // added, voice mode should be disabled now
      if (ttsMessages[0]?.isLast) setVoiceMode(false);
      setTtsMessages(ttsMessages.slice(1));
    };

    playbackRef.current?.addEventListener("ended", listener);
    return () => playbackRef.current?.removeEventListener("ended", listener);
  }, [playbackRef, ttsMessages]);

  useEffect(() => {
    const playback = playbackRef.current;
    if (!playback || !useVoiceMode) return;
    playback.pause();

    if (!ttsMessages[0]) return;
    const abortPlayback = new AbortController();

    textToSpeech(ttsMessages[0].text).then((audio) => {
      if (!audio || abortPlayback.signal.aborted) return;
      playback.src = audio;
      playback.play();
    });

    return () => {
      abortPlayback.abort();
      playback.pause();
    };
  }, [ttsMessages[0]]);

  useEffect(() => {
    if (!partialAnswer?.isThinking || !partialAnswer.partialAnswer) return;
    const playbackEndPosition = partialAnswer.partialAnswer.lastIndexOf(".");

    // no new complete sentences yet
    if (playbackEndPosition <= lastPlaybackPosition) return;

    setTtsMessages([
      ...ttsMessages,
      {
        isLast: false,
        text: partialAnswer.partialAnswer.slice(
          lastPlaybackPosition,
          playbackEndPosition,
        ),
      },
    ]);
    setLastPlaybackPosition(playbackEndPosition);
  }, [partialAnswer]);

  useEffect(() => {
    // make sure we're using a model response, not a user message
    if ((focusedChat?.chat_history.length ?? 1) % 2 != 0) return;

    // voice mode is a one-shot flag, set when an audio message is sent
    if (!useVoiceMode) return;

    let ttsMessage = "";
    focusedChat?.chat_history[focusedChat.chat_history.length - 1]?.forEach(
      (part) => {
        if (part.startsWith("text:")) ttsMessage += part.slice(5);
      },
    );

    setTtsMessages([
      ...ttsMessages,
      { isLast: true, text: ttsMessage.slice(lastPlaybackPosition) },
    ]);
    setLastPlaybackPosition(0);
  }, [focusedChat?.chat_history]);

  return <audio ref={playbackRef} />;
}
