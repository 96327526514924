import { useAppStore } from "../../store";

const Input = ({
  id,
  value,
  placeholder,
  onChange,
  label,
  type,
  info,
  onKeyDown,
}: {
  id: string;
  value: string;
  placeholder: string;
  onChange: (event: any) => void;
  label?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  info?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
}) => {
  const { setStopPropagatingHotkeys } = useAppStore();
  return (
    <div className="flex flex-col gap-3 w-full">
      {label && (
        <label className="text-darkPrimary uppercase text-xs font-mono">
          {label}
        </label>
      )}
      <input
        value={value}
        className="text-sm appearance-none w-full bg-darkField text-darkPrimary border border-darkLine rounded h-12 px-3 focus:outline-none focus:border-darkActive caret-blue"
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={() => setStopPropagatingHotkeys(true)}
        onBlur={() => setStopPropagatingHotkeys(false)}
      />
      {info && <p className="text-darkPrimary text-xs italic">{info}</p>}
    </div>
  );
};

export default Input;
