import { useEffect, useMemo, useRef, useState } from "react";
import { Todo, TodoStatus } from "../../types/dataclasses";
import { faCaretDown, faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import Separator from "../ui/Separator";
import { useAppStore } from "../../store";
import {
  Listbox,
  ListboxButton,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCheck,
  faClock,
  faClose,
  // faHardDrive,
  faTrash,
  faUndo,
  faSignalStream,
  faArrowUpRight,
} from "@fortawesome/pro-regular-svg-icons";
import { format } from "date-fns";
import DatePicker from "../ui/DatePicker";
import IconButton from "../ui/IconButton";
import TableRow from "../ui/TableRow";
import ContentRow from "../ui/ContentRow";
import posthog from "posthog-js";
import { WsList } from "./WsList";
import { getStatusString } from "../../utils/strings";
import { useWindowSize } from "../../hooks/useWindowResize";
import ShowMore from "./ShowMore";
import { WorkstreamSelector } from "./WorkstreamSelector";

function TaskDetails({ task }: { task: Todo }) {
  const { updateTodo, setStopPropagatingHotkeys } = useAppStore();
  const taskInputRef = useRef<HTMLTextAreaElement | null>(null);

  const [description, setDescription] = useState(task.description);

  const windowSize = useWindowSize();

  useEffect(() => {
    const dispose = setTimeout(() => {
      updateTodo({ ...task, description: description });
      if (task.description !== description)
        posthog.capture("user_task_event", {
          actionName: "Task_Meta_Updated",
          tags: task.workstreams,
          isDescriptionUpdated: task.description !== description,
        });
    }, 200);
    return () => clearTimeout(dispose);
  }, [description]);

  useEffect(() => {
    if (taskInputRef.current) {
      taskInputRef.current.style.height = "auto";
      taskInputRef.current.style.height = `${taskInputRef.current.scrollHeight}px`;
    }
  }, [windowSize]);

  return (
    <div className="w-full max-w-[640px] self-center px-4 py-5">
      <div className="flex flex-row py-2 justify-end gap-3.5">
        {task.status === TodoStatus.accepted && (
          <IconButton
            onClick={() => {
              updateTodo({
                ...task,
                status: TodoStatus.completed,
              });
              posthog.capture("user_task_event", {
                actionName: "Task_Status_Updated",
                tags: task.workstreams,
                oldStatus: task.status,
                newStatus: TodoStatus.accepted,
              });
            }}
            icon={faCheck}
          />
        )}
        {task.status === TodoStatus.suggestion && (
          <IconButton
            onClick={() => {
              updateTodo({
                ...task,
                status: TodoStatus.accepted,
              });
              posthog.capture("user_task_event", {
                actionName: "Task_Status_Updated",
                tags: task.workstreams,
                oldStatus: task.status,
                newStatus: TodoStatus.accepted,
              });
            }}
            icon={faAdd}
          />
        )}
        {task.status === TodoStatus.completed && (
          <IconButton
            onClick={() => {
              updateTodo({
                ...task,
                status: TodoStatus.accepted,
              });
              posthog.capture("user_task_event", {
                actionName: "Task_Status_Updated",
                tags: task.workstreams,
                oldStatus: task.status,
                newStatus: TodoStatus.accepted,
              });
            }}
            icon={faUndo}
          />
        )}
        {task.status === TodoStatus.suggestion && (
          <IconButton
            onClick={() => {
              updateTodo({ ...task, status: TodoStatus.rejected });
              posthog.capture("user_task_event", {
                actionName: "Task_Status_Updated",
                tags: task.workstreams,
                oldStatus: TodoStatus.suggestion,
                newStatus: TodoStatus.rejected,
              });
            }}
            icon={faClose}
          />
        )}

        <Popover>
          <PopoverButton>
            <IconButton icon={faClock} onClick={() => {}} />
          </PopoverButton>
          <PopoverPanel
            anchor="bottom end"
            className="mt-1 rounded-md border border-darkLine"
          >
            {({ close }) => (
              <DatePicker
                value={new Date(task.date)}
                onChange={(v) => {
                  updateTodo({ ...task, date: v.toISOString() });
                  posthog.capture("user_task_event", {
                    actionName: "Task_Date_Updated",
                    tags: task.workstreams,
                    oldDate: task.date,
                    newDate: v.toISOString(),
                  });
                  close();
                }}
              />
            )}
          </PopoverPanel>
        </Popover>
        <IconButton
          onClick={() => {
            updateTodo({ ...task, status: TodoStatus.deleted });
            posthog.capture("user_task_event", {
              actionName: "Task_Status_Updated",
              tags: task.workstreams,
              oldStatus: task.status,
              newStatus: TodoStatus.deleted,
            });
          }}
          icon={faTrash}
        />
      </div>

      <div className="pt-8 space-y-7">
        <div className="flex flex-col gap-5">
          <ContentRow title="Due Date" icon={faClock}>
            <div className="text-sm text-darkPrimary truncate">
              {format(new Date(task.date), "d MMM")}
            </div>
          </ContentRow>

          <ContentRow title="Workstreams" icon={faSignalStream}>
            <Listbox
              value={task.workstreams}
              onChange={(workstreams) => {
                updateTodo({ ...task, workstreams });
                posthog.capture("user_task_event", {
                  actionName: "Task_Tags_Updated",
                  tags: task.workstreams,
                  oldTags: task.workstreams,
                  newTags: workstreams,
                });
              }}
              multiple
            >
              <ListboxButton as="div" className="cursor-pointer">
                <div className={`flex flex-row items-center gap-2`}>
                  <div className="text-sm text-darkPrimary truncate">
                    {task.workstreams.length > 0
                      ? task.workstreams.length > 1
                        ? `${task.workstreams[0]} +${
                            task.workstreams.length - 1
                          }`
                        : task.workstreams[0]
                      : ""}
                  </div>
                  <IconButton
                    icon={faArrowUpRight}
                    onClick={() => {}}
                    size={10}
                  />
                </div>
              </ListboxButton>

              <WsList />
            </Listbox>
          </ContentRow>

          <ContentRow title="Status" icon={faClock}>
            <div className="text-sm text-darkPrimary truncate">
              {getStatusString(task.status)}
            </div>
          </ContentRow>
          {/* <ContentRow title="Context" icon={faHardDrive}>
            <>
              <div className="text-sm text-darkPrimary truncate">View</div>
              <IconButton icon={faArrowUpRight} onClick={() => {}} size={10} />
            </>
          </ContentRow> */}
        </div>

        <Separator />
        <textarea
          ref={taskInputRef}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            e.target.style.height = "auto";
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          placeholder="Add Description..."
          className="bg-transparent w-full resize-none outline-none caret-blue"
          onFocus={() => setStopPropagatingHotkeys(true)}
          onBlur={() => setStopPropagatingHotkeys(false)}
        />

        {/* <Markdown text={summary.text} /> */}
      </div>
    </div>
  );
}

function Tasks() {
  const { todos, activeTodoId, workstreamFilter, updateTodo } = useAppStore();

  const [expandAccepted, setExpandAccepted] = useState(true);
  const [expandSuggested, setExpandSuggested] = useState(true);
  const [expandCompleted, setExpandCompleted] = useState(false);

  const [showAllAccepted, setShowAllAccepted] = useState(false);
  const [showAllSuggested, setShowAllSuggested] = useState(false);
  const [showAllCompleted, setShowAllCompleted] = useState(false);

  const DEFAULT_ROW_SHOWN = 5;

  const acceptedTasks = useMemo(
    () =>
      todos.filter(
        (t) =>
          t.status == TodoStatus.accepted &&
          (workstreamFilter.length == 0 ||
            t.workstreams.includes(workstreamFilter))
      ),
    [todos, workstreamFilter]
  );

  // task suggestions can be collapsed to show only the first 4 entries
  const suggestedTasks = useMemo(
    () =>
      todos.filter(
        (t) =>
          t.status == TodoStatus.suggestion &&
          (workstreamFilter.length == 0 ||
            t.workstreams.includes(workstreamFilter))
      ),
    [todos, workstreamFilter]
  );

  const completedTasks = useMemo(
    () =>
      todos.filter(
        (t) =>
          t.status == TodoStatus.completed &&
          (workstreamFilter.length == 0 ||
            t.workstreams.includes(workstreamFilter))
      ),
    [todos, workstreamFilter]
  );

  const activeTodo = useMemo(() => {
    return todos.find(
      (t) =>
        t.id == activeTodoId &&
        t.status != TodoStatus.deleted &&
        t.status != TodoStatus.rejected
    );
  }, [todos, activeTodoId]);

  if (activeTodo) {
    return <TaskDetails key={activeTodoId} task={activeTodo} />;
  }

  const filterShownItems = (items: Todo[], showAll: boolean) => {
    if (showAll) {
      return items;
    }
    return items.slice(0, DEFAULT_ROW_SHOWN);
  };

  return (
    <div className="px-4 pt-2">
      {/* accepted */}
      <div
        onClick={() => {
          setExpandAccepted(!expandAccepted);
        }}
        className="h-10 flex flex-row items-center text-darkSecondary cursor-pointer group"
      >
        <div className="font-mono text-xs text-darkSecondary uppercase">
          TODO ({acceptedTasks.length})
        </div>
        <FontAwesomeIcon
          size="2xs"
          icon={expandAccepted ? faCaretDown : faCaretRight}
          className="ms-auto me-3 group-hover:text-darkPrimary"
        />
      </div>

      {expandAccepted && (
        <>
          {filterShownItems(acceptedTasks, showAllAccepted).map((task) => (
            <TableRow
              key={task.id}
              isSelected={activeTodoId === task.id}
              onClick={() => {
                // setActiveTodoId(task.id)
              }}
              title={task?.description ?? "Empty task"}
              secondaryDescription={<WorkstreamSelector task={task} />}
              persistentActions={
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    updateTodo({
                      ...task,
                      status: TodoStatus.completed,
                    });
                    posthog.capture("user_task_event", {
                      actionName: "Task_Status_Updated",
                      tags: task.workstreams,
                      oldStatus: task.status,
                      newStatus: TodoStatus.completed,
                    });
                  }}
                  icon={faCheck}
                />
              }
              hoveredActions={
                <>
                  <Popover onClick={(e) => e.preventDefault()}>
                    <PopoverButton>
                      <IconButton icon={faClock} onClick={() => {}} />
                    </PopoverButton>
                    <PopoverPanel
                      anchor="bottom end"
                      className="mt-1 rounded-md border border-darkLine"
                    >
                      {({ close }) => (
                        <DatePicker
                          value={new Date(task.date)}
                          onChange={(v) => {
                            updateTodo({ ...task, date: v.toISOString() });
                            posthog.capture("user_task_event", {
                              actionName: "Task_Date_Updated",
                              tags: task.workstreams,
                              oldDate: task.date,
                              newDate: v.toISOString(),
                            });

                            close();
                          }}
                        />
                      )}
                    </PopoverPanel>
                  </Popover>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      updateTodo({ ...task, status: TodoStatus.deleted });
                      posthog.capture("user_task_event", {
                        actionName: "Task_Status_Updated",
                        tags: task.workstreams,
                        oldStatus: task.status,
                        newStatus: TodoStatus.deleted,
                      });
                    }}
                    icon={faTrash}
                  />
                </>
              }
              actionsDescription={format(
                new Date(task.date),
                "d MMM"
              ).toUpperCase()}
            />
          ))}

          {acceptedTasks.length > DEFAULT_ROW_SHOWN && (
            <ShowMore
              onClick={() => setShowAllAccepted(!showAllAccepted)}
              text={
                showAllAccepted
                  ? "Show Less"
                  : `Show More (${acceptedTasks.length - DEFAULT_ROW_SHOWN})`
              }
            />
          )}
        </>
      )}

      {/* suggested */}
      <Separator className="my-2" />
      <div
        onClick={() => {
          setExpandSuggested(!expandSuggested);
        }}
        className="h-10 flex flex-row items-center text-darkSecondary cursor-pointer group"
      >
        <div className="font-mono text-xs text-darkSecondary uppercase">
          SUGGESTED ({suggestedTasks.length})
        </div>
        {/* expand/collapse */}
        <FontAwesomeIcon
          size="2xs"
          icon={expandSuggested ? faCaretDown : faCaretRight}
          className="ms-auto me-3 group-hover:text-darkPrimary"
        />
      </div>
      {expandSuggested && (
        <div>
          {/* tasks */}
          {filterShownItems(suggestedTasks, showAllSuggested).map((task) => (
            <TableRow
              key={task.id}
              isSelected={activeTodoId === task.id}
              onClick={() => {
                // setActiveTodoId(task.id)
              }}
              title={task?.description ?? "Empty task"}
              secondaryDescription={<WorkstreamSelector task={task} />}
              persistentActions={
                <>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      updateTodo({
                        ...task,
                        status: TodoStatus.accepted,
                      });
                    }}
                    icon={faAdd}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      updateTodo({
                        ...task,
                        status: TodoStatus.rejected,
                      });
                    }}
                    icon={faClose}
                  />
                </>
              }
              actionsDescription={format(
                new Date(task.date),
                "d MMM"
              ).toUpperCase()}
            />
          ))}

          {/* show more/show less */}
          {suggestedTasks.length > DEFAULT_ROW_SHOWN && (
            <ShowMore
              onClick={() => setShowAllSuggested(!showAllSuggested)}
              text={
                showAllSuggested
                  ? "Show Less"
                  : `Show More (${suggestedTasks.length - DEFAULT_ROW_SHOWN})`
              }
            />
          )}
        </div>
      )}

      {/* completed */}
      <Separator className="my-2" />
      <div
        onClick={() => {
          setExpandCompleted(!expandCompleted);
        }}
        className="h-10 flex flex-row items-center text-darkSecondary cursor-pointer group"
      >
        <div className="font-mono text-xs text-darkSecondary uppercase">
          Complete ({completedTasks.length})
        </div>
        <FontAwesomeIcon
          size="2xs"
          icon={expandCompleted ? faCaretDown : faCaretRight}
          className="ms-auto me-3 group-hover:text-darkPrimary"
        />
      </div>
      {expandCompleted && (
        <>
          {filterShownItems(completedTasks, showAllCompleted).map((task) => (
            <TableRow
              key={task.id}
              isSelected={activeTodoId === task.id}
              onClick={() => {
                // setActiveTodoId(task.id)
              }}
              title={task?.description ?? "Empty task"}
              secondaryDescription={<WorkstreamSelector task={task} />}
              persistentActions={
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    updateTodo({
                      ...task,
                      status: TodoStatus.accepted,
                    });
                  }}
                  icon={faUndo}
                />
              }
              hoveredActions={
                <>
                  <Popover onClick={(e) => e.preventDefault()}>
                    <PopoverButton>
                      <IconButton icon={faClock} onClick={() => {}} />
                    </PopoverButton>
                    <PopoverPanel
                      anchor="bottom end"
                      className="mt-1 rounded-md border border-darkLine"
                    >
                      {({ close }) => (
                        <DatePicker
                          value={new Date(task.date)}
                          onChange={(v) => {
                            updateTodo({ ...task, date: v.toISOString() });
                            close();
                          }}
                        />
                      )}
                    </PopoverPanel>
                  </Popover>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      updateTodo({ ...task, status: TodoStatus.deleted });
                    }}
                    icon={faTrash}
                  />
                </>
              }
              actionsDescription={format(
                new Date(task.date),
                "d MMM"
              ).toUpperCase()}
            />
          ))}

          {/* show more/show less */}
          {completedTasks.length > DEFAULT_ROW_SHOWN && (
            <ShowMore
              onClick={() => setShowAllCompleted(!showAllCompleted)}
              text={
                showAllCompleted
                  ? "Show Less"
                  : `Show More (${completedTasks.length - DEFAULT_ROW_SHOWN})`
              }
            />
          )}
        </>
      )}
    </div>
  );
}

export default Tasks;
