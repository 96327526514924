import { invoke } from "@tauri-apps/api/core";
import { faComputer } from "@fortawesome/pro-regular-svg-icons";

import CenteredTile from "../components/ui/CenteredTile";
import SectionRow from "../components/ui/SectionRow";
import Separator from "../components/ui/Separator";
import SwitchButton from "../components/ui/SwitchButton";
import useAutoStart from "../hooks/useAutoStart";

const PermitA11yScreen = () => {
  const { status, enable, disable } = useAutoStart();

  return (
    <CenteredTile
      icon={faComputer}
      title="Enable Context Collection"
      description="Context Collection uses data on your screen to help Little Bird create tasks, give advice, and personalize your chats."
      content={
        <div className="flex flex-col gap-8">
          <Separator className="border-darkLine" />
          <SectionRow
            title="Accessibility Settings"
            description="Enable Context Collection for tasks, chats, and more."
            actionComponent={
              <SwitchButton
                onPress={() => {
                  invoke("request_accessibility_permissions");
                }}
                isOn={false}
              >
                Enable Access
              </SwitchButton>
            }
          />

          {status !== null && (
            <SectionRow
              title="Start little bird on login"
              description="Start Little Bird when you turn on your computer."
              actionComponent={
                <SwitchButton
                  onPress={async () => {
                    if (status) {
                      await disable();
                    } else {
                      await enable();
                    }
                  }}
                  isOn={status}
                >
                  Enable
                </SwitchButton>
              }
            />
          )}

          <Separator className="border-darkLine" />
        </div>
      }
    />
  );
};

export default PermitA11yScreen;
