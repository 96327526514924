import { useAppStore } from "../../store";
import { stringToColor } from "../../utils/strings";
import { FilterButton } from "../ui/FilterButton";

export function WorkstreamFilter() {
  const { workstreams, workstreamFilter, setWorkstreamFilter } = useAppStore();

  return (
    <div className="h-12 flex flex-row overflow-y-hidden overflow-x-auto items-center space-x-3">
      <FilterButton
        label="All tasks"
        isActive={workstreamFilter === ""}
        onClick={() => setWorkstreamFilter("")}
      />
      {workstreams
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .map((workstream) => (
          <div key={workstream.id}>
            <FilterButton
              leftIcon={
                <div
                  className={`w-1.5 h-1.5 rounded-full`}
                  style={{
                    backgroundColor: stringToColor(workstream.name),
                  }}
                />
              }
              label={workstream.name}
              isActive={workstreamFilter === workstream.name}
              onClick={() => setWorkstreamFilter(workstream.name)}
            />
          </div>
        ))}
    </div>
  );
}
