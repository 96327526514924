import { Summary } from "../../types/dataclasses";
import Separator from "../ui/Separator";
import { useAppStore } from "../../store";
import { faMessages } from "@fortawesome/pro-regular-svg-icons";
import { format } from "date-fns";
import IconButton from "../ui/IconButton";
import TableRow from "../ui/TableRow";
import Markdown from "../ui/Markdown";
import { formatDate } from "../../utils/date";

function JournalDetails({ summary }: { summary: Summary }) {
  return (
    <div className="w-full max-w-[640px] self-center px-4 py-5">
      <div className="pt-8 space-y-7">
        <div className="flex flex-col gap-5">
          <div className="text-2xl font-medium select-text">
            {formatDate(summary.date, undefined, "d MMM")}
          </div>
          {/* <ContentRow title="Context" icon={faHardDrive}>
            <>
              <div className="text-sm text-darkPrimary truncate">View</div>
              <IconButton icon={faArrowUpRight} onClick={() => {}} size={10} />
            </>
          </ContentRow> */}
        </div>
        <Separator />
        <Markdown text={summary.text} />
      </div>
    </div>
  );
}

function Journal() {
  const { summaries, focusedSummary, setFocusedSummary } = useAppStore();

  if (focusedSummary) {
    return (
      <div className="flex flex-col overflow-y-auto">
        <JournalDetails summary={focusedSummary} />
      </div>
    );
  }

  return (
    <div className="px-4 pt-2">
      {summaries.map((journal, index) => {
        return (
          <TableRow
            key={index.toString()}
            isSelected={false}
            onClick={() => setFocusedSummary(journal)}
            title={journal.text}
            secondaryDescription=""
            persistentActions={
              <>
                <IconButton
                  className="w-7"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  icon={faMessages}
                />
              </>
            }
            actionsDescription={format(
              new Date(journal.date),
              "d MMM"
            ).toUpperCase()}
          />
        );
      })}
    </div>
  );
}

export default Journal;
