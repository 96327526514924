import { useEffect, useRef } from "react";
import { inBrowser } from "../../utils/platform";
import { platform } from "@tauri-apps/plugin-os";

export default function DrawerLayout({
  panelHeader,
  panelContent,
  mainHeader,
  mainContent,
  isOpen,
  closeDrawer,
}: {
  panelHeader: JSX.Element;
  panelContent: JSX.Element;
  mainHeader: JSX.Element;
  mainContent: JSX.Element;
  isOpen: boolean;
  closeDrawer: () => void;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const os = !inBrowser() && platform();
  const onMobile = os === "android" || os === "ios";

  useEffect(() => {
    if (!onMobile) return;
    const handleResize = () => {
      if (containerRef.current) {
        const height = window.visualViewport?.height || window.innerHeight;
        containerRef.current.style.maxHeight = `${height}px`;
        // This is a hack to fix the issue where the drawer content is scrolled down
        setTimeout(() => (document.documentElement.scrollTop = 0), 0);
      }
    };

    window.visualViewport?.addEventListener("resize", handleResize);
    return () => {
      window.visualViewport?.removeEventListener("resize", handleResize);
    };
  }, [onMobile, containerRef.current]);

  return (
    <div
      ref={containerRef}
      className={`flex flex-1 h-full border-t border-darkLine overflow-hidden relative`}
    >
      {/* Backdrop */}
      {isOpen && (
        <div
          className="fixed inset-0 z-0 sm:hidden"
          onClick={closeDrawer} // Close the drawer when the backdrop is clicked
        ></div>
      )}

      {/* Sliding Panel */}
      <div
        className={`flex flex-col h-full border-r border-darkLine transition-all duration-500 ease-in-out z-0 w-[320px] ${
          isOpen ? "" : "hidden"
        }`}
      >
        <div className="flex flex-row justify-between items-center">
          {panelHeader}
        </div>
        <div className="overflow-y-auto">{panelContent}</div>
      </div>

      {/* Main Content Area */}
      <div
        className={`min-w-full sm:min-w-0 flex-1 flex flex-col transition-transform duration-500 ease-in-out ${
          isOpen
            ? "pointer-events-none sm:pointer-events-auto opacity-25 sm:opacity-100"
            : ""
        }`}
      >
        <div className="flex flex-row justify-between items-center border-b border-darkLine">
          {mainHeader}
        </div>
        {/* Main content takes up remaining space with flex-grow */}
        <div className="overflow-auto flex-1 flex flex-col">{mainContent}</div>
      </div>
    </div>
  );
}
