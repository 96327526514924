import { enable, isEnabled, disable } from "@tauri-apps/plugin-autostart";
import { useCallback, useEffect, useState } from "react";

export default function useAutoStart() {
  const [status, setStatus] = useState<boolean | null>(null);

  const checkAutoStartState = useCallback(async () => {
    setStatus(await isEnabled());
    setStatus(await isEnabled());
  }, []);

  useEffect(() => {
    checkAutoStartState();
  }, []);

  return {
    status,
    enable: async () => {
      await enable();
      await checkAutoStartState();
    },
    disable: async () => {
      await disable();
      await checkAutoStartState();
    },
  };
}
