import { useAppStore } from "../../store";

const TextArea = ({
  id,
  value,
  placeholder,
  onChange,
  label,
  info,
  onBlur,
}: {
  id: string;
  value: string;
  placeholder: string;
  onChange: (event: any) => void;
  label?: string;
  info?: string;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement> | undefined;
}) => {
  const { setStopPropagatingHotkeys } = useAppStore();
  return (
    <div className="flex flex-col gap-3 w-full">
      {label && (
        <label className="text-darkPrimary uppercase text-xs font-mono">
          {label}
        </label>
      )}
      <textarea
        id={id}
        className="text-sm appearance-none w-full bg-darkField text-darkPrimary border border-darkLine rounded py-4 px-3 focus:outline-none focus:border-darkActive box-border resize-none overflow-hidden caret-blue"
        rows={4}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={() => setStopPropagatingHotkeys(true)}
        onBlur={() => {
          setStopPropagatingHotkeys(false);
          onBlur;
        }}
      />

      {info && <p className="text-darkPrimary text-xs italic">{info}</p>}
    </div>
  );
};

export default TextArea;
