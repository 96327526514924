import type React from "react";
import { useRef, useState } from "react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  PopoverPanelProps,
} from "@headlessui/react";

type TooltipProps = {
  label: React.ReactElement | string;
  placement?: PopoverPanelProps["anchor"];
  enterDelay?: number;
  leaveDelay?: number;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Tooltip: React.FC<TooltipProps> = (props) => {
  const {
    children,
    label,
    enterDelay = 250,
    leaveDelay = 150,
    placement = "bottom",
  } = props;
  const baseStyles = `px-3 py-2 transition duration-200 ease-out flex flex-row items-center rounded border border-darkLine overflow-visible text-darkSecondary uppercase text-xs backdrop-blur-2xl shadow-card bg-darkField font-mono [--anchor-gap:4px] sm:[--anchor-gap:8px]`;
  const [isOpen, setIsOpen] = useState(false);
  let enterTimeout = useRef<ReturnType<typeof setTimeout>>();
  let leaveTimeout = useRef<ReturnType<typeof setTimeout>>();

  const handleMouseEnter = () => {
    leaveTimeout.current && clearTimeout(leaveTimeout.current);
    enterTimeout.current = setTimeout(() => setIsOpen(true), enterDelay);
  };

  const handleMouseLeave = () => {
    enterTimeout.current && clearTimeout(enterTimeout.current);
    leaveTimeout.current = setTimeout(() => setIsOpen(false), leaveDelay);
  };

  return (
    <Popover>
      <PopoverButton
        as="div"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`w-full`}
      >
        {children}
      </PopoverButton>
      {isOpen && (
        <PopoverPanel
          static
          transition
          anchor={placement}
          className={`${baseStyles} ${props.className}`}
        >
          {label}
        </PopoverPanel>
      )}
    </Popover>
  );
};

export default Tooltip;
