import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { handleExternalUrlClick } from "../../utils/platform";

const Source = ({ title, url }: { title: string; url: string }) => {
  const nice_url_parts = url.replace(/^(https?:\/\/)?(www\.)?/, "").split(".");
  let nice_url = "";
  for (let part of nice_url_parts) {
    if (part.length > nice_url.length && !part.includes("/")) {
      nice_url = part.slice(0, 1).toUpperCase() + part.slice(1);
    }
  }

  return (
    <div
      onClick={() => handleExternalUrlClick(url)}
      className="bg-darkField cursor-pointer w-full flex flex-col items-start justify-between gap-1 p-3 border border-darkLine rounded"
    >
      <div className="w-full truncate text-xs">{title}</div>
      <div className="w-full flex flex-row gap-2 items-center">
        <FontAwesomeIcon
          icon={faLink}
          className="text-darkPrimary opacity-70"
          width={12}
        />
        <div
          className="truncate font-mono uppercase text-darkPrimary opacity-70"
          style={{
            fontSize: 10,
          }}
        >
          {nice_url}
        </div>
      </div>
    </div>
  );
};
export default Source;
