import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  faArrowLeftToLine,
  faBars,
  faPlus,
  faMessages,
  faCheck,
  faBookOpen,
  faSlidersSimple,
  faPenLine,
  faArrowRightLong,
  faEllipsis,
  faTrash,
  faShareNodes,
} from "@fortawesome/pro-regular-svg-icons";
import { ROUTES, useAppStore } from "../store";
import DrawerLayout from "../components/ui/DrawerLayout";
import ListRow from "../components/ui/ListRow";
import Thread from "../components/blocks/Thread";
import { formatDate } from "../utils/date";
import IconButton from "../components/ui/IconButton";
import Tasks from "../components/blocks/Tasks";
import Modal from "../components/ui/Modal";
import Settings from "../components/blocks/Settings";
import NewTask from "../components/blocks/NewTask";
import { WorkstreamFilter } from "../components/blocks/WorkstreamFilter";
import CollectionSwitch from "../components/blocks/CollectionSwitch";
import WorkstreamSettings from "../components/blocks/WorkstreamSettings";
import Journal from "../components/blocks/Journal";
import posthog from "posthog-js";
import BreadCrumb from "../components/ui/BreadCrumb";
import RecentChats from "../components/blocks/RecentChats";
import DropdownMenu from "../components/ui/DropdownMenu";
import { ChatMode } from "../types/dataclasses";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import ShareModal from "../components/blocks/ShareModal";
import CMDModal from "../components/blocks/CMDModal";
import { useHotkeys } from "../hooks/useHotkeys";

function MainScreen() {
  const {
    todos,
    activeTodoId,
    setActiveTodoId,
    setFocusedChat,
    focusedChat,
    chats,
    fetchChats,
    requestNewChat,
    drawerOpen,
    setDrawerOpen,
    currentRoute,
    setCurrentRoute,
    settingsOpen,
    setSettingsOpen,
    workstreamSettingsOpen,
    setWorkstreamSettingsOpen,
    focusedSummary,
    setFocusedSummary,
    chatMode,
    setChatMode,
    setShareModalOpen,
    deleteChat,
    cmdModalOpen,
    setCmdModalOpen,
  } = useAppStore((state) => state);

  useEffect(() => {
    fetchChats();
  }, []);

  const [width, setWidth] = useState(0);

  const elementRef = useRef(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        setWidth(entries[0].contentRect.width);
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }
    };
  }, []);

  const handleResize = useCallback(() => {
    if (window.innerWidth < 900) {
      setDrawerOpen(false);
    } else {
      setDrawerOpen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useHotkeys();
  // TODO: MOVE THIS TO ZUSTAND
  const [showTaskCreation, setShowTaskCreation] = useState(false);

  const activeTodo = useMemo(() => {
    return todos.find((t) => t.id == activeTodoId);
  }, [todos, activeTodoId]);

  useEffect(() => {
    posthog.capture("$pageview", { pagename: currentRoute });
  }, [currentRoute]);

  useEffect(() => {
    if (activeTodo) {
      posthog.capture("$pageview", {
        pagename: "TASK_DETAILS",
        tags: activeTodo?.workstreams,
        task_id: activeTodo.id,
      });
    }
  }, [activeTodo?.id]);

  useEffect(() => {
    if (focusedSummary) {
      posthog.capture("$pageview", {
        pagename: "JOURNAL_DETAILS",
        date: focusedSummary.date,
      });
    }
  }, [focusedSummary?.date]);

  return (
    <>
      <DrawerLayout
        isOpen={drawerOpen}
        closeDrawer={() => setDrawerOpen(false)}
        panelHeader={
          <div className="flex-1">
            <div className="flex h-12 px-5 justify-end border-darkLine">
              <IconButton
                className="self-center"
                icon={faArrowLeftToLine}
                onClick={() => setDrawerOpen(false)}
              />
            </div>
            <div className="px-3 pt-4 pb-5 border-b border-t border-darkLine">
              <ListRow
                onClick={() => {
                  setCurrentRoute(ROUTES.CHAT);
                  handleResize();
                }}
                title="Chat"
                higlighted={currentRoute === ROUTES.CHAT}
                leftIcon={faMessages}
              />
              <ListRow
                onClick={() => {
                  setCurrentRoute(ROUTES.TASKS);
                  handleResize();
                }}
                title="Tasks"
                higlighted={currentRoute === ROUTES.TASKS}
                leftIcon={faCheck}
              />
              <ListRow
                onClick={() => {
                  setCurrentRoute(ROUTES.JOURNAL);
                  handleResize();
                }}
                title="Journal"
                higlighted={currentRoute === ROUTES.JOURNAL}
                leftIcon={faBookOpen}
              />
              <ListRow
                onClick={() => setSettingsOpen(true)}
                title="Settings"
                leftIcon={faSlidersSimple}
              />
              <CollectionSwitch />
            </div>
          </div>
        }
        panelContent={
          <div className="py-8 px-3">
            <h6 className="text-xs font-mono text-darkSecondary mb-4 uppercase">
              Recent chats
            </h6>
            {chats
              .slice(0, 5) // JUST SET LAST 5 CHAT THREADS AS RECENT
              .map((d, index) => (
                <Popover
                  key={index.toString()}
                  className="mb-4"
                  ref={elementRef}
                >
                  <ListRow
                    leftIcon={faMessages}
                    key={d.chat_id}
                    onClick={() => {
                      handleResize();
                      setFocusedChat(d);
                      setCurrentRoute(ROUTES.CHAT);
                    }}
                    title={d.title.replaceAll('"', "")}
                    higlighted={
                      d.chat_id === focusedChat?.chat_id &&
                      currentRoute === ROUTES.CHAT // LOOKS WRONG IF TASKS ARE FOCUSED
                    }
                  >
                    <PopoverButton
                      as="div"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent propagation to the parent button
                      }}
                    >
                      <IconButton icon={faEllipsis} onClick={() => {}} />
                    </PopoverButton>
                  </ListRow>

                  <PopoverPanel
                    anchor="bottom end"
                    className={`bg-darkField backdrop-blur-lg shadow-card flex flex-col items-center rounded border border-darkLine overflow-visible`}
                    style={{
                      width: width - 24,
                    }}
                  >
                    {({ close }) => (
                      <>
                        <ListRow
                          leftIcon={faShareNodes}
                          title="Share Conversation"
                          onClick={() => {
                            setFocusedChat(d);
                            setShareModalOpen(true);
                          }}
                          noBottomMargin
                        />
                        <ListRow
                          leftIcon={faTrash}
                          title="Delete Conversation"
                          onClick={() => {
                            deleteChat(d.chat_id);
                            close();
                          }}
                          noBottomMargin
                        />
                      </>
                    )}
                  </PopoverPanel>
                </Popover>
              ))}

            <div className="flex flex-row items-center gap-3">
              <h6 className="text-xs font-mono text-darkSecondary uppercase">
                View All
              </h6>
              <IconButton
                icon={faArrowRightLong}
                onClick={() => {
                  setCurrentRoute(ROUTES.RECENT_CHATS);
                  handleResize();
                }}
                size={10}
              />
            </div>
          </div>
        }
        mainHeader={
          <div className="w-full flex flex-1 items-center h-12 px-5">
            {!drawerOpen && (
              <IconButton icon={faBars} onClick={() => setDrawerOpen(true)} />
            )}

            <div
              className={`overflow-hidden w-full flex items-center ${
                !drawerOpen ? "px-4" : "pr-4"
              }`}
            >
              {currentRoute === ROUTES.CHAT ? (
                focusedChat ? (
                  <BreadCrumb
                    icon={faMessages}
                    main={focusedChat.title.replaceAll('"', "")}
                  />
                ) : (
                  <BreadCrumb main="Welcome to Little Bird" />
                )
              ) : null}

              {currentRoute === ROUTES.RECENT_CHATS && (
                <BreadCrumb icon={faMessages} main="Recent Chats" />
              )}

              {currentRoute === ROUTES.TASKS && (
                <>
                  {activeTodo ? (
                    <BreadCrumb
                      onClick={() => setActiveTodoId(null)}
                      main="All Tasks"
                      section={activeTodo.description}
                    />
                  ) : (
                    <WorkstreamFilter />
                  )}
                </>
              )}

              {currentRoute === ROUTES.JOURNAL && (
                <BreadCrumb
                  onClick={
                    focusedSummary ? () => setFocusedSummary(null) : undefined
                  }
                  main="Journal"
                  section={
                    focusedSummary
                      ? formatDate(focusedSummary.date, undefined, "d MMM")
                      : undefined
                  }
                />
              )}
            </div>

            {currentRoute === ROUTES.CHAT && (
              <div className="flex flex-1 flex-row gap-4">
                <DropdownMenu
                  buttonText={chatMode}
                  options={[ChatMode.smart, ChatMode.fast]}
                  setOption={(option) => setChatMode(option as ChatMode)}
                  anchor="bottom end"
                />
                {focusedChat && (
                  <>
                    <IconButton
                      icon={faShareNodes}
                      onClick={() => setShareModalOpen(true)}
                    />

                    <IconButton
                      icon={faTrash}
                      onClick={() => {
                        deleteChat(focusedChat.chat_id);
                      }}
                    />
                  </>
                )}

                <IconButton icon={faPlus} onClick={requestNewChat} />
              </div>
            )}

            {currentRoute === ROUTES.TASKS && (
              <div className="flex flex-row gap-4">
                <IconButton
                  icon={faSlidersSimple}
                  onClick={() => setWorkstreamSettingsOpen(true)}
                />
                <IconButton
                  icon={faPenLine}
                  onClick={() => setShowTaskCreation(true)}
                />
              </div>
            )}
          </div>
        }
        mainContent={
          <>
            {currentRoute === ROUTES.CHAT && <Thread />}
            {currentRoute === ROUTES.TASKS && <Tasks />}
            {currentRoute === ROUTES.JOURNAL && <Journal />}
            {currentRoute === ROUTES.RECENT_CHATS && <RecentChats />}
          </>
        }
      />
      <Modal
        mainContent={<Settings />}
        isOpen={settingsOpen}
        onClose={() => setSettingsOpen(false)}
      />
      <Modal
        mainContent={<NewTask onClose={() => setShowTaskCreation(false)} />}
        isOpen={showTaskCreation}
        onClose={() => setShowTaskCreation(false)}
      />
      <Modal
        mainContent={<WorkstreamSettings />}
        isOpen={workstreamSettingsOpen}
        onClose={() => setWorkstreamSettingsOpen(false)}
      />
      <ShareModal />
      <Modal
        mainContent={<CMDModal />}
        isOpen={cmdModalOpen}
        onClose={() => setCmdModalOpen(false)}
      />
    </>
  );
}

export default MainScreen;
