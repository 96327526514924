import { memo, useMemo } from "react";

import {
  faThumbsDown as thumbsDownOff,
  faThumbsUp as thumbsUpOff,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faThumbsDown as thumbsDownOn,
  faThumbsUp as thumbsUpOn,
} from "@fortawesome/pro-solid-svg-icons";

import Markdown from "../ui/Markdown";
import { Avatar } from "../ui/Avatar";
import CopyButton from "./CopyButton";
import FileGallery from "./FileGallery";
import { useAppStore } from "../../store";
import Source from "./Source";

import AI_Icon from "../../assets/icons/ai-avatar.svg";
import IconButton from "../ui/IconButton";

const FILENAME_REGEX = /-{80}\n\|\s*CONTENT\sOF\s([^|]+?)\s*\|\n-{80}/g;
const IMAGE_FILENAME_REGEX =
  /-{80}\n\|\s*CONTENT\sOF\s([^|]+?)\s*\|\n-{80}\n\n<This\sdocument\sis\san\simage/g;

function parseMessageParts(msgParts: string[]): {
  msgText: string;
  imageSources: string[];
  docSources: string[];
  sources: Record<string, string>[] | null;
} {
  let msgText = "";
  const imageSources: string[] = [];
  let docSources: string[] = [];
  let sources: Record<string, string>[] | null = null;
  for (const part of msgParts) {
    if (part.startsWith("text:")) {
      if (part.startsWith("text:<system>") && part.endsWith("</system>")) {
        let fileNames: string[] = [];
        let matches = part.matchAll(FILENAME_REGEX);
        for (const match of matches) {
          fileNames.push(match[1]);
        }
        matches = part.matchAll(IMAGE_FILENAME_REGEX); // TODO: could combine into single regex
        for (const match of matches) {
          fileNames = fileNames.filter((name) => name !== match[1]);
        }

        docSources = docSources.concat(fileNames);
      } else {
        msgText += part.slice(5);
      }
    } else if (part.startsWith("sources:")) {
      sources = JSON.parse(part.slice(8));
    } else if (part.startsWith("image/")) {
      const colonIdx = part.indexOf(":");
      const dataType = part.slice(0, colonIdx);
      const base64 = part.slice(colonIdx + 1);
      imageSources.push(`data:${dataType};base64,${base64}`);
    } else {
      console.error("Unknown message part type: " + part);
    }
  }
  return { msgText, imageSources, docSources, sources };
}

const Message = ({
  msg,
  isAi,
  measureRef,
  dataIndex,
  inProgressUserMessage,
  hideVotes,
}: {
  msg: string[];
  isAi: boolean;
  measureRef: React.LegacyRef<HTMLDivElement> | undefined;
  dataIndex: number;
  inProgressUserMessage: boolean;
  hideVotes?: boolean;
}) => {
  const { msgText, imageSources, docSources, sources } = parseMessageParts(msg);

  const votes = useAppStore((state) => state.votes);
  const vote = useAppStore((state) => state.vote);
  const focusedChat = useAppStore((state) => state.focusedChat);
  const inProgressAttachedFiles = useAppStore(
    (state) => state.inProgressAttachedFiles
  );

  const currentVote = useMemo(() => {
    const chatVotes = focusedChat ? votes?.[focusedChat?.chat_id] ?? [] : [];
    return (
      chatVotes.find((d) => d.message_index === dataIndex)?.direction ?? null
    );
  }, [votes]);

  if (isAi) {
    return (
      <div
        ref={measureRef}
        data-index={dataIndex}
        className="flex flex-col gap-2.5"
      >
        <div className="flex gap-3">
          <div className="pt-1">
            <Avatar source={AI_Icon} />
          </div>
          <div className="text-darkPrimary w-full">
            <Markdown text={msgText} />
          </div>
        </div>
        {sources !== null && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-2 pl-8">
            {sources.map((x: Record<string, string>) => {
              return <Source title={x.title} url={x.url} />;
            })}
          </div>
        )}

        <div className="flex gap-3.5">
          {msgText && <CopyButton text={msgText} copyText="Copy text" />}

          {!hideVotes && (
            <IconButton
              icon={currentVote === 1 ? thumbsUpOn : thumbsUpOff}
              onClick={() => {
                if (currentVote !== 1)
                  vote({
                    message_index: dataIndex,
                    direction: 1,
                  });
              }}
            />
          )}
          {!hideVotes && (
            <IconButton
              icon={currentVote === -1 ? thumbsDownOn : thumbsDownOff}
              onClick={() => {
                if (currentVote !== -1)
                  vote({
                    message_index: dataIndex,
                    direction: -1,
                  });
              }}
            />
          )}
        </div>
      </div>
    );
  }
  return (
    <div
      ref={measureRef}
      data-index={dataIndex}
      className="flex flex-col gap-2.5"
    >
      {msgText && (
        <div className="flex justify-end w-full">
          <div className="bg-darkLine text-darkPrimary px-3 py-1 rounded-lg sm:max-w-[80%]">
            <Markdown text={msgText} />
          </div>
        </div>
      )}

      {(inProgressAttachedFiles.length > 0 || imageSources.length > 0) && (
        <div className="flex justify-end w-full">
          <FileGallery
            attachedFiles={
              inProgressUserMessage && inProgressAttachedFiles
                ? inProgressAttachedFiles
                : imageSources
            }
            convertFileSource={
              inProgressUserMessage && inProgressAttachedFiles ? true : false
            }
            focusable
            size="lg"
          />
        </div>
      )}

      {docSources.length > 0 && (
        <div className="flex flex-col gap-4 justify-end w-full">
          {docSources.map((file) => (
            <div className="flex justify-end w-full">
              <div className="bg-darkLine text-darkPrimary px-3 py-1 rounded-lg max-w-[80%]">
                <Markdown text={`**Attachment:** ${file}`} />
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="flex justify-end w-full">
        <div className="flex gap-3.5">
          {/* <IconButton
            icon={}
            onClick={() => {}
          /> */}
          {msgText && (
            <CopyButton
              copyTextPositionCl="top start"
              text={msgText}
              copyText="Copy text"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Message);
