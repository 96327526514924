import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { Transition } from "@headlessui/react";

export default function Modal({
  isOpen,
  onClose,
  mainContent,
  afterLeave,
  className,
}: {
  isOpen: boolean;
  onClose: () => void;
  mainContent: JSX.Element;
  afterLeave?: () => void;
  className?: string;
}) {
  return (
    <Transition appear show={isOpen} afterLeave={afterLeave}>
      <Dialog open={isOpen} onClose={onClose} className="relative">
        <Transition.Child
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogBackdrop className="fixed inset-0 bg-darkModal" />
        </Transition.Child>

        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center p-4">
            <Transition.Child
              enter="transition-all transform ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition-all transform ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel
                className={`relative transform overflow-hidden rounded bg-darkBG shadow-card backdrop-blur-lg w-full max-w-3xl border border-darkLine p-1 ${className}`}
              >
                {mainContent}
              </DialogPanel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
