import { useCallback, useEffect, useState } from "react";

import { PROD_API_URL, DEV_API_URL, useAppStore } from "../../store";

import SectionRow from "../ui/SectionRow";
import Input from "../ui/Input";
import TextArea from "../ui/TextArea";
import Separator from "../ui/Separator";
import ListRow from "../ui/ListRow";
import Button from "../ui/Button";

import {
  getExtensionLink,
  handleExternalUrlClick,
  handleMailTo,
} from "../../utils/platform";
import useAutoStart from "../../hooks/useAutoStart";
import SwitchButton from "../ui/SwitchButton";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { FilterButton } from "../ui/FilterButton";

const Settings = () => {
  const router = useRouter();
  const navigate = useNavigate();
  const {
    user,
    signOut,
    fetchUser,
    updateUser,
    deleteUserContext,
    deleteInputs,
    APIUrl,
    versionUpdateInfo,
    checkVersionUpdate,
  } = useAppStore((state) => state);

  const { status, enable, disable } = useAutoStart();
  const [isCheckingUpdate, setIsCheckingUpdate] = useState(false);

  useEffect(() => {
    fetchUser();
    handleCheckUpdate();
  }, []);

  const handleCheckUpdate = async () => {
    setIsCheckingUpdate(true);
    await checkVersionUpdate();
    setIsCheckingUpdate(false);
  };

  const versionsAreDifferent = versionUpdateInfo.currentVersion !== versionUpdateInfo.latestVersion;

  const [activeSection, setActiveSection] = useState<
    | "general"
    | "context_collection"
    | "extensions"
    | "danger_zone"
    | "chats"
    | "tasks"
    | "journal"
  >("general");

  const [isSubmittingGeneral, setIsSubmittingGeneral] = useState(false);
  const [isSubmittingDeleteContext, setIsSubmittingDeleteContext] =
    useState(false);

  const [isSubmittingChats, setIsSubmittingChats] = useState(false);

  const [isSubmittingTasks, setIsSubmittingTasks] = useState(false);

  const [isSubmittingJournal, setIsSubmittingJournal] = useState(false);

  const [inputName, setInputName] = useState(user?.name ?? "");
  const [inputEmail, setInputEmail] = useState(user?.email ?? "");

  const [inputGlobalPrompt, setInputGlobalPrompt] = useState(
    user?.global_prompt ?? ""
  );
  const [inputTodoPrompt, setInputTodoPrompt] = useState(
    user?.todo_prompt ?? ""
  );
  const [inputSummaryPrompt, setInputSummaryPrompt] = useState(
    user?.summary_prompt ?? ""
  );

  const showDevSwitch = inputEmail.endsWith("@genos.dev");

  const resetForm = useCallback(() => {
    setInputName(user?.name ?? "");
    setInputEmail(user?.email ?? "");
  }, [user]);

  return (
    <div className="sm:flex flex-row h-[560px] max-h-[80vh] overflow-auto">
      <div className="sm:w-60 px-3 pt-3 pb-1 sm:pt-8 sm:pb-0 overflow-auto">
        <h6 className="font-mono uppercase text-xs text-start text-darkSecondary mb-3">
          Settings
        </h6>

        <div className="hidden sm:flex flex-col w-full">
          <ListRow
            onClick={() => {
              if (activeSection !== "general") {
                setActiveSection("general");
                resetForm();
              }
            }}
            title="General"
            higlighted={activeSection === "general"}
          />
          <ListRow
            onClick={() => {
              if (activeSection !== "context_collection") {
                setActiveSection("context_collection");
                resetForm();
              }
            }}
            title="Context Collection"
            higlighted={activeSection === "context_collection"}
          />
          <ListRow
            onClick={() => {
              if (activeSection !== "extensions") {
                setActiveSection("extensions");
                resetForm();
              }
            }}
            title="Extensions"
            higlighted={activeSection === "extensions"}
          />
        </div>

        <div className="sm:hidden h-12 flex flex-row overflow-y-hidden overflow-x-auto items-center space-x-2">
          <FilterButton
            onClick={() => {
              if (activeSection !== "general") {
                setActiveSection("general");
                resetForm();
              }
            }}
            label="General"
            isActive={activeSection === "general"}
          />
          <FilterButton
            onClick={() => {
              if (activeSection !== "context_collection") {
                setActiveSection("context_collection");
                resetForm();
              }
            }}
            label="Context Collection"
            isActive={activeSection === "context_collection"}
          />
          <FilterButton
            onClick={() => {
              if (activeSection !== "extensions") {
                setActiveSection("extensions");
                resetForm();
              }
            }}
            label="Extensions"
            isActive={activeSection === "extensions"}
          />
        </div>

        <h6 className="font-mono uppercase text-xs text-start text-darkSecondary my-3">
          Apps
        </h6>

        <div className="hidden sm:flex flex-col w-full">
          <ListRow
            onClick={() => {
              if (activeSection !== "chats") {
                setActiveSection("chats");
                resetForm();
              }
            }}
            title="Chats"
            higlighted={activeSection === "chats"}
          />
          <ListRow
            onClick={() => {
              if (activeSection !== "tasks") {
                setActiveSection("tasks");
                resetForm();
              }
            }}
            title="Tasks"
            higlighted={activeSection === "tasks"}
          />
          <ListRow
            onClick={() => {
              if (activeSection !== "journal") {
                setActiveSection("journal");
                resetForm();
              }
            }}
            title="Journal"
            higlighted={activeSection === "journal"}
          />
        </div>

        <div className="sm:hidden h-12 flex flex-row overflow-y-hidden overflow-x-auto items-center space-x-2">
          <FilterButton
            onClick={() => {
              if (activeSection !== "chats") {
                setActiveSection("chats");
                resetForm();
              }
            }}
            label="Chats"
            isActive={activeSection === "chats"}
          />
          <FilterButton
            onClick={() => {
              if (activeSection !== "tasks") {
                setActiveSection("tasks");
                resetForm();
              }
            }}
            label="Tasks"
            isActive={activeSection === "tasks"}
          />
          <FilterButton
            onClick={() => {
              if (activeSection !== "journal") {
                setActiveSection("journal");
                resetForm();
              }
            }}
            label="Journal"
            isActive={activeSection === "journal"}
          />
        </div>

        <Separator className="my-1 sm:my-5" />
        <div className="hidden sm:flex flex-col w-full">
          <ListRow
            onClick={() => {
              if (activeSection !== "danger_zone") {
                setActiveSection("danger_zone");
                resetForm();
              }
            }}
            title="Danger Zone"
            higlighted={activeSection === "danger_zone"}
          />
        </div>
        <div className="sm:hidden h-12 flex flex-row overflow-y-hidden overflow-x-auto items-center space-x-2">
          <FilterButton
            onClick={() => {
              if (activeSection !== "danger_zone") {
                setActiveSection("danger_zone");
                resetForm();
              }
            }}
            label="Danger Zone"
            isActive={activeSection === "danger_zone"}
          />
        </div>
      </div>
      <div className="flex-1 border-t sm:border-t-0 sm:border-l border-darkLine px-3 sm:px-6 pt-3 sm:pt-8 pb-8 overflow-auto">
        {activeSection === "general" && (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setIsSubmittingGeneral(true);
              if (user) {
                await updateUser({
                  ...user,
                  name: inputName,
                  email: inputEmail,
                });
              }
              setIsSubmittingGeneral(false);
            }}
          >
            <SectionRow
              className="mb-4"
              title="Name"
              description="What Little Bird calls you"
              actionComponent={<></>}
            />
            <Input
              id="name"
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
              placeholder="Enter name"
            />
            <SectionRow
              className="mt-7 mb-4"
              title="Email"
              description="Email address you use to login"
              actionComponent={<></>}
            />
            <Input
              id="email"
              type="email"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              placeholder="Enter email"
            />
            {showDevSwitch && (
              <SectionRow
                className="mt-7 mb-4"
                title="Use Dev"
                description="Toggle to use the development API"
                actionComponent={
                  <SwitchButton
                    isOn={APIUrl === DEV_API_URL}
                    onPress={async () => {
                      const newUrl =
                        APIUrl === DEV_API_URL ? PROD_API_URL : DEV_API_URL;
                      localStorage.setItem("API_URL", newUrl);

                      await signOut();
                      window.location.pathname = "/login"; // Force reload
                    }}
                  >
                    Use Dev
                  </SwitchButton>
                }
              />
            )}
            <SectionRow
              className="mt-7 mb-4"
              title="Version"
              description={
                <div className="flex flex-col space-y-1">
                  <span>Your Version: {versionUpdateInfo.currentVersion}</span>
                  {versionUpdateInfo.latestVersion && (
                    <span className="text-darkSecondary flex items-center space-x-2">
                      <span>Latest Version: {versionUpdateInfo.latestVersion}</span>
                      {versionsAreDifferent && (
                        <>
                          <span>·</span>
                          <button
                            onClick={() => handleExternalUrlClick(versionUpdateInfo.download_url)}
                            className="text-blue-500 underline hover:text-blue-600 transition-colors"
                          >
                            Download
                          </button>
                        </>
                      )}
                    </span>
                  )}
                </div>
              }
              actionComponent={
                <Button 
                  variant="outline"
                  onPress={handleCheckUpdate}
                  isSubmitting={isCheckingUpdate}
                >
                  Check for Updates
                </Button>
              }
            />
            <div className="mt-6 w-full flex justify-end">
              <Button
                type="submit"
                disabled={inputName === user?.name && inputEmail === user.email}
                isSubmitting={isSubmittingGeneral}
              >
                Save Changes
              </Button>
            </div>
          </form>
        )}

        {activeSection === "context_collection" && (
          <>
            <SectionRow
              title={"Total Context Collected: " + user?.total_inputs}
              description={
                "Latest context collected: " +
                new Date(user?.latest_input! + "Z").toLocaleString(undefined, {
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })
              }
              actionComponent={<></>}
            />
            <Separator className="my-7" />
            {status !== null && (
              <SectionRow
                title="Start little bird on login"
                description="Start Little Bird when you turn on your computer."
                actionComponent={
                  <SwitchButton
                    onPress={async () => {
                      if (status) {
                        await disable();
                      } else {
                        await enable();
                      }
                    }}
                    isOn={status}
                  >
                    Enable
                  </SwitchButton>
                }
              />
            )}
          </>
        )}

        {activeSection === "extensions" && (
          <>
            <SectionRow
              className="mb-5"
              title={"Chromium Addon"}
              description={"Improved context collection for Chromium"}
              actionComponent={
                <Button
                  variant="outline"
                  onPress={() =>
                    handleExternalUrlClick(getExtensionLink("Chrome"))
                  }
                >
                  Download
                </Button>
              }
            />
            <SectionRow
              className="mt-7"
              title={"Firefox Addon"}
              description={"Improved context collection for Firefox"}
              actionComponent={
                <Button
                  variant="outline"
                  onPress={() =>
                    handleExternalUrlClick(getExtensionLink("Firefox"))
                  }
                >
                  Download
                </Button>
              }
            />
          </>
        )}

        {activeSection === "chats" && (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setIsSubmittingChats(true);
              if (user) {
                await updateUser({
                  ...user,
                  global_prompt: inputGlobalPrompt,
                });
              }
              setIsSubmittingChats(false);
            }}
          >
            <SectionRow
              className="mb-4"
              title="Little bird chat "
              description="Describe how you want Little Bird to chat with you."
              actionComponent={<></>}
            />
            <TextArea
              id="global_prompt"
              value={inputGlobalPrompt}
              onChange={(e) => setInputGlobalPrompt(e.target.value)}
              placeholder=""
            />
            <div className="mt-10 w-full flex justify-end">
              <Button
                type="submit"
                disabled={inputGlobalPrompt === user?.global_prompt}
                isSubmitting={isSubmittingChats}
              >
                Save Changes
              </Button>
            </div>
          </form>
        )}

        {activeSection === "tasks" && (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setIsSubmittingTasks(true);
              if (user) {
                await updateUser({
                  ...user,
                  todo_prompt: inputTodoPrompt,
                });
              }
              setIsSubmittingTasks(false);
            }}
          >
            <SectionRow
              className="mb-4"
              title="Tasks Capture"
              description="Describe what you want Little Bird to capture in tasks"
              actionComponent={<></>}
            />
            <TextArea
              id="todo_prompt"
              value={inputTodoPrompt}
              onChange={(e) => setInputTodoPrompt(e.target.value)}
              placeholder=""
            />
            <div className="mt-10 w-full flex justify-end">
              <Button
                type="submit"
                disabled={inputTodoPrompt === user?.todo_prompt}
                isSubmitting={isSubmittingTasks}
              >
                Save Changes
              </Button>
            </div>
          </form>
        )}

        {activeSection === "journal" && (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setIsSubmittingJournal(true);
              if (user) {
                await updateUser({
                  ...user,
                  summary_prompt: inputSummaryPrompt,
                });
              }
              setIsSubmittingJournal(false);
            }}
          >
            <SectionRow
              className="mb-4"
              title="journal Capture"
              description="Describe what you want Little Bird to capture in your journal."
              actionComponent={<></>}
            />
            <TextArea
              id="summary_prompt"
              value={inputSummaryPrompt}
              onChange={(e) => setInputSummaryPrompt(e.target.value)}
              placeholder=""
            />
            <div className="mt-10 w-full flex justify-end">
              <Button
                type="submit"
                disabled={inputSummaryPrompt === user?.summary_prompt}
                isSubmitting={isSubmittingJournal}
              >
                Save Changes
              </Button>
            </div>
          </form>
        )}

        {activeSection === "danger_zone" && (
          <div className="flex flex-col gap-7">
            <SectionRow
              title={"🚽 Delete last hour"}
              description={"Delete data from the last hour"}
              actionComponent={
                <Button
                  variant="outline"
                  onPress={async () => {
                    const oneHourAgo = new Date(
                      Date.now() - 60 * 60 * 1000
                    ).getTime();
                    await deleteInputs(oneHourAgo);
                  }}
                >
                  Delete
                </Button>
              }
            />
            <SectionRow
              title={"🗑 Delete last day"}
              description={"Delete data from the last 24 hours"}
              actionComponent={
                <Button
                  variant="outline"
                  onPress={() => {
                    const oneDayAgo = new Date(
                      Date.now() - 24 * 60 * 60 * 1000
                    ).getTime();
                    deleteInputs(oneDayAgo);
                  }}
                >
                  Delete
                </Button>
              }
            />
            <SectionRow
              title={"❌ Delete all data"}
              description={"Delete all historical data"}
              actionComponent={
                <Button
                  variant="outline"
                  onPress={() => {
                    deleteInputs(0);
                  }}
                >
                  Delete
                </Button>
              }
            />
            <SectionRow
              // className="mt-7"
              title="Delete context"
              description="Delete context from Little Bird"
              actionComponent={
                <Button
                  variant="outline"
                  isSubmitting={isSubmittingDeleteContext}
                  disabled={isSubmittingDeleteContext}
                  onPress={async () => {
                    try {
                      setIsSubmittingDeleteContext(true);
                      await deleteUserContext();
                    } finally {
                      setIsSubmittingDeleteContext(false);
                    }
                  }}
                >
                  Delete context
                </Button>
              }
            />
            <Separator />
            <SectionRow
              title={"Sign Out from Little Bird"}
              description={"Log out from the app"}
              actionComponent={
                <Button
                  variant="outline"
                  onPress={async () => {
                    await signOut();
                    router.invalidate().finally(() => {
                      navigate({ to: "/login", replace: true });
                    });
                  }}
                >
                  Sign Out
                </Button>
              }
            />
            <SectionRow
              title={"Delete account"}
              description={"Delete your Little Bird account and all its data."}
              actionComponent={
                <Button
                  variant="outline"
                  onPress={() => {
                    handleMailTo(
                      "support@littlebird.app",
                      "Delete Account",
                      `Please delete my account ${user?.email}`
                    );
                  }}
                >
                  Delete account
                </Button>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
