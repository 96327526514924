import { useCallback, useState } from "react";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { useAppStore } from "../../store";
import TileModal from "../ui/TileModal";
import ListRow from "../ui/ListRow";
import SectionRow from "../ui/SectionRow";
import Input from "../ui/Input";
import Button from "../ui/Button";
import { FilterButton } from "../ui/FilterButton";

export default function WorkstreamSettings() {
  const { workstreams, editWorkstream, removeWorkstream } = useAppStore();
  const [selected, setSelected] = useState(0);

  const [name, setName] = useState(workstreams[selected]?.name ?? "");
  const select = useCallback(
    (index: number) => {
      setSelected(index);
      setName(workstreams[index]?.name ?? "");
    },
    [workstreams, setName, setSelected]
  );

  return (
    <TileModal
      title="Manage categories"
      sideContent={
        <>
          <div className="hidden sm:block">
            {workstreams.map((workstream, index) => (
              <ListRow
                key={index.toString()}
                onClick={() => select(index)}
                title={workstream.name}
                higlighted={selected === index}
              />
            ))}
            <ListRow
              leftIcon={faAdd}
              onClick={() => select(-1)}
              title="New Category"
            />
          </div>
          <div className="sm:hidden h-12 flex flex-row overflow-y-hidden overflow-x-auto items-center space-x-2">
            {workstreams.map((workstream, index) => (
              <FilterButton
                key={index.toString()}
                onClick={() => select(index)}
                label={workstream.name}
                isActive={selected === index}
              />
            ))}
            <FilterButton
              // leftIcon={faAdd}
              isActive={false}
              onClick={() => select(-1)}
              label="New Category"
            />
          </div>
        </>
      }
      mainContent={
        <div className="flex flex-col h-full">
          <SectionRow
            className="mb-5"
            title="Name"
            description="What do you want to call this category?"
            actionComponent={<></>}
          />
          <Input
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter a name"
          />
          <div className="mt-8">
            <div className="flex flex-row items-center">
              <Button
                type="submit"
                variant="outline"
                disabled={!workstreams[selected]}
                // isSubmitting={isSubmittingContext}
                onPress={() => {
                  removeWorkstream(workstreams[selected].id);
                  select(0);
                }}
              >
                Delete Category
              </Button>

              <Button
                disabled={
                  name.toLowerCase() ===
                  workstreams[selected]?.name.toLowerCase()
                }
                className="ms-auto"
                onPress={() => {
                  editWorkstream({
                    ...(workstreams[selected] ?? { id: null }),
                    name,
                  });
                }}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
}
