import {
  faArrowUp,
  faMicrophone,
  faPaperclip,
  faVolume,
  faVolumeMute,
  faStop,
} from "@fortawesome/pro-regular-svg-icons";
import * as solidIcons from "@fortawesome/pro-solid-svg-icons";

import { useAppStore, VOICE_MODE_SUPPORTED } from "../../store";
import IconButton from "../ui/IconButton";
import { useEffect, useMemo, useState } from "react";

import { open } from "@tauri-apps/plugin-dialog";
import { getCurrentWindow } from "@tauri-apps/api/window";

import { useWindowSize } from "../../hooks/useWindowResize";
import { useChatInputFocus } from "../../hooks/useChatInputFocus";

import Separator from "../ui/Separator";

import MessageList from "./MessageList";
import FileGallery from "./FileGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChatTts from "./ChatTts";
import { useSpeechToText } from "../../hooks/useAudioRecording";
import { inBrowser } from "../../utils/platform";
import { platform } from "@tauri-apps/plugin-os";
// import DropdownMenu from "./DropdownMenu";
// import { Assistant } from "../dataclasses";

async function handleFileSelection(): Promise<string[]> {
  try {
    const selected = await open({
      multiple: true,
      directory: false,
    });
    return selected || [];
  } catch (error) {
    console.error("Error selecting documents", error);
    return [];
  }
}

async function handleClipboardPaste(clipboardData: DataTransfer): Promise<string[]> {
  const files: string[] = [];

  for (const item of clipboardData.items) {
    if (item.type.startsWith('image/')) {
      try {
        const blob = item.getAsFile();
        if (!blob) continue;

        const objectUrl = URL.createObjectURL(blob);
        files.push(objectUrl);
      } catch (error) {
        console.error("Error handling pasted image:", error);
      }
    }
  }
  
  return files;
}

export default function Thread() {
  const {
    focusedChat,
    partialAnswers,
    sendQuestion,
    autocomplete,
    setSuffixChat,
    attachedFiles,
    setAttachedFiles,
    addAttachedFiles,
    drawerOpen,
    setInProgressAttachedFiles,
    addInProgressAttachedFiles,
    inProgressAttachedFiles,
    useVoiceMode,
    setVoiceMode,
    isProcessingVoice,
    ttsMessages,
    setTtsMessages,
    settingsOpen,
    isStreaming,
    setStopPropagatingHotkeys,
  } = useAppStore((state) => state);

  const fetchVotes = useAppStore((state) => state.fetchVotes);
  const stopFocusedChat = useAppStore((state) => state.stopFocusedChat);

  const chatInput = useAppStore((state) => state.chatInput);
  const setChatInput = useAppStore((state) => state.setChatInput);

  const [isDragging, setIsDragging] = useState(false);
  const [chatInputInternal, setChatInputInternal] = useState(chatInput);

  const { textareaRef, focusChatInput } = useChatInputFocus();

  const { isRecording, startRecording, stopRecording } = useSpeechToText(
    (text) => {
      // request TTS for the next response and send
      // the transcribed message
      setVoiceMode(true);
      sendQuestion(text, []);
    }
  );

  // cancel TTS playback when leaving the screen
  useEffect(() => {
    return () => {
      setTtsMessages([]);
      setVoiceMode(false);
    };
  }, []);

  useEffect(() => {
    if (inBrowser()) {
      return;
    }
    const window = getCurrentWindow();

    const cleanupPromise = window.onDragDropEvent((event) => {
      if (event.payload.type === "over") {
        setIsDragging(true);
      } else if (event.payload.type === "drop") {
        const paths = event.payload.paths;
        if (paths.length > 0) {
          addAttachedFiles(paths);
          addInProgressAttachedFiles(paths);
        }
        setIsDragging(false);
      } else if (event.payload.type === "leave") {
        setIsDragging(false);
      }
    });

    return () => {
      cleanupPromise.then((unlisten) => unlisten());
    };
  }, []);

  const partialAnswer = useMemo(() => {
    return focusedChat ? partialAnswers[focusedChat.chat_id] : null;
  }, [partialAnswers, focusedChat]);

  const windowSize = useWindowSize();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [chatInputInternal, windowSize, drawerOpen]);

  useEffect(() => {
    fetchVotes();
  }, [focusedChat]);

  useEffect(() => {
    focusChatInput();
  }, [settingsOpen, focusedChat, focusChatInput]);

  return (
    <>
      {ttsMessages[0] && useVoiceMode && (
        <div className="fixed top-4 right-4">
          <div
            onClick={() => {
              setTtsMessages([]);
              setVoiceMode(false);
            }}
            className="cursor-pointer group w-10 h-10 flex items-center justify-center bg-darkBG hover:bg-cta-gradient rounded border border-darkLine transition-colors duration-200"
          >
            <FontAwesomeIcon
              icon={faVolume}
              className="block group-hover:hidden"
            />
            <FontAwesomeIcon
              icon={faVolumeMute}
              className="hidden group-hover:block"
            />
          </div>
        </div>
      )}
      <ChatTts />
      {/* Messages Container */}
      {/* play carefully with layout can get broken easily */}
      <MessageList />
      <Separator />
      <div className="flex flex-col w-full max-w-[640px] mx-auto self-center text-darkPrimary pt-3 pb-5 px-4">
        {autocomplete && autocomplete.length > 0 && (
          <div className="flex flex-wrap gap-4 pb-4">
            {autocomplete.map((suggestion, index) => (
              <button
                className="text-start border border-darkLine px-3 py-2 rounded-md"
                key={index}
                onClick={() => {
                  setChatInput(suggestion);
                  setSuffixChat("");
                }}
              >
                {suggestion}
              </button>
            ))}
          </div>
        )}

        <div
          className="flex flex-col gap-3 bg-darkField border border-darkLine rounded py-3 px-3 cursor-[text] focus-within:border-darkActive"
          onClick={() => textareaRef.current?.focus()}
        >
          {(attachedFiles.length > 0 || isDragging) && (
            <FileGallery
              attachedFiles={attachedFiles}
              convertFileSource
              removeFile={(file) => {
                setAttachedFiles(attachedFiles.filter((f) => f !== file));
                setInProgressAttachedFiles(
                  inProgressAttachedFiles.filter((f) => f !== file)
                );
              }}
            />
          )}

          <textarea
            placeholder="Ask Little Bird"
            id={"chat-input"}
            className="text-sm appearance-none w-full disabled:opacity-60 text-darkPrimary focus:outline-none bg-transparent box-border resize-none overflow-hidden m-0 p-0 caret-blue"
            rows={1}
            ref={textareaRef}
            value={chatInputInternal}
            disabled={isProcessingVoice || isStreaming}
            onChange={(e) => setChatInputInternal(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                const os = !inBrowser() && platform();
                const onMobile = os === "android" || os === "ios";

                if (
                  e.metaKey ||
                  e.shiftKey ||
                  (onMobile && e.key === "Enter")
                ) {
                  setChatInputInternal(chatInputInternal + "\n");
                  return;
                }
                if (
                  partialAnswer?.isThinking ||
                  (chatInputInternal.replace(/[\s\n\r]/g, "") === "" && attachedFiles.length === 0) ||
                  isStreaming
                ) {
                  return;
                }
                setChatInput(chatInputInternal);
                sendQuestion(chatInputInternal, attachedFiles);
                setChatInputInternal("");
              }
            }}
            onFocus={() => setStopPropagatingHotkeys(true)}
            onBlur={() => setStopPropagatingHotkeys(false)}
            onPaste={async (e) => {
              const hasImageData = Array.from(e.clipboardData.items).some(item => 
                item.type.startsWith('image/')
              );
              
              if (hasImageData) {
                e.preventDefault();
                const files = await handleClipboardPaste(e.clipboardData);
                if (files.length > 0) {
                  addAttachedFiles(files);
                  addInProgressAttachedFiles(files);
                }
              }
            }}
          />

          <div className="flex flex-row items-center">
            <div className="flex flex-1 flex-row gap-4">
              {/* <DropdownMenu
                  buttonText={assistant}
                  options={[Assistant.claude, Assistant.gpt]}
                  setOption={(option) => setAssistant(option as Assistant)}
                /> */}
              {!inBrowser() && (
                <IconButton
                  className="mr-auto"
                  icon={faPaperclip}
                  onClick={async () => {
                    const files = await handleFileSelection();
                    if (files.length > 0) {
                      addAttachedFiles(files);
                      addInProgressAttachedFiles(files);
                    }
                  }}
                />
              )}
            </div>
            <div className="flex items-center gap-3">
              {VOICE_MODE_SUPPORTED() && (
                <IconButton
                  icon={isRecording ? solidIcons.faMicrophone : faMicrophone}
                  onClick={() =>
                    isRecording ? stopRecording() : startRecording()
                  }
                  disabled={false}
                  className={`flex items-center justify-center w-7 h-7 rounded ${
                    isRecording ? "bg-darkRed" : ""
                  }`}
                  size={12}
                />
              )}
              {!isStreaming ? (
                <IconButton
                  className="bg-cta-gradient flex items-center justify-center w-7 h-7 rounded"
                  size={10}
                  icon={faArrowUp}
                  onClick={() => {
                    setChatInput(chatInputInternal);
                    sendQuestion(chatInputInternal, attachedFiles);
                    setChatInputInternal("");
                  }}
                  disabled={
                    partialAnswer?.isThinking ||
                    (chatInputInternal.replace(/[\s\n\r]/g, "") === "" && attachedFiles.length === 0) ||
                    isStreaming
                  }
                />
              ) : (
                <IconButton
                  className="bg-darkRed flex items-center justify-center w-7 h-7 rounded"
                  size={10}
                  icon={faStop}
                  onClick={() => {
                    stopFocusedChat();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
