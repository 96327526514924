import { faMicrophone } from "@fortawesome/pro-regular-svg-icons";
import * as solidIcons from "@fortawesome/pro-solid-svg-icons";
import { useAppStore } from "../store";
import IconButton from "../components/ui/IconButton";
import ChatTts from "../components/blocks/ChatTts";
import { useSpeechToText } from "../hooks/useAudioRecording";

export const SpotLightScreen = () => {
  const { sendQuestion, setVoiceMode, isProcessingVoice } = useAppStore();

  const { isRecording, startRecording, stopRecording } = useSpeechToText(
    (text) => {
      // request TTS for the next response and send
      // the transcribed message
      setVoiceMode(true);
      sendQuestion(text, []);
    }
  );

  return (
    <div className="w-screen h-screen flex-col p-4">
      <ChatTts />
      <div className="flex flex-row items-center space-x-2">
        <IconButton
          disabled={!isRecording && isProcessingVoice}
          onClick={() => (isRecording ? stopRecording() : startRecording())}
          icon={isRecording ? solidIcons.faMicrophone : faMicrophone}
        />
        {isProcessingVoice && (
          <div className="flex space-x-1">
            <div className="h-1 w-1 bg-darkPrimary rounded-full animate-bounce [animation-delay:-0.3s]" />
            <div className="h-1 w-1 bg-darkPrimary rounded-full animate-bounce [animation-delay:-0.15s]" />
            <div className="h-1 w-1 bg-darkPrimary rounded-full animate-bounce" />
          </div>
        )}
      </div>
    </div>
  );
};
