import { create } from "zustand";
import { createJSONStorage, persist, StateStorage } from "zustand/middleware";
import { platform } from "@tauri-apps/plugin-os";

import {
  Assistant,
  Chat,
  ChatMode,
  Source,
  Summary,
  Todo,
  TodoCreate,
  User,
  VoteData,
  VoteDataDirection,
  Workstream,
} from "./types/dataclasses";
import {
  create_new_chat,
  create_todo,
  delete_user_context,
  delete_inputs,
  edit_workstream,
  get_autocomplete,
  get_chat_history,
  get_chat_reply,
  get_chat_votes,
  get_daily_summaries,
  get_sources,
  get_todos,
  get_user_data,
  get_workstreams,
  poll_chat_with_context,
  post_vote,
  remove_workstream,
  speech_to_text,
  stop_chat_with_context,
  text_to_speech,
  update_todo,
  update_user_data,
  version_info,
  get_shared_chat,
  delete_chat,
  get_recommended_questions,
} from "./utils/api";
import { invoke } from "@tauri-apps/api/core";
import { LazyStore } from "@tauri-apps/plugin-store";
import posthog from "posthog-js";
import { emit } from "@tauri-apps/api/event";
import { inBrowser } from "./utils/platform";
import { differenceInHours } from "date-fns";

export const LOCAL_API_URL = "http://localhost:5274";
export const DEV_API_URL = "https://dev.lilbird.co";
export const PROD_API_URL = "https://lilbird.co";

// DO NOT MERGE DEV TO MAIN AS CI CD IS AUTO DEPLOYING TO S3 NOW!!!
// export const STORAGE_NAME = "littlebrdStore.devel.bin"; // import.meta.env.CONFIG_STORAGE_NAME;
export const STORAGE_NAME = "littlebrdStore.prod.bin";

// DO NOT MERGE LOCALHOST TO MAIN AS CI CD IS AUTO DEPLOYING TO S3 NOW!!!
// export const API_URL = "http://localhost:5274"; // import.meta.env.CONFIG_API_URL;
export const API_URL =
  import.meta.env.VITE_API_URL ||
  localStorage.getItem("API_URL") ||
  PROD_API_URL;

export const NEW_CHAT_DEFAULT_TITLE = "New Chat";
export const DEFAULT_WORKSTREAM_FILTER = "";

const FIRST_CHAT_QUESTION_RECOMMENDATIONS = [
  "What have I been doing in the last few minutes? ",
  "What do I find important? ",
  "What promise does Little Bird want to fulfil?",
];
const RECOMMENDED_QUESTIONS_FETCH_INTERVAL = 3; //in hours

// used to disable voice mode in platforms that don't support it (e.g. Android)
let voiceModeSupported = true;
invoke("voice_mode_supported").then((enabled) => {
  console.warn("VOICE MODE SUPPORTED " + enabled);
  voiceModeSupported = enabled as boolean;
});
export const VOICE_MODE_SUPPORTED = () => voiceModeSupported;

export enum ROUTES {
  CHAT = "CHAT",
  RECENT_CHATS = "RECENT_CHATS",
  TASKS = "TASKS",
  JOURNAL = "JOURNAL",
}

let store: LazyStore | null = null;

if (!inBrowser()) {
  store = new LazyStore(STORAGE_NAME, { autoSave: true });
}

export const storage: StateStorage =
  store === null
    ? localStorage
    : {
        getItem: async (name: string): Promise<string | null> => {
          return (await store?.get(name)) || null;
        },
        setItem: async (name: string, value: string): Promise<void> => {
          await store?.set(name, value);
        },
        removeItem: async (name: string): Promise<void> => {
          await store?.delete(name);
        },
      };
function errorToast(message: any): {
  title: string;
  description: string;
  kind: "error";
} {
  return {
    title: "Error",
    description: message instanceof Error ? message.message : message,
    kind: "error",
  };
}

export enum ONBOARDINGSTEP {
  VIDEO = "VIDEO",
  PERMISSIONS = "PERMISSIONS",
  THEME = "THEME",
  COMPLETE = "COMPLETE",
}

interface AppStore {
  // Store related
  assistant: Assistant;
  setAssistant: (assistant: Assistant) => void;
  chatMode: ChatMode;
  setChatMode: (mode: ChatMode) => void;
  _hasHydrated: boolean;
  setHasHydrated: (hasHydrated: boolean) => void;
  // App / UI States
  modalContent: JSX.Element | null;
  setModalContent: (content: JSX.Element | null) => void;
  toastData: {
    title: string;
    description: string;
    kind: "info" | "error";
  } | null;
  setToastData: (
    data: {
      title: string;
      description: string;
      kind: "info" | "error";
    } | null
  ) => void;
  drawerOpen: boolean;
  setDrawerOpen: (state: boolean) => void;
  signOut: () => Promise<void>;
  // Dev / Settings
  APIUrl: string;
  setAPIUrl: (url: string) => void;
  serverAllowedLastUpdated: Date;
  serverAllowed: boolean;
  setServerAllowed: (allowed: boolean) => void;
  hasAccessibilityPermissions: boolean;
  setHasAccessibilityPermissions: (state: boolean) => void;
  // Todo UI
  workstreamFilter: string;
  setWorkstreamFilter: (filter: string) => void;
  setActiveTodoId: (id: number | null) => void;
  activeTodoId: number | null;
  // Summary/History UI
  focusedSummary: Summary | null;
  setFocusedSummary: (summary: Summary | null) => void;
  // Chat UI
  focusedChat: Chat | null;
  setFocusedChat: (chat: Chat | null) => void;
  stopFocusedChat: () => void;
  votes: {
    [chat_id: number]: {
      message_index: number;
      direction: VoteDataDirection;
    }[];
  };
  fetchVotes: () => Promise<void>;
  vote: (vote: VoteData) => Promise<void>;
  currentRoute: ROUTES;
  setCurrentRoute: (screen: ROUTES) => void;
  speechToText: (audio: Blob) => Promise<string | null>;
  textToSpeech: (text: string) => Promise<string | null>;
  useVoiceMode: boolean;
  setVoiceMode: (enabled: boolean) => void;
  isProcessingVoice: boolean;
  setProcessingVoice: (processing: boolean) => void;
  ttsMessages: { text: string; isLast: boolean }[];
  setTtsMessages: (messages: { text: string; isLast: boolean }[]) => void;
  // Auth
  onboardingStep: ONBOARDINGSTEP;
  setOnboardingStep: (step: ONBOARDINGSTEP) => void;
  token: string | null;
  email: string | null;
  user: User | null;
  setEmail: (email: string | null) => void;
  setToken: (token: string | null) => void;
  setUser: (user: User | null) => void;
  fetchUser: () => Promise<void>;
  updateUser: (
    user: User
  ) => Promise<{ error?: string | undefined; res?: string | undefined }>;
  deleteUserContext: () => Promise<void>;
  // Todos
  todos: Todo[];
  workstreams: Workstream[]; // This can be just a selector, no need to have an own entry
  fetchTodos: () => Promise<void>;
  createTodo: (todo: TodoCreate) => Promise<void>;
  updateTodo: (todo: Todo) => void;
  sources: {
    [id: number]: Source[];
  };
  getSource: (id: number) => void;
  // Workstreams
  removeWorkstream: (id: number) => Promise<void>;
  editWorkstream: (workstream: Workstream) => Promise<void>;
  workstreamSettingsOpen: boolean;
  setWorkstreamSettingsOpen: (open: boolean) => void;
  // Summary
  summaries: Summary[];
  fetchSummaries: () => Promise<void>;
  // Chat
  chats: Chat[];
  fetchChats: () => Promise<void>;
  fetchSharedChat: (chatId: string, chatPublicToken: string) => Promise<void>;
  requestNewChat: () => Promise<void>;
  partialAnswers: {
    [chat_id: number]: {
      isThinking: boolean;
      partialAnswer: string;
    };
  };
  pollPartialAnswer: (chatId: number) => Promise<void>;
  sendQuestion: (text: string, attachedFiles: string[]) => Promise<void>;
  suffixChat: string;
  setSuffixChat: (text: string) => void;
  chatInput: string;
  setChatInput: (text: string) => void;
  autocompleteLastUpdated: number | null;
  lastAutocompleteQuery: string | null;
  autocomplete: string[] | null;
  recommendedQuestionLastUpdated: string | null;
  firstChatRecommendedQuestion: string[];
  fetchRecommendedQuestions: () => Promise<void>;
  resetChatInput: () => void;
  lastInputReset: number | null;
  lightMode: boolean;
  toggleLightMode: () => void;
  settingsOpen: boolean;
  setSettingsOpen: (state: boolean) => void;
  attachedFiles: string[];
  setAttachedFiles: (files: string[]) => void;
  addAttachedFiles: (files: string[]) => void;
  inProgressAttachedFiles: string[];
  setInProgressAttachedFiles: (files: string[]) => void;
  addInProgressAttachedFiles: (files: string[]) => void;
  // Context Collector
  contextCollectorWorking: boolean;
  setContextCollectorWorking: (state: boolean) => void;
  ongoingContextCollection: boolean;
  setOngoingContextCollection: (state: boolean) => void;
  startContextCollection: () => Promise<void>;
  stopContextCollection: () => Promise<void>;
  // Context Collector watchdog
  wasStoppedByUser: boolean;
  setWasStoppedByUser: (stopped: boolean) => void;
  watchdogInterval: number | undefined;
  initializeWatchdog: () => void;
  checkVersionUpdate: () => Promise<void>;
  versionUpdateInfo: {
    needs_update: boolean;
    download_url: string;
    currentVersion: string;
    latestVersion: string;
  };
  cancelVersionUpdate: boolean;
  setCancelVersionUpdate: (state: boolean) => void;
  deleteInputs: (timestamp: number) => Promise<boolean>;
  cmdModalOpen: boolean;
  setCmdModalOpen: (show: boolean) => void;
  isStreaming: boolean;
  setIsStreaming: (streaming: boolean) => void;
  shareModalOpen: boolean;
  setShareModalOpen: (open: boolean) => void;
  deleteChat: (chatId: number) => void;
  stopPropagatingHotkeys: boolean;
  setStopPropagatingHotkeys: (stop: boolean) => void;
}

const initialState = {
  assistant: Assistant.claude,
  chatMode: ChatMode.smart,
  _hasHydrated: false,
  cmdModalOpen: false,
  drawerOpen: true,
  serverAllowed: true,
  serverAllowedLastUpdated: new Date(),
  hasAccessibilityPermissions: false,
  APIUrl: API_URL,
  currentRoute: ROUTES.CHAT,
  workstreamFilter: DEFAULT_WORKSTREAM_FILTER,
  activeTodoId: null,
  focusedSummary: null,
  focusedChat: null,
  modalContent: null,
  toastData: null,
  onboardingStep: ONBOARDINGSTEP.COMPLETE,
  token: null,
  email: null,
  user: null,
  todos: [],
  workstreams: [],
  sources: {},
  summaries: [],
  chats: [],
  votes: [],
  partialAnswers: {},
  suffixChat: "",
  chatInput: "",
  autocompleteLastUpdated: null,
  lastAutocompleteQuery: null,
  autocomplete: null,
  recommendedQuestionLastUpdated: null,
  firstChatRecommendedQuestion: FIRST_CHAT_QUESTION_RECOMMENDATIONS,
  lastInputReset: null,
  attachedFiles: [],
  inProgressAttachedFiles: [],
  lightMode: document.documentElement.classList.contains("dark") ? false : true,
  settingsOpen: false,
  contextCollectorWorking: false,
  wasStoppedByUser: false,
  watchdogInterval: undefined,
  ongoingContextCollection: false,
  workstreamSettingsOpen: false,
  ttsMessages: [],
  useVoiceMode: false,
  isProcessingVoice: false,
  isStreaming: false,
  shareModalOpen: false,
  stopPropagatingHotkeys: false,
};

// enum EmitEvent {
//   "SET_SERVER_ALLOWED" = "SET_SERVER_ALLOWED",
// }

export const useAppStore = create<AppStore>()(
  persist(
    (set, get) => {
      return {
        ...initialState,
        setHasHydrated: (state) => {
          set({
            _hasHydrated: state,
          });
        },
        setCmdModalOpen: (state: boolean) => set({ cmdModalOpen: state }),
        setDrawerOpen: (state: boolean) => {
          set({ drawerOpen: state });
        },
        setAssistant: (assistant: Assistant) => {
          set({ assistant });
        },
        setChatMode: (mode: ChatMode) => {
          set({ chatMode: mode });
        },
        setAPIUrl: (url: string) => set({ APIUrl: url }),
        setCurrentRoute: (screen: ROUTES) => set({ currentRoute: screen }),
        setServerAllowed: (allowed: boolean) => {
          set({
            serverAllowed: allowed,
            serverAllowedLastUpdated: new Date(),
          });
        },
        setHasAccessibilityPermissions(state: boolean) {
          set({ hasAccessibilityPermissions: state });
        },
        setWorkstreamFilter: (filter) => set({ workstreamFilter: filter }),
        setActiveTodoId: (id: number | null) => {
          set({ activeTodoId: id });
        },
        setFocusedSummary: (summary: Summary | null) =>
          set({ focusedSummary: summary }),
        setFocusedChat: (chat: Chat | null) => set({ focusedChat: chat }),
        stopFocusedChat: () => {
          const { focusedChat, token, APIUrl } = get();
          const chatId = focusedChat?.chat_id;

          if (chatId == null || focusedChat == null) {
            return;
          }

          const interruptMessage = ["text:<Manual interrupt by user>"];
          const updatedChatHistory = [
            ...focusedChat.chat_history,
            interruptMessage,
          ];

          set({
            isStreaming: false,
            focusedChat: {
              ...focusedChat,
              chat_history: updatedChatHistory,
            },
            chats: get().chats.map((chat) =>
              chat.chat_id === chatId
                ? { ...chat, chat_history: updatedChatHistory }
                : chat
            ),
            partialAnswers: {
              ...get().partialAnswers,
              [chatId]: {
                partialAnswer: "",
                isThinking: false,
              },
            },
          });

          stop_chat_with_context(chatId, token!, APIUrl).catch((error) => {
            console.error("Error stopping chat:", error);
            set({
              toastData: errorToast(error),
              isStreaming: false,
            });
          });
        },

        setModalContent: (content: JSX.Element | null) =>
          set({ modalContent: content }),
        setToastData: (toastData) => set({ toastData }),
        signOut: async () => {
          if (!inBrowser()) {
            await emit("update-state", { APIUrl: null, token: null });
          }
          set({
            ...initialState,
            _hasHydrated: true, // Store is already hydrated, no need to set this
          });
          posthog.reset(); // ON LOGOUT RESET USER
        },
        setOnboardingStep: (step: ONBOARDINGSTEP) => {
          set({ onboardingStep: step });
        },
        setEmail: (email: string | null) => set({ email }),
        setToken: (token: string | null) => set({ token }),
        setUser: (user: User | null) => set({ user }),
        fetchUser: async () => {
          const token = get().token;
          if (token) {
            try {
              const user = await get_user_data(token, get().APIUrl);
              set({ user });
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        updateUser: async (user: User) => {
          const token = get().token;
          if (token) {
            try {
              await update_user_data(token, user, get().APIUrl);
            } catch (error) {
              console.error("Error updating user data:", error);
              set({ toastData: errorToast(error) });
              return { error: String(error) };
            }
            get().fetchUser();
            return { res: "ok" };
          }
          return { error: "token doesnt exist" };
        },
        deleteUserContext: async () => {
          const token = get().token;
          if (token) {
            try {
              await delete_user_context(token, get().APIUrl);
              await Promise.all([
                get().fetchChats(),
                get().fetchSummaries(),
                set({ focusedChat: null, focusedSummary: null }),
              ]);
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        fetchTodos: async () => {
          const token = get().token;
          if (token) {
            try {
              const todos = await get_todos(token, get().APIUrl);
              const workstreams = await get_workstreams(token, get().APIUrl);
              set({
                todos: todos.sort(
                  (
                    a: { date: string | number | Date },
                    b: { date: string | number | Date }
                  ) => {
                    return (
                      new Date(b.date).getTime() - new Date(a.date).getTime()
                    );
                  }
                ),
                workstreams: workstreams,
              });
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        removeWorkstream: async (id: number) => {
          const token = get().token;
          if (token) {
            try {
              await remove_workstream(id, token, get().APIUrl);
              await get().fetchTodos();
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        editWorkstream: async (workstream: Workstream) => {
          const token = get().token;
          if (token) {
            try {
              await edit_workstream(
                token,
                get().APIUrl,
                workstream.id,
                workstream.name,
                workstream.capture ?? undefined,
                workstream.ignore ?? undefined
              );
              await get().fetchTodos();
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        setWorkstreamSettingsOpen: (open: boolean) => {
          set({ workstreamSettingsOpen: open });
        },
        createTodo: async (todo: TodoCreate) => {
          const token = get().token;
          if (token) {
            try {
              await create_todo(todo, token, get().APIUrl);
              await get().fetchTodos();
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        updateTodo: async (updatedTodo: Todo) => {
          const token = get().token;
          if (token) {
            const currentTodos = get().todos;
            set({
              todos: currentTodos.map((todo) => {
                if (todo.id === updatedTodo.id) {
                  return { ...updatedTodo };
                }
                return todo;
              }),
            });
            try {
              await update_todo(updatedTodo, token, get().APIUrl);
              get().fetchTodos();
            } catch (e) {
              set({ toastData: errorToast(e), todos: currentTodos });
            }
          }
        },
        getSource: async (id: number) => {
          const token = get().token;
          if (token && id) {
            try {
              const sources = await get_sources(id, token, get().APIUrl);
              set({
                sources: {
                  ...get().sources,
                  [id]: sources,
                },
              });
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        fetchSummaries: async () => {
          const token = get().token;
          if (token) {
            try {
              const summaries = await get_daily_summaries(token, get().APIUrl);
              set({
                summaries: summaries.sort(
                  (
                    a: { date: string | number | Date },
                    b: { date: string | number | Date }
                  ) => {
                    return (
                      new Date(b.date).getTime() - new Date(a.date).getTime()
                    );
                  }
                ),
              });
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        pollPartialAnswer: async (chatId: number) => {
          const token = get().token;
          if (token) {
            try {
              const answer = await poll_chat_with_context(
                chatId,
                token,
                get().APIUrl
              );
              set({
                partialAnswers: {
                  ...get().partialAnswers,
                  [chatId]: {
                    ...get().partialAnswers[chatId],
                    partialAnswer: answer,
                  },
                },
              });
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        fetchChats: async () => {
          const token = get().token;
          if (token) {
            try {
              const chats = await get_chat_history(token, get().APIUrl);
              const focusedChat = get().focusedChat;
              const updatedFocusedChat =
                focusedChat &&
                chats.find((chat) => chat.chat_id === focusedChat.chat_id);
              try {
                set({
                  chats,
                });
                if (
                  updatedFocusedChat &&
                  updatedFocusedChat.title !== focusedChat.title
                ) {
                  set({
                    focusedChat: {
                      ...focusedChat,
                      title: updatedFocusedChat.title,
                    },
                  });
                }
              } catch (error) {
                console.error("Error fetching chats:", error);
              }
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        fetchSharedChat: async (chatId: string, chatPublicToken: string) => {
          try {
            const chat = await get_shared_chat(
              chatId,
              chatPublicToken,
              get().APIUrl
            );
            set({ focusedChat: chat });
          } catch (e) {
            set({ toastData: errorToast(e) });
            return Promise.reject(e);
          }
        },
        fetchVotes: async () => {
          const token = get().token;
          const chat_id = get().focusedChat?.chat_id;
          if (token && chat_id) {
            try {
              const votes = await get_chat_votes(chat_id, token, get().APIUrl);
              set({
                votes: {
                  ...get().votes,
                  [chat_id]: votes,
                },
              });
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        vote: async (vote: VoteData) => {
          const token = get().token;
          const chat_id = get().focusedChat?.chat_id;
          if (token && chat_id) {
            try {
              await post_vote(
                chat_id,
                vote.message_index,
                vote.direction,
                token,
                get().APIUrl
              );
              get().fetchVotes();
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        deleteChat: async (chatId: number) => {
          const token = get().token;
          if (token) {
            try {
              await delete_chat(chatId, token, get().APIUrl);
              if (get().focusedChat?.chat_id === chatId) {
                set({
                  focusedChat: null,
                });
              }
              get().fetchChats();
            } catch (e) {
              set({ toastData: errorToast(e) });
            }
          }
        },
        requestNewChat: async () => {
          get().resetChatInput();
          set({
            focusedChat: null,
            inProgressAttachedFiles: [],
          });
        },
        sendQuestion: async (text: string, attachedFiles: string[]) => {
          let chatObj: Chat | null = null;

          get().resetChatInput();
          const token = get().token;

          if (token) {
            if (!get().focusedChat) {
              try {
                const id = await create_new_chat(token, get().APIUrl);
                chatObj = {
                  title: NEW_CHAT_DEFAULT_TITLE,
                  chat_id: id,
                  chat_history: [[`text:${text}`]],
                  date: "string",
                };
              } catch (e) {
                set({ toastData: errorToast(e) });
                return;
              }
            } else {
              chatObj = {
                title: get().focusedChat!.title,
                chat_id: get().focusedChat!.chat_id,
                date: get().focusedChat!.date,
                chat_history: [
                  ...get().focusedChat!.chat_history,
                  [`text:${text}`],
                ],
              };
            }

            if (
              chatObj.chat_id in get().partialAnswers &&
              get().partialAnswers[chatObj.chat_id].isThinking
            ) {
              return;
            }
            posthog.capture("user_chat_event", {
              actionName: "Message_Sent",
              chatThread: chatObj.title,
            });
            set({
              focusedChat: chatObj,
              chats: [
                ...get().chats.map((chat) => {
                  if (chat.chat_id === chatObj!.chat_id) {
                    return {
                      ...chat,
                      chat_history: [...chat.chat_history, [`text:${text}`]],
                    };
                  }
                  return chat;
                }),
              ] as Chat[],
              partialAnswers: {
                ...get().partialAnswers,
                [chatObj.chat_id]: {
                  isThinking: true,
                  partialAnswer: "",
                },
              },
            });

            try {
              set({ isStreaming: true });

              let streamedMessage = "";
              let finalHistory: string[][] = [];

              await get_chat_reply(
                text,
                chatObj.chat_id,
                // get().assistant, // TODO: When enabled pass this
                Assistant.claude,
                get().chatMode,
                token,
                get().APIUrl,
                attachedFiles,
                (response: any) => {
                  streamedMessage += response.content;
                  finalHistory = response!.history;

                  set({
                    partialAnswers: {
                      ...get().partialAnswers,
                      [chatObj!.chat_id]: {
                        isThinking: false,
                        partialAnswer: streamedMessage,
                      },
                    },
                  });
                }
              );

              set({
                focusedChat: {
                  title: chatObj.title,
                  chat_id: chatObj.chat_id,
                  date: chatObj.date,
                  chat_history: finalHistory,
                },
                partialAnswers: {
                  ...get().partialAnswers,
                  [chatObj.chat_id]: {
                    partialAnswer: "",
                    isThinking: false,
                  },
                },
              });

              get().fetchChats();
            } catch (error) {
              set({
                toastData: {
                  title: "Failed to send message",
                  description: JSON.stringify((error as any).message),
                  kind: "error",
                },
              });
              set({
                partialAnswers: {
                  ...get().partialAnswers,
                  [chatObj.chat_id]: {
                    isThinking: false,
                    partialAnswer: "",
                  },
                },
              });
              get().fetchChats();
            } finally {
              set({
                partialAnswers: {
                  ...get().partialAnswers,
                  [chatObj.chat_id]: {
                    isThinking: false,
                    partialAnswer: "",
                  },
                },
                inProgressAttachedFiles: [],
              });
              get().fetchChats();
              set({ isStreaming: false });
            }
          }
        },
        setSuffixChat: (suffix: string) => set({ suffixChat: suffix }),
        setChatInput: async (text: string) => {
          let startedAutocompRequest = Date.now();
          set({ chatInput: text });
          if (get().suffixChat) {
            return;
          }
          if (text.length < 5 && text.length !== 0) {
            return;
          }
          if (text.length === 0) {
            // sort of handles box clear event
            set({
              autocomplete: null,
              autocompleteLastUpdated: null,
              lastAutocompleteQuery: null,
            });
            return;
          }
          let autocompleteLastUpdated = get().autocompleteLastUpdated;
          if (autocompleteLastUpdated) {
            if (Date.now() - autocompleteLastUpdated < 1000 * 4) {
              return;
            }
          }
          const lastAutocompleteQuery = get().lastAutocompleteQuery;
          if (lastAutocompleteQuery) {
            if (text.length === lastAutocompleteQuery.length) {
              return;
            }
          }
          set({
            autocompleteLastUpdated: Date.now(),
          });
          try {
            const token = get().token;
            if (token) {
              const autocompletes = await get_autocomplete(
                text,
                get().focusedChat?.chat_id || 0,
                token,
                get().APIUrl
              );
              const lir = get().lastInputReset;
              if (lir !== null) {
                if (lir > startedAutocompRequest) {
                  return;
                }
              }
              set({
                autocomplete: autocompletes,
                autocompleteLastUpdated: Date.now(),
                lastAutocompleteQuery: text,
              });
            }
          } catch (error) {}
        },
        resetChatInput: () => {
          set({
            suffixChat: "",
            chatInput: "",
            autocomplete: null,
            autocompleteLastUpdated: null,
            lastAutocompleteQuery: null,
            lastInputReset: Date.now(),
            attachedFiles: [],
          });
        },
        fetchRecommendedQuestions: async () => {
          const recommendedQuestionLastUpdatedAt =
            get().recommendedQuestionLastUpdated;
          if (
            recommendedQuestionLastUpdatedAt &&
            differenceInHours(
              new Date(),
              new Date(String(recommendedQuestionLastUpdatedAt))
            ) < RECOMMENDED_QUESTIONS_FETCH_INTERVAL
          ) {
            return;
          }
          try {
            const recommendedQuestions = await get_recommended_questions(
              get().token!,
              get().APIUrl
            );
            set({
              firstChatRecommendedQuestion: recommendedQuestions,
              recommendedQuestionLastUpdated: new Date().toISOString(),
            });
          } catch (error) {
            set({ toastData: errorToast(error) });
          }
        },
        speechToText: async (audio) => {
          const { token, APIUrl } = get();
          if (!token) return null;

          return speech_to_text(token, APIUrl, audio);
        },
        textToSpeech: async (text) => {
          const { token, APIUrl } = get();
          if (!token) return null;

          return text_to_speech(token, APIUrl, text);
        },
        setVoiceMode: (enabled) => {
          set({ useVoiceMode: enabled });
        },
        setProcessingVoice: (processing) => {
          set({ isProcessingVoice: processing });
        },
        setTtsMessages: (messages) => {
          set({ ttsMessages: messages });
        },
        toggleLightMode: () => {
          const htmlElement = document.documentElement;
          htmlElement.classList.toggle("dark");
          set({
            lightMode: htmlElement.classList.contains("dark") ? false : true,
          });
        },
        setSettingsOpen: (state: boolean) => {
          set({ settingsOpen: state });
        },
        setContextCollectorWorking: (state: boolean) => {
          set({ contextCollectorWorking: state });
        },
        setOngoingContextCollection: (state: boolean) => {
          set({ ongoingContextCollection: state });
        },
        setWasStoppedByUser: (stopped: boolean) =>
          set({ wasStoppedByUser: stopped }),
        initializeWatchdog: () => {
          if (inBrowser()) {
            return;
          }
          // Clear any existing interval first
          if (get().watchdogInterval !== undefined) {
            clearInterval(get().watchdogInterval);
          }
          // Start new watchdog interval
          const interval = window.setInterval(() => {
            const {
              contextCollectorWorking,
              wasStoppedByUser,
              startContextCollection,
            } = get();
            if (!contextCollectorWorking && !wasStoppedByUser) {
              console.error(
                "Context collector not running, but not stopped by user, watchdog attempting to start collector"
              );
              startContextCollection();
            }
          }, 20000);
          set({ watchdogInterval: interval });
        },
        startContextCollection: async () => {
          const token = get().token;
          const API_URL = get().APIUrl;
          if (token) {
            set({
              wasStoppedByUser: false,
              serverAllowed: true,
              serverAllowedLastUpdated: new Date(),
            }); // Reset the flag when starting collection
            if (inBrowser()) {
              return;
            }
            return await invoke("start_context_collection", {
              token: token || "",
              url: API_URL + "/add/inputs/v2",
              email: get().user?.email || "",
            });
          } else {
            console.error("startContextCollection called with no token");
          }
        },
        stopContextCollection: async () => {
          set({
            wasStoppedByUser: true,
            serverAllowed: false,
            serverAllowedLastUpdated: new Date(),
          });
          if (inBrowser()) {
            return;
          }
          return await invoke("stop_context_collection");
        },
        setAttachedFiles: (files) => {
          set({ attachedFiles: files });
        },
        addAttachedFiles: (files) => {
          const { attachedFiles } = get();
          let newPaths = files.filter((path) => !attachedFiles.includes(path));
          set({ attachedFiles: [...attachedFiles, ...newPaths] });
        },
        inProgressAttachedFiles: [],
        setInProgressAttachedFiles: (files) => {
          set({ inProgressAttachedFiles: files });
        },
        addInProgressAttachedFiles: (files) => {
          const { inProgressAttachedFiles } = get();
          let newPaths = files.filter(
            (path) => !inProgressAttachedFiles.includes(path)
          );
          set({
            inProgressAttachedFiles: [...inProgressAttachedFiles, ...newPaths],
          });
        },
        checkVersionUpdate: async () => {
          if (inBrowser()) {
            set({
              versionUpdateInfo: {
                needs_update: false,
                download_url: "",
                currentVersion: "",
                latestVersion: "",
              },
            });
            return;
          }
          const os = platform();
          const onMobile = os === "android" || os === "ios";
          if (onMobile) {
            set({
              versionUpdateInfo: {
                needs_update: false,
                download_url: "https://play.google.com/store/apps/details?id=app.lttlbrd",
                currentVersion: "",
                latestVersion: "",
              },
            });
            return;
          }

          try {
            const ok_versions = await version_info();
            const appVersion: string = await invoke("get_app_version");
                // Add debug logging
            const validVersions = ok_versions.filter(version => version.length > 0);
            const latestVersion = validVersions[validVersions.length - 1]; 
            if (ok_versions.includes(appVersion)) {
              set({
                versionUpdateInfo: {
                  needs_update: false,
                  download_url: "",
                  currentVersion: appVersion,
                  latestVersion: latestVersion,
                },
              });
            } else {
              set({
                versionUpdateInfo: {
                  needs_update: true,
                  download_url: `https://little-bird-releases.s3.us-east-2.amazonaws.com/Little+Bird.dmg`,
                  currentVersion: appVersion,
                  latestVersion: latestVersion,
                },
              });
            }
          } catch (e) {
            console.error(e);
            set({ toastData: errorToast(e) });
          }
        },
        versionUpdateInfo: {
          needs_update: false,
          download_url: "",
          currentVersion: "",
          latestVersion: "",
        },
        cancelVersionUpdate: false,
        setCancelVersionUpdate: (state: boolean) => {
          set({ cancelVersionUpdate: state });
        },
        deleteInputs: async (timestamp: number): Promise<boolean> => {
          try {
            await delete_inputs(get().token!, get().APIUrl, timestamp);
            return true;
          } catch (e) {
            set({ toastData: errorToast(e) });
          }
          return false;
        },
        setIsStreaming: (streaming: boolean) => set({ isStreaming: streaming }),
        setShareModalOpen: (open: boolean) => set({ shareModalOpen: open }),
        setStopPropagatingHotkeys: (stop: boolean) =>
          set({ stopPropagatingHotkeys: stop }),
      };
    },
    {
      name: STORAGE_NAME,
      storage: createJSONStorage(() => storage),
      // Do not persist these keys
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              !["workstreamFilter"].includes(key) &&
              !["activeTodoId"].includes(key) &&
              !["focusedChat"].includes(key) &&
              !["partialAnswer"].includes(key) &&
              !["focusedSummary"].includes(key) &&
              !["currentRoute"].includes(key) &&
              !["modalContent"].includes(key) &&
              !["suffixChat"].includes(key) &&
              !["autocompleteLastUpdated"].includes(key) &&
              !["lastAutocompleteQuery"].includes(key) &&
              !["autocomplete"].includes(key) &&
              !["chatInput"].includes(key) &&
              !["waitingResponse"].includes(key) &&
              !["partialAnswers"].includes(key) &&
              !["APIUrl"].includes(key) &&
              !["toastData"].includes(key) &&
              !["cancelVersionUpdate"].includes(key) &&
              !["inProgressAttachedFiles"].includes(key) &&
              !["settingsOpen"].includes(key) &&
              !["attachedFiles"].includes(key) &&
              !["watchdogInterval"].includes(key) && // Don't persist the interval
              !["wasStoppedByUser"].includes(key) && // Don't persist the stopped flag
              !["isProcessingVoice"].includes(key) && // Don't persist the voice processing flag
              !["ttsMessages"].includes(key) && // Don't persist the TTS queue
              !["isStreaming"].includes(key) && // Don't persist the streaming flag
              !["shareModalOpen"].includes(key) &&
              !["cmdModalOpen"].includes(key) &&
              !["stopPropagatingHotkeys"].includes(key)
          )
        ),
      onRehydrateStorage: (_state) => (state) => {
        if (state) {
          state.setHasHydrated(true);
          // Start watchdog when store is hydrated
          state.initializeWatchdog();
        }
      },
    }
  )
);
