import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuItemsProps,
} from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";

export default function DropdownMenu({
  buttonText,
  options,
  setOption,
  anchor = "top end",
}: {
  buttonText: string;
  options: string[];
  setOption: (option: string) => void;
  anchor?: MenuItemsProps["anchor"];
}) {
  return (
    <Menu>
      <MenuButton className="hover:bg-darkField border hover:border-darkLine border-transparent px-2 rounded text-xs flex flex-row gap-2 items-center">
        {buttonText}
        <FontAwesomeIcon icon={faCaretDown} className="text-darkSecondary" />
      </MenuButton>
      <MenuItems
        anchor={anchor}
        className="mt-1 bg-darkField backdrop-blur-lg shadow-card text-darkPrimary rounded text-xs rounded border border-darkLine"
      >
        {options.map((option, index) => (
          <MenuItem key={index.toString()}>
            <button
              className="w-full text-start border border-transparent hover:bg-darkField hover:border-darkLine text-xs py-2 px-3 block"
              onClick={() => setOption(option)}
            >
              {option}
            </button>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
}
