export function FilterButton({
  label,
  isActive,
  onClick,
  leftIcon,
}: {
  label: string;
  isActive: boolean;
  onClick: () => void;
  leftIcon?: JSX.Element;
}) {
  return (
    <div
      className={`flex flex-row items-center justify-center group gap-2 rounded-[4px] px-3 py-2.5 border cursor-pointer hover:border-darkLine hover:opacity-100 hover:bg-darkField ${
        isActive
          ? "bg-darkField border-darkLine"
          : "opacity-70 border-transparent"
      }`}
      style={{
        height: 29,
      }}
      onClick={onClick}
    >
      {leftIcon && leftIcon}
      <span className={`font-mono uppercase truncate text-xs text-darkPrimary`}>
        {label}
      </span>
    </div>
  );
}
