import "./App.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { PostHogProvider } from "posthog-js/react";
import { posthog } from "posthog-js";

const IS_DEV = import.meta.env.DEV;

import * as Sentry from "@sentry/react";
import { AuthProvider } from "./hooks/useAuth";
import { RouterWrapper } from "./router";

// hard-coded posthog integration token, should be passed by env vars
// later (not sensitive so it's ok to leave it like this before release)
const postHogPublicKey = "phc_ruSljIPpNGZO4uxgbAIFsKuawfu5H0iI1k4ZcmQIsSW";

if (!IS_DEV) {
  posthog.init(postHogPublicKey, {
    api_host: "https://us.i.posthog.com",
    person_profiles: "always",
    capture_pageview: false,
  });
}

Sentry.init({
  dsn: "https://96f904e733472b528b0154cd44021a41@o4507576431542272.ingest.us.sentry.io/4508289503789056",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !IS_DEV,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <AuthProvider>
        <RouterWrapper />
      </AuthProvider>
    </PostHogProvider>
  </React.StrictMode>
);
