import { useParams, useSearch } from "@tanstack/react-router";
import { useAppStore } from "../store";
import { useEffect } from "react";
import MessageList from "../components/blocks/MessageList";
import Separator from "../components/ui/Separator";
import { faMessages } from "@fortawesome/pro-regular-svg-icons";
import Button from "../components/ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useState } from "react";
import LoadingScreen from "./LoadingScreen";

const renderErrorScreen = (
  statusCode: number,
  title: string,
  message: string
) => (
  <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
    <div className="text-center">
      <p className="text-base font-semibold">{statusCode}</p>
      <h1 className="mt-4 text-balance text-3xl font-semibold tracking-tight sm:text-6xl">
        {title}
      </h1>
      <p className="mt-6 text-pretty text-lg font-medium sm:text-xl/8">
        {message}
      </p>
      <div className="flex flex-row justify-center align-middle max-w-[640px] mx-auto px-4 py-2 gap-2 mt-6">
        <a href="https://www.joinlittlebird.com/" target="_blank">
          <Button className="py-0 px-1">
            Still you can join the wait list!
          </Button>
        </a>
      </div>
    </div>
  </main>
);

const SharedChatScreen = () => {
  const { chatId } = useParams({ strict: false });
  const search = useSearch({
    strict: false,
  });
  const { fetchSharedChat, focusedChat } = useAppStore((state) => state);
  const publicToken = new URLSearchParams(search).get("publicToken");
  const [isLoadingChat, setIsLoadingChat] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!publicToken || !chatId) return;
    fetchSharedChat(chatId, publicToken)
      .catch((e: any) => {
        setError(e.response.data.detail);
      })
      .finally(() => setIsLoadingChat(false));
  }, [publicToken, chatId]);

  if (!publicToken) {
    return renderErrorScreen(
      401,
      "Unauthorized Access",
      "Sorry, we couldn’t load the chat you’re looking for. Please ask them to share the chat with a public token with you."
    );
  }
  if (error) {
    return renderErrorScreen(
      404,
      "Something went wrong",
      `Sorry, we couldn’t load the chat you’re looking for, because ${error.toLocaleLowerCase()}`
    );
  }
  if (isLoadingChat) return <LoadingScreen />;

  return (
    <div className="flex flex-col max-h-screen ">
      <div className="w-full max-w-[640px] mx-auto px-4 py-2 flex gap-2 align-middle">
        <FontAwesomeIcon
          icon={faMessages}
          width={16}
          className="flex-shrink-0 mt-1"
        />
        <div className="flex flex-col justify-center">
          {focusedChat?.title.replaceAll('"', "") || "Loading..."}
        </div>
      </div>
      <Separator />
      <div className="flex-grow justify-center align-middle h-full overflow-auto">
        {error ? (
          <div className="text-red-500 text-center">{error}</div>
        ) : (
          <MessageList />
        )}
      </div>

      <Separator />
      <div className="flex flex-row justify-center align-middle max-w-[640px] mx-auto px-4 py-2 gap-2">
        <a href="https://www.joinlittlebird.com/" target="_blank">
          <Button className="py-0 px-1">
            {" "}
            Want to experience this on your own data? Join the Waitlist Now!
          </Button>
        </a>
      </div>
    </div>
  );
};

export default SharedChatScreen;
