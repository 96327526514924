import { faUser } from "@fortawesome/pro-regular-svg-icons";

import CenteredTile from "../components/ui/CenteredTile";
import { useState } from "react";
import Button from "../components/ui/Button";
import Input from "../components/ui/Input";
import { handleMailTo } from "../utils/platform";

const DeleteAccountScreen = () => {
  const [email, setEmail] = useState("");
  return (
    <CenteredTile
      icon={faUser}
      title="Delete Account"
      description="Send an email and we will handle your request ASAP"
      content={
        <>
          <Input
            label="EMAIL"
            id="email"
            value={email}
            placeholder="john.doe@gmail.com"
            type="email"
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <Button
            className="w-full mt-4"
            disabled={email === ""}
            onPress={() => {
              handleMailTo(
                "support@littlebird.app",
                "Delete Account",
                `Please delete my account ${email}`
              );
            }}
          >
            Send email
          </Button>
        </>
      }
    />
  );
};

export default DeleteAccountScreen;
