import { format } from "date-fns";
import { ROUTES, useAppStore } from "../../store";
import TableRow from "../ui/TableRow";
import { faMessages } from "@fortawesome/pro-regular-svg-icons";

function RecentChats() {
  const { chats, setFocusedChat, setCurrentRoute } = useAppStore();

  return (
    <div className="px-4 pt-2">
      {chats.map((chat, index) => {
        return (
          <TableRow
            key={index.toString()}
            isSelected={false}
            onClick={() => {
              setFocusedChat(chat);
              setCurrentRoute(ROUTES.CHAT);
            }}
            leadingIcon={faMessages}
            title={chat.title}
            description={chat.chat_history
              .reduce((acc, currentArray) => {
                return acc + " " + currentArray.join(" ");
              }, "")
              .trim()}
            secondaryDescription=""
            persistentActions={<></>}
            actionsDescription={format(new Date(chat.date), "d MMM")}
          />
        );
      })}
    </div>
  );
}

export default RecentChats;
