import { useState } from "react";
import Separator from "../ui/Separator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CMD_K_OPTIONS } from "../../hooks/useHotkeys";
import { useAppStore } from "../../store";

const CMDModal = ({}: {}) => {
  const { setStopPropagatingHotkeys } = useAppStore();
  const [search, setSearch] = useState("");
  const [highlightedIndex, _setHighlightedIndex] = useState(-1);

  return (
    <div className="h-[560px] max-h-[80vh] overflow-auto">
      <div className="flex flex-col items-start pt-5 px-4">
        <div className="font-mono text-xs uppercase text-darkPrimary opacity-60">
          Little Bird Actions
        </div>
        <input
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Search"
          className="bg-transparent w-full resize-none outline-none caret-blue my-4"
          onFocus={() => setStopPropagatingHotkeys(true)}
          onBlur={() => setStopPropagatingHotkeys(false)}
        />
      </div>
      <Separator />
      <div className="flex flex-col items-start pt-5 px-4">
        <div className="font-mono text-xs uppercase text-darkPrimary opacity-60">
          Actions
        </div>
      </div>

      <div className="flex flex-col my-4 w-full">
        {CMD_K_OPTIONS.filter((o) =>
          o.text.toLowerCase().includes(search.toLowerCase())
        ).map((o, index) => (
          <div
            className={`flex flex-row w-full gap-4 items-center text-start px-6 h-14 ${
              index === highlightedIndex ? "bg-darkField" : ""
            }`}
          >
            {o.icon && (
              <FontAwesomeIcon
                icon={o.icon}
                width={18}
                className={
                  index === highlightedIndex
                    ? "text-darkPrimary"
                    : "text-darkSecondary"
                }
              />
            )}
            <div
              className={`flex-grow text-sm truncate ${
                index === highlightedIndex
                  ? "text-darkPrimary"
                  : "text-darkSecondary"
              }`}
            >
              {o.text}
            </div>

            <div className="flex flex-row gap-3 font-mono uppercase text-xs">
              {o.actionInfo.map((step) => (
                <div
                  className={`h-6 w-6 flex items-center justify-center ${
                    step.isKey ? "shadow-card rounded border" : ""
                  } 
                    ${
                      index === highlightedIndex
                        ? "text-darkPrimary border-darkPrimary"
                        : "text-darkSecondary border-darkLine"
                    }
                    `}
                >
                  {step.text}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CMDModal;
