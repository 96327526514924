import { useVirtualizer } from "@tanstack/react-virtual";
import Message from "./Message";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppStore } from "../../store";
import useOnScreen from "../../hooks/useOnScreen";
import { Avatar } from "../ui/Avatar";
import AI_Icon from "../../assets/icons/ai-avatar.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/pro-regular-svg-icons";
import { useMatch } from "@tanstack/react-router";
import Button from "../ui/Button";

const MessageList = () => {
  const {
    user,
    focusedChat,
    partialAnswers,
    isProcessingVoice,
    attachedFiles,
    sendQuestion,
    firstChatRecommendedQuestion,
  } = useAppStore((state) => state);

  const isSharedMessageList = useMatch({
    from: "/shared-chat/$chatId",
    shouldThrow: false,
  });
  const [loader, setLoader] = useState(0);
  const [loaderSetAtLenght, setLoaderSetAtLenght] = useState(0);

  useEffect(() => {
    if (focusedChat && loaderSetAtLenght != focusedChat.chat_history.length) {
      setLoaderSetAtLenght(focusedChat.chat_history.length);
      setLoader(0);
    }
    const interval = setInterval(() => {
      setLoader(loader + 0.15);
    }, 150);
    return () => {
      clearInterval(interval);
    };
  }, [focusedChat, loader]);

  const partialAnswer = useMemo(() => {
    return focusedChat ? partialAnswers[focusedChat.chat_id] : null;
  }, [partialAnswers, focusedChat]);

  const data = useMemo((): string[][] => {
    const partial = partialAnswer?.partialAnswer ?? "";
    if (!partial) {
      return focusedChat?.chat_history ?? [];
    }
    let partialHistory: string[][] = JSON.parse(
      JSON.stringify(focusedChat?.chat_history ?? [])
    );
    partialHistory.push(["text:" + partial]);
    partialHistory = partialHistory.filter(
      (x: string[]) => x.join("").length > 0
    );
    return partialHistory;
  }, [focusedChat, partialAnswers]);

  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: data.length,
    estimateSize: () => 100,
    overscan: 5,
    getScrollElement: () => scrollWrapperRef.current,
    paddingStart: 20,
    paddingEnd: 20,
    scrollPaddingEnd: 20,
    gap: 28,
  });

  const items = rowVirtualizer.getVirtualItems();
  const endOfListRef = useRef<HTMLDivElement | null>(null);
  const scrolledToBottom = useOnScreen(endOfListRef);

  const [lastFocusedChat, setLastFocusedChat] = useState<number | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (focusedChat?.chat_id !== lastFocusedChat) {
        // THIS MEANS INITIAL RENDER OF CHAT MESSAGES
        rowVirtualizer.scrollToIndex(data.length - 1, {
          behavior: lastFocusedChat ? "smooth" : "auto",
        });
        if (focusedChat?.chat_id) {
          setLastFocusedChat(focusedChat?.chat_id);
        }
      } else {
        // ONLY SHOW NEW STATES IF THE SCROLL IS AT THE BOTTOM
        if (scrolledToBottom) {
          endOfListRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }, 50); // Small delay to ensure rendering is complete
    return () => clearTimeout(timeout);
  }, [data]);

  // NOT WORKING YET
  // const handleScroll = useCallback((event: any) => {
  //   const { scrollTop } = event.target as HTMLDivElement;
  //   console.log("scrollTop", scrollTop);
  //   if (focusedChat?.chat_id) {
  //     setChatScroll({
  //       ...chatScroll,
  //       ...(focusedChat?.chat_id && {
  //         [focusedChat.chat_id]: {
  //           scrollOffset: event.nativeEvent.contentOffset.y,
  //           // shouldScrollToEnd:
  //           //   event.nativeEvent.contentOffset.y >=
  //           //   event.nativeEvent.contentSize.height - paddingToBottom,
  //         },
  //       }),
  //     });
  //   }
  // }, []);

  if (!focusedChat || !focusedChat.chat_history.length) {
    const userName = user?.name?.split(" ")[0];
    return (
      <div
        ref={scrollWrapperRef}
        className="h-full overflow-y-auto flex flex-col justify-end w-full max-w-[640px] mx-auto px-4"
      >
        <Message
          key={0}
          msg={[
            `text:Hello ${userName}, I’m Little Bird. \n
  Other people describe me like an AI Jarvis; the more you use me, the more powerful I become. \n
  Ask me a question, or click a recommendation to get started. `,
          ]}
          isAi={true}
          dataIndex={0}
          hideVotes={true}
          measureRef={rowVirtualizer.measureElement}
          inProgressUserMessage={false}
        />

        <div className="flex flex-col  self-center text-darkPrimary py-3 gap-3 mt-4">
          <div className="font-mono text-xs uppercase text-darkSecondary">
            Recommendations
          </div>
          <div className="flex flex-wrap gap-4 ">
            {firstChatRecommendedQuestion.map((suggestion, index) => (
              <Button
                variant="outline"
                className="normal-case bg-darkField"
                key={index}
                onPress={() => sendQuestion(suggestion, attachedFiles)}
              >
                {suggestion}
              </Button>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={scrollWrapperRef}
      className="h-full overflow-y-auto "
      // onScroll={handleScroll}
    >
      <div
        className="w-full"
        style={{
          height: rowVirtualizer.getTotalSize(),
          position: "relative",
        }}
      >
        {items.map((item) => {
          return (
            <div
              key={String(item.key)}
              className="w-full"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: `${item.size}px`,
                transform: `translateY(${item.start}px)`,
              }}
            >
              <div className="w-full max-w-[640px] mx-auto px-4">
                <Message
                  key={String(item.key)}
                  msg={data[item.index]}
                  isAi={item.index % 2 !== 0}
                  dataIndex={item.index}
                  hideVotes={Boolean(isSharedMessageList)}
                  measureRef={rowVirtualizer.measureElement}
                  inProgressUserMessage={
                    item.index % 2 === 0 &&
                    (item.index === items.length - 1 ||
                      ((item.index === items.length - 3 ||
                        item.index === items.length - 2) &&
                        Boolean(partialAnswer?.isThinking)))
                  }
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className="w-full max-w-[640px] mx-auto px-4">
        {partialAnswer?.isThinking && (
          <div className={`flex gap-3 items-center pb-7`}>
            <Avatar source={AI_Icon} />
            <div className="flex space-x-1">
              <div className="w-48 h-1 bg-opacity-20 bg-darkField rounded-full overflow-hidden">
                <div
                  className="h-full bg-darkPrimary rounded-full transition-transform duration-100 ease-linear"
                  style={{ width: `${Math.ceil((100 * loader) / 11)}%` }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {isProcessingVoice && (
        <div className="w-full px-4">
          <div className="w-full max-w-[640px] mx-auto">
            <div className={`flex gap-3 pb-7 items-center`}>
              <div className="flex space-x-1 ms-auto">
                <div className="h-1 w-1 bg-darkPrimary rounded-full animate-bounce [animation-delay:-0.3s]" />
                <div className="h-1 w-1 bg-darkPrimary rounded-full animate-bounce [animation-delay:-0.15s]" />
                <div className="h-1 w-1 bg-darkPrimary rounded-full animate-bounce" />
              </div>
              <FontAwesomeIcon className="" icon={faMicrophone} />
            </div>
          </div>
        </div>
      )}
      <div ref={endOfListRef} />
    </div>
  );
};
export default MessageList;
