import { useState } from "react";
import { faClone, faCheck } from "@fortawesome/pro-regular-svg-icons";

import { writeText } from "@tauri-apps/plugin-clipboard-manager";
import IconButton from "../ui/IconButton";
import { inBrowser } from "../../utils/platform";
import Tooltip from "../ui/Tooltip";
import { PopoverPanelProps } from "@headlessui/react";

const CopyButton = ({
  text,
  copyText,
  copyTextPositionCl = "top start",
  className,
}: {
  text: string;
  copyText: string;
  copyTextPositionCl?: PopoverPanelProps["anchor"];
  className?: string;
}) => {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <Tooltip
      label={isCopied ? "Copied" : copyText}
      placement={copyTextPositionCl}
      className="p-1"
    >
      <IconButton
        icon={isCopied ? faCheck : faClone}
        onClick={async () => {
          if (inBrowser()) {
            await navigator.clipboard.writeText(text);
          } else {
            await writeText(text);
          }
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 4000);
        }}
        disabled={false}
        className={className}
      />
    </Tooltip>
  );
};
export default CopyButton;
