import Separator from "../ui/Separator";

const ShowMore = ({ text, onClick }: { text: string; onClick: () => void }) => {
  return (
    <div className="flex items-center justify-center py-4">
      <Separator className="flex-1" />
      <button
        onClick={onClick}
        className={`px-8 font-mono text-xs text-darkPrimary uppercase hover:text-darkSecondary`}
      >
        {text}
      </button>
      <Separator className="flex-1" />
    </div>
  );
};

export default ShowMore;
