import React from "react";
import Logo from "../assets/icons/logo.svg";

const LoadingScreen = () => {
  const [rotation, setRotation] = React.useState(0);

  React.useEffect(() => {
    const animate = () => {
      setRotation((prev) => (prev + 1) % 360);
      requestAnimationFrame(animate);
    };

    const animationFrame = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <img
        src={Logo}
        alt="Little Bird"
        width={48}
        style={{
          transform: `rotate(${rotation}deg)`,
          transition: "transform 16ms linear",
        }}
      />
    </div>
  );
};
export default LoadingScreen;
