import { useMemo, useState } from "react";
import IconButton from "./IconButton";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameDay,
  isSameMonth,
  addMonths,
  subMonths,
  startOfWeek,
  endOfWeek,
} from "date-fns";

const WeekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export default function DatePicker({
  value,
  onChange,
}: {
  value?: Date;
  onChange: (value: Date) => void;
}) {
  const [currentMonth, setCurrentMonth] = useState(value ?? new Date());

  const calendarDays = useMemo(() => {
    const start = startOfWeek(startOfMonth(currentMonth));
    const end = endOfWeek(endOfMonth(currentMonth));

    return eachDayOfInterval({ start, end });
  }, [currentMonth]);

  return (
    <div className="bg-darkField shadow-card backdrop-blur-lg">
      <div className="flex justify-between items-center px-4 py-4">
        <IconButton
          className="w-6 rounded hover:bg-darkField"
          icon={faChevronLeft}
          size={6}
          onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
        />
        <div className="text-sm uppercase font-mono">
          {format(currentMonth, "MMM ‘yy")}
        </div>
        <IconButton
          className="w-6 rounded hover:bg-darkField"
          icon={faChevronRight}
          size={6}
          onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
        />
      </div>

      <div className="grid grid-cols-7 gap-3 p-4 pt-1">
        {WeekDays.map((day) => (
          <div
            key={day}
            className="font-mono text-center text-xs text-darkSecondary"
          >
            {day}
          </div>
        ))}

        {calendarDays.map((date, index) => (
          <div
            key={index}
            onClick={() => {
              onChange(date);
              setCurrentMonth(date);
            }}
            className={`font-mono text-center text-sm p-2 cursor-pointer rounded hover:bg-darkField ${
              date && value && isSameDay(date, value)
                ? "bg-darkField hover:bg-darkLine text-darkPrimary"
                : ""
            } ${
              date && !isSameMonth(date, currentMonth)
                ? "text-darkField opacity-75"
                : ""
            }`}
          >
            {date ? format(date, "d") : ""}
          </div>
        ))}
      </div>
    </div>
  );
}
