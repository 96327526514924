import React, { useEffect, useState } from "react";
import { message } from "@tauri-apps/plugin-dialog";
import { faArrowLeftLong } from "@fortawesome/pro-regular-svg-icons";
import { useAppStore } from "../store";
import { create_user } from "../utils/api";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import CenteredTile from "../components/ui/CenteredTile";
import Logo from "../assets/icons/logo.svg";
import IconButton from "../components/ui/IconButton";
import { inBrowser } from "../utils/platform";
import { useRouter } from "@tanstack/react-router";
import { useAuth } from "../hooks/useAuth";

function LandingScreen() {
  const router = useRouter();
  const { isAuthenticated } = useAuth();
  const { email, setEmail, APIUrl, setToken } = useAppStore((state) => state);

  const [_email, _setEmail] = React.useState("");
  const [_token, _setToken] = React.useState("");

  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const [isSubmittingToken, setIsSubmittingToken] = useState(false);
  const [anotherRequested, setAnotherRequested] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      router.invalidate();
    }
  }, [isAuthenticated]);

  return (
    <CenteredTile
      title={!email ? "Sign in to Little Bird" : "Enter Code to Login"}
      description={
        !email
          ? "Enter your email address and we'll send you a verification code."
          : "Code sent to " + email
      }
      leadingContent={<img src={Logo} alt="Little Bird" className="mx-auto" />}
      content={
        <>
          {!email ? (
            <>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    setIsSubmittingEmail(true);
                    await create_user(_email, APIUrl);
                    setEmail(_email);
                    setIsSubmittingEmail(false);
                  } catch {
                    setIsSubmittingEmail(false);
                    if (inBrowser()) {
                      alert("Failed to send email");
                    } else {
                      await message("Failed to send email", {
                        title: "",
                        kind: "error",
                      });
                    }
                  }
                }}
              >
                <Input
                  label="EMAIL"
                  id="email"
                  value={_email}
                  placeholder="john.doe@gmail.com"
                  type="email"
                  onChange={(e) => _setEmail(e.currentTarget.value)}
                />
                <Button
                  className="w-full mt-4"
                  type="submit"
                  disabled={_email === ""}
                  isSubmitting={isSubmittingEmail}
                >
                  next
                </Button>
              </form>
              <p className="mt-6 text-center text-darkSecondary text-sm">
                By creating an account, you agree to our{" "}
                <a
                  className="text-darkPrimary underline"
                  href="https://scarlet-magician-747.notion.site/Terms-and-Conditions-15a0e6d1f99b80f5a558d0912d054137?pvs=4"
                  target="_blank"
                >
                  Terms and Conditions
                </a>{" "}
                and acknowledge our{" "}
                <a
                  className="text-darkPrimary underline"
                  href="https://scarlet-magician-747.notion.site/Privacy-Policy-15a0e6d1f99b801d81aed543083dbb7f?pvs=4"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                .
              </p>
              <p className="mt-2 text-center text-darkSecondary text-sm">
                Trouble logging in?{" "}
                <a
                  className="text-darkPrimary underline"
                  href="mailto:support@littlebird.app"
                  target="_blank"
                >
                  Contact Support
                </a>
              </p>
            </>
          ) : (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setIsSubmittingToken(true);
                  setToken(_token);
                  setIsSubmittingToken(false);
                  router.invalidate();
                }}
              >
                <Input
                  id="token"
                  value={_token}
                  placeholder="Enter code"
                  onChange={(e) => _setToken(e.currentTarget.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setIsSubmittingToken(true);
                      setToken(_token);
                      setIsSubmittingToken(false);
                    }
                  }}
                />
                <div className="flex flex-row items-center mt-4">
                  <IconButton
                    className="mr-5"
                    icon={faArrowLeftLong}
                    onClick={() => {
                      setEmail(null);
                    }}
                  />
                  <Button
                    className="w-full"
                    type="submit"
                    disabled={_token === ""}
                    isSubmitting={isSubmittingToken}
                  >
                    Login
                  </Button>
                </div>
              </form>
              <p className="mt-6 text-center text-darkSecondary text-sm">
                Didn’t receive a code?{" "}
                {anotherRequested ? (
                  <span className="text-darkPrimary">Code sent</span>
                ) : (
                  <span
                    className="text-darkPrimary cursor-pointer underline"
                    onClick={async () => {
                      setAnotherRequested(true);
                      setIsSubmittingEmail(true);
                      await create_user(_email, APIUrl);
                      setIsSubmittingEmail(false);
                    }}
                  >
                    Request Another
                  </span>
                )}
              </p>
            </>
          )}
        </>
      }
    />
  );
}

export default LandingScreen;
